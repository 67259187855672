"use client";
import { Col, Grid, Row, Empty, Pagination } from "antd";
import TranslateWord from "../../src/Components/sharedComponents/TranslateWord";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { getAllLensData, getLenscategories } from "../Store/LensSlice";
const { useBreakpoint } = Grid;
const AllLenslist = () => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screens = useBreakpoint();
  let loggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserDetails"));
  const { userSelectedNewsFeedData } = useSelector((state) => state.profileSettingsList);
  const { selectedInterfaceLanguage } = useSelector((state) => state.interfaceLanguageList);
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: allLensData,
    lensCategories: allLenscategories,
    status,
    error,
    lensSelectedLang,
  } = useSelector((state) => state.lensList);
  const { data: feedLanguageData, selectedfeedLanguage } = useSelector((state) => state.feedLanguageList);
  const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
    return feed?.name === lensSelectedLang?.name;
  });
  let feedLangPayload = [];
  if (loggedInUserData?.user_id) {
    feedLangPayload = userSelectedNewsFeedData?.filter((item) => item.selected)?.map((item) => item.newsfeed_lang_id);
  } else {
    feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
      return feedL?.newsfeed_lang_id;
    });
  }
  const ALLWORD = lensSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
  const trendingLives = lensSelectedLang?.name === "తెలుగు" ? "ట్రెండింగ్" : "Trending";
  const [category, setCategory] = useState(ALLWORD);
  const lensCategoriesnames = [];
  if (allLenscategories && allLenscategories.data && allLenscategories.data.length > 0) {
    allLenscategories.data.forEach((cat) => {
      lensCategoriesnames.push({ category_name: cat?.lens_category_name });
    });
  }
  lensCategoriesnames.splice(0, 0, { category_name: ALLWORD }, { category_name: trendingLives });
  const [lensCategories, setLensCategories] = useState(lensCategoriesnames);
  useEffect(() => {
    if (lensSelectedLang?.name) {
      dispatch(getLenscategories(lensSelectedLang?.name));
    }
  }, [lensSelectedLang]);
  const gettingData = (key) => {
    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === lensSelectedLang?.name;
    });
    let feedLangPayload = [];
    if (loggedInUserData?.user_id) {
      feedLangPayload = userSelectedNewsFeedData?.filter((item) => item.selected)?.map((item) => item.newsfeed_lang_id);
    } else {
      feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
        return feedL?.newsfeed_lang_id;
      });
    }
    const payload = {
      category_name: "",
      interface_language_id: selectedInterfaceLanguage?.interface_lang_id,
      is_all: true,
      is_category: false,
      is_trending: false,
      login_user_id: null,
      news_feed_lang_ids: feedLangPayload,
      page: 0,
      pageSize: 12,
    };
    if (key === "అన్ని" || key === "All") {
      payload.is_all = true;
      payload.is_trending = false;
      setCategory(ALLWORD);
    } else if (key === "Trending" || key === "ట్రెండింగ్") {
      payload.is_all = false;
      payload.is_trending = true;
      setCategory(trendingLives);
    } else {
      payload.is_all = false;
      payload.is_category = true;
      payload.category_name = key;
      setCategory(key);
    }
    setCurrentPage(1);
    dispatch(getAllLensData(payload));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    gettingData(category);
  }, [lensSelectedLang, selectedfeedLanguage, selectedInterfaceLanguage]);
  const allData =
    allLensData?.data && allLensData?.data[category] && allLensData?.data[category].length > 0
      ? allLensData?.data[category]
      : [];

  const gotoLensWithId = (id) => {
    navigate(`/lensDetails?param1=${id}&category=${category}`);
    window.scrollTo(0, 0);
  };
  const handlePagination = (page, pageSize, key) => {
    const payload = {
      category_name: "",
      interface_language_id: selectedInterfaceLanguage?.interface_lang_id,
      is_all: true,
      is_category: false,
      is_latest: false,
      is_trending: false,
      login_user_id: null,
      news_feed_lang_ids: feedLangPayload,
      page: page - 1,
      pageSize: pageSize,
    };
    if (key === "అన్ని" || key === "All") {
      payload.is_all = true;
      payload.is_trending = false;
      setCategory(ALLWORD);
    } else if (key === "Trending" || key === "ట్రెండింగ్") {
      payload.is_all = false;
      payload.is_trending = true;
      setCategory(trendingLives);
    } else {
      payload.is_all = false;
      payload.is_category = true;
      payload.category_name = key;
      setCategory(key);
    }
    setCurrentPage(page);
    dispatch(getAllLensData(payload));
    window.scrollTo(0, 0);
  };
  const pageSize = 12;
  const paginatedData = allData.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  const filteredLensData = allLensData?.data?.filter((record) => {
    if (record?.video_thunbnail_url || record?.lens_url || record?.title) return record;
  });
  const lensTitle = selectedInterfaceLanguage?.name === "తెలుగు" ? "లెన్స్" : "Lens";
  return (
    <>
      <div>
        <Row justify="center" align="middle">
          <Col span={24} style={{ textAlign: "center" }}>
            <Row justify="center">
              <Col>
                <h1
                  className={`${
                    selectedInterfaceLanguage?.name === "తెలుగు" ? "telugu-text-title_lg" : "english-text-title_lg"
                  }`}>
                  {/* {<TranslateWord key={"Lens"} text={"LENS"} lang={lensSelectedLang} />} */}
                  {lensTitle}
                </h1>
              </Col>
            </Row>
            <Row justify="center">
              <Col style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div>
                  <strong
                    className={`${
                      selectedInterfaceLanguage?.name === "తెలుగు" ? "telugu-text" : "english-text-title"
                    }`}>
                    <Link to="/">
                      {lensSelectedLang && <TranslateWord key={"home"} text={"HOME"} lang={lensSelectedLang} />}
                    </Link>
                  </strong>
                </div>
                <div style={{ margin: "0 5px" }}>/</div>
                <div>
                  {" "}
                  <strong
                    className={`${
                      selectedInterfaceLanguage?.name === "తెలుగు" ? "telugu-text" : "english-text-title"
                    }`}>
                    {category}
                  </strong>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: "10px", marginBottom: "20px", padding: "5px", width: "100%" }}>
          <Col>
            {lensCategories?.map((type) => {
              return (
                <span
                  key={type.category_name}
                  style={{
                    display: "inline-block",
                    marginRight: "10px",
                    fontSize: "16px",
                    cursor: "pointer",
                    padding: "5px 10px",
                    borderRadius: "4px",
                  }}>
                  <span>
                    <strong
                      className={`${selectedInterfaceLanguage?.name === "తెలుగు" ? "telugu-text" : "english-text"}`}
                      style={{
                        color: category === type?.category_name ? "red" : "black",
                      }}
                      onClick={() => gettingData(type?.category_name)}>
                      {type?.category_name}
                    </strong>
                  </span>
                </span>
              );
            })}
          </Col>
        </Row>
        {status === "idle" && allLensData?.data?.length === 0 ? (
          <div className="item">
            <div className="img-overlay-69 img-scale-animate main_slider_tabs  main_slider_all_politics">
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "center",
                  marginTop: "100px",
                  marginBottom: "70px",
                }}>
                <Empty />
                <h4 style={{ color: "white" }}>No data </h4>
              </div>
            </div>
          </div>
        ) : (
          <div className="news-cards-wrapper" style={{ marginTop: screens.xs ? "0px" : "-30px" }}>
            <div className="grid-container">
              {filteredLensData?.map((lensData, index) => {
                return (
                  <div>
                    <div
                      onClick={() => gotoLensWithId(lensData?.lens_id)}
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        textAlign: "center",
                        width: screens.xs ? "350px" : "198px",
                        height: screens.xs ? "622px" : "350px",
                        objectFit: "contain",
                        backgroundColor: "black",
                        marginRight: "5px",
                        marginTop: "10px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}>
                      {lensData?.lens_url && lensData?.video_thunbnail_url ? (
                        <img
                          src={lensData?.video_thunbnail_url}
                          alt={lensData.title}
                          layout="fill"
                          style={{
                            textAlign: "center",
                            width: screens.xs ? "350px" : "199px",
                            height: screens.xs ? "622px" : "352px",
                            objectFit: "contain",
                          }}
                        />
                      ) : (
                        <img
                          src={lensData?.lens_url}
                          alt={lensData.title}
                          layout="fill"
                          style={{
                            textAlign: "center",
                            width: screens.xs ? "350px" : "199px",
                            height: screens.xs ? "622px" : "352px",
                            objectFit: "contain",
                          }}
                        />
                      )}
                    </div>
                    <div style={{ width: screens.xs ? "350px" : "199px", height: "50px" }}>
                      <h1
                        style={{
                          fontFamily: lensSelectedLang?.name === "English" ? "Poppins" : "Mallanna",
                          fontSize: "16px",
                          fontWeight: "600",
                          lineHeight: "20px",
                        }}>
                        {lensData?.title?.length > 30 ? lensData.title.slice(0, 38) + "..." : lensData?.title}
                      </h1>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <Row justify="end" style={{ marginTop: "20px" }}>
          <Pagination
            current={currentPage}
            total={allLensData?.totalElements}
            pageSize={pageSize}
            onChange={(page) => handlePagination(page, pageSize, category)}
            showSizeChanger={false}
          />
        </Row>
      </div>
    </>
  );
};

export default AllLenslist;
