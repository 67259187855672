"use client";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, Select, Button, Row, Col, Typography, Avatar, Grid, Empty } from "antd";
import TranslateWord from "../Components/sharedComponents/TranslateWord.js";
import { getAllCategoryChannelsData } from "../Store/ChannelsSlice";
import axios from "axios";
import BaseUrl from "../utils/config";
const { Title } = Typography;
const { Option } = Select;
const { useBreakpoint } = Grid;

const AllChannelList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screens = useBreakpoint();
  let loggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserDetails"));
  const { userSelectedNewsFeedData } = useSelector((state) => state.profileSettingsList);
  const { selectedInterfaceLanguage } = useSelector((state) => state.interfaceLanguageList);
  const [applyFilter, setApplyFilter] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [newsfeedLanglist, setNewsfeedLanglist] = useState([]);
  const [language, setLanguage] = useState(newsfeedLanglist[0]?.newsfeed_lang_id);
  const [politikosChannels, setPolitikosChannels] = useState([]);
  const { allChannelsData, status, error, channelsSelectedLang } = useSelector((state) => state.channelsList);
  const { data: feedLanguageData, selectedfeedLanguage } = useSelector((state) => state.feedLanguageList);

  const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
    return feed?.name === channelsSelectedLang?.name;
  });
  console.log("feedLa", userSelectedFeedLanguages);

  let feedLangPayload = [];
  if (loggedInUserData?.user_id) {
    feedLangPayload = userSelectedNewsFeedData?.filter((item) => item.selected)?.map((item) => item.newsfeed_lang_id);
  } else {
    feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
      return feedL?.newsfeed_lang_id;
    });
  }
  const getAllCategoryData = (newsFeedId) => {
    const payload = {
      category_id: selectedCategory ? (selectedCategory === "All" ? null : selectedCategory) : null,
      interfacelanguageName: "English",
      news_feed_language_id: newsFeedId ? (newsFeedId === "Language" ? null : newsFeedId) : null, //feedLangPayload[0]
      search_by_text: null,
      page: 0,
      pageSize: 40,
      search_by_text: null,
    };
    dispatch(getAllCategoryChannelsData(payload));
  };
  useEffect(() => {
    getAllCategoryData(language);
  }, [applyFilter]);

  useEffect(() => {
    if (applyFilter) {
      getAllCategoryData(language);
    }
    window.scrollTo(0, 0);
  }, [language, selectedCategory]);

  useEffect(() => {
    axios
      .get(`${BaseUrl}/category`)
      .then((response) => {
        if (response?.status === 200 && response?.data?.data && response?.data?.data?.length) {
          const filterLangData = response?.data?.data?.filter(
            (interfaceLang) => interfaceLang?.interface_language?.name === channelsSelectedLang.name
          );
          console.log("filterLangData", filterLangData, filterLangData.length);
          setCategoriesList(filterLangData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  console.log("categoriesList", categoriesList);

  useEffect(() => {
    axios
      .get(`${BaseUrl}/user/newsfeed`)
      .then((response) => {
        if (response?.status === 200 && response?.data?.data && response?.data?.data?.length) {
          setNewsfeedLanglist(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // for politikos channels
    axios
      .get(`${BaseUrl}/livetv/internal/channels/English`)
      .then((response) => {
        if (response?.status === 200 && response?.data?.data && response?.data?.data?.length) {
          setPolitikosChannels(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  console.log("interfaceLanglist", newsfeedLanglist);

  console.log("channellist56", politikosChannels);

  const handleApplyFilter = () => {
    setApplyFilter(!applyFilter);
  };

  const clearFilters = () => {
    setLanguage("Language"); //newsfeedLanglist[0].newsfeed_lang_id
    setSelectedCategory("All");
    handleApplyFilter();
  };

  const handleUpdatedSelectedCategory = (val) => {
    setSelectedCategory(val);
  };
  const handleLanguage = (value) => {
    setLanguage(value);
  };
  const Channels = selectedInterfaceLanguage?.name === "తెలుగు" ? "ఛానెల్స్" : "Channels";
  const politikosChannel =
    selectedInterfaceLanguage?.name === "తెలుగు" ? "పొలిటికోస్‌ ఛానెల్‌లు" : "Politikos Channels";
  const allChannels = selectedInterfaceLanguage?.name === "తెలుగు" ? "అన్ని ఛానెల్‌లు" : "All Channels";
  const catPlaceholder = selectedInterfaceLanguage?.name === "తెలుగు" ? "విభాగం" : "All Categories";
  const langPlaceholder = selectedInterfaceLanguage?.name === "తెలుగు" ? "భాష" : "Language";
  const applyFilterBtn = selectedInterfaceLanguage?.name === "తెలుగు" ? "ఫిల్టర్ ని వర్తింపజేయండి" : "Apply Filter";
  const clearFilterBtn = selectedInterfaceLanguage?.name === "తెలుగు" ? "ఫిల్టర్ ని క్లియర్ చేయండి" : "Clear Filter";

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <Title level={2} className="mb-8 text-center" style={{ fontFamily: "mallanna" }}>
        {Channels}
      </Title>

      {/* Categories Grid */}
      <h1 style={{ marginTop: "16px", marginBottom: "32px", marginLeft: "8px", fontFamily: "mallanna" }}>
        {politikosChannel}
      </h1>
      <Row gutter={[16, 16]} className="mb-8">
        {politikosChannels?.map((politikosChannel, index) => (
          <Col key={index} xs={12} sm={8} md={6} lg={4}>
            <Card
              hoverable
              className="text-center"
              bodyStyle={{ padding: "12px" }}
              onClick={() => navigate(`/Channel?channel_id=${politikosChannel?.live_tv_source_id}`)}>
              <Avatar size={64} src={politikosChannel.logo_url} className="mb-2" />
              <div
                className="text-sm no-scrollbar"
                style={{
                  position: "relative",
                  whiteSpace: "nowrap",
                  overflowX: "auto",
                  msOverflowStyle: "none",
                  scrollbarWidth: "none",
                }}>
                {politikosChannel?.tv_name}
              </div>
            </Card>
          </Col>
        ))}
      </Row>

      <h1 style={{ marginTop: "16px", marginBottom: "32px", marginLeft: "8px", fontFamily: "mallanna" }}>
        {allChannels}
      </h1>

      {/* Filters */}

      {screens.xs ? (
        <div className="mb-8 flex gap-4 items-center" style={{ marginTop: "20px", marginBottom: "20px" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Select
              defaultValue="All"
              style={{ width: 200 }}
              className="mr-4"
              onChange={handleUpdatedSelectedCategory}
              value={selectedCategory}>
              <Option value="All">{catPlaceholder}</Option>
              {categoriesList.map((category, index) => (
                <Option key={index} value={category?.category_id}>
                  {category?.category_name}
                </Option>
              ))}
            </Select>

            <Select
              defaultValue="Language"
              style={{ width: 200 }}
              className="mr-4"
              value={language}
              onChange={handleLanguage}>
              <Option value="Language" disabled>
                {langPlaceholder}
              </Option>

              {newsfeedLanglist?.map((langObj) => {
                return <Option value={langObj?.newsfeed_lang_id}>{langObj?.name}</Option>;
              })}
            </Select>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: "20px",
              marginBottom: "15px",
            }}>
            {!applyFilter ? (
              <Button type="primary" onClick={handleApplyFilter}>
                {applyFilterBtn}
              </Button>
            ) : (
              <Button type="primary" onClick={clearFilters}>
                {clearFilterBtn}
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div className="mb-8 flex gap-4 items-center" style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Select
            defaultValue="All"
            style={{ width: 200 }}
            className="mr-4"
            onChange={handleUpdatedSelectedCategory}
            value={selectedCategory}>
            <Option value="All">{catPlaceholder}</Option>
            {categoriesList.map((category, index) => (
              <Option key={index} value={category?.category_id}>
                {category?.category_name}
              </Option>
            ))}
          </Select>

          <Select
            defaultValue="Language"
            style={{ width: 200 }}
            className="mr-4"
            value={language}
            onChange={handleLanguage}>
            <Option value="Language" disabled>
              {langPlaceholder}
            </Option>

            {newsfeedLanglist?.map((langObj) => {
              return <Option value={langObj?.newsfeed_lang_id}>{langObj?.name}</Option>;
            })}
          </Select>
          {!applyFilter ? (
            <Button type="primary" onClick={handleApplyFilter}>
              {applyFilterBtn}
            </Button>
          ) : (
            <Button type="primary" onClick={clearFilters}>
              {clearFilterBtn}
            </Button>
          )}
        </div>
      )}

      {/* Channels Grid */}
      {allChannelsData.data?.length === 0 ? (
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            marginTop: "100px",
            marginBottom: "70px",
          }}>
          <Empty />
        </div>
      ) : (
        <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
          {allChannelsData?.data &&
            allChannelsData?.data.map((channel) => (
              <Col key={channel?.live_tv_source_id} xs={12} sm={8} md={6} lg={4}>
                <Card
                  hoverable
                  className="text-center"
                  bodyStyle={{ padding: "12px" }}
                  onClick={() => navigate(`/Channel?channel_id=${channel?.live_tv_source_id}`)}>
                  <Avatar size={64} src={channel.logo_url || "/placeholder.svg?height=64&width=64"} className="mb-2" />
                  <div
                    className="text-sm font-medium no-scrollbar"
                    style={{
                      position: "relative",
                      whiteSpace: "nowrap",
                      overflowX: "auto",
                      msOverflowStyle: "none",
                      scrollbarWidth: "none",
                    }}>
                    {channel?.tv_display_name}
                  </div>
                </Card>
              </Col>
            ))}
        </Row>
      )}
    </div>
  );
};

export default AllChannelList;
