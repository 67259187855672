import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { Avatar, Badge, Button, Card, Col, Empty, Pagination, Row, Skeleton, Tabs, Tooltip } from "antd";
import axios from "axios";
import BaseUrl from "../../utils/config";
import whatsappIcon from "../../imgaes/whatsappIcon.svg";
import videoPlayIcon from "../../imgaes/videoPlayIcon.svg";
import messageIcon from "../../imgaes/messageIcon.svg";
import smileIcon from "../../imgaes/smileIcon.svg";
import headphoneIcon from "../../imgaes/headphoneIcon.svg";
import adv1 from "../../imgaes/adv1.svg";
import adv2 from "../../imgaes/adv2.svg";
import AdvtHereImg from "../../imgaes/AdvtHereImg.png";
import adv12 from "../../imgaes/Advs/adv12.svg";
import adv13 from "../../imgaes/Advs/adv13.svg";
import adv14 from "../../imgaes/Advs/adv14.svg";
import noImg from "../../imgaes/noImg.svg";
import noImgJPG from "../../imgaes/noImgJPG.jpg";
import smileGif from "../../imgaes/smileGif.gif";
import cryIcon from "../../imgaes/cryIcon.gif";
import heartIcon from "../../imgaes/heartIcon.gif";
import angryIcon from "../../imgaes/angryIcon.gif";
import likeIcon from "../../imgaes/likeIcon.gif";
import wowIcon from "../../imgaes/wowIcon.gif";
import Modal from "../Modal";
import Emojis from "./Emojis";
import MorEellipsisIcon from "../Sections/Common/MorEellipsisIcon";
import CommentsModal from "./CommentsModal";
import { WhatsappShareButton } from "react-share";
import { shareStoryUrl } from "../../utils/config";
import TranslateWord from "./TranslateWord";
import { teluguFontStyle, teluguFontStyleHeading } from "../../Translation/Words";
import { useDispatch, useSelector } from "react-redux";
import { getAllPoliticsData } from "../../Store/PoliticsSlice";
import { getAllEntertainmentData } from "../../Store/EntertainmentSlice";
import { getAllSportsData } from "../../Store/SportsSlice";
import { getAllBusinessData } from "../../Store/BusinessSlice";
import { getAllCategoryData } from "../../Store/categoryWiseSlice";
import { getAllTechnologyData } from "../../Store/TechnologySlice";
import { getAllLivetvData } from "../../Store/LivetvSlice";
import LoadingSpinner from "./LoadingSpinner";
import { getAllVideosData } from "../../Store/VideosSlice";
import { getAllChannelsData } from "../../Store/ChannelsSlice";

let totalCards = 0;
let filterCategoryData = [];
let pageUpdate = 1;
let selectedIngerface = [];
let feedLangData = [];
let ALLWORD = "All";
const CategoryNews = (props) => {
  const { TabPane } = Tabs;
  let { category, subcategory } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let activeTab = "";
  let selectedLang = {};
  const [selectedFeedLang, setSelectedFeedLang] = useState({ name: "English" });
  const [sLang, setSLang] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [livetvData, setLivetvData] = useState([]);
  let loggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserDetails"));
  const { userSelectedNewsFeedData } = useSelector((state) => state.profileSettingsList);
  const {
    data: allPoliticsData,
    status: politicsStatus,
    error: politicsError,
    politicsSelectedLang,
  } = useSelector((state) => state.politicsList);
  const {
    data: allEntertainmentData,
    status: entertainmentStatus,
    error: entertainementError,
    entertainmentSelectedLang,
  } = useSelector((state) => state.entertainmentList);
  const {
    data: allSportsData,
    status: sportsStatus,
    error: sportsError,
    sportsSelectedLang,
  } = useSelector((state) => state.sportsList);
  const {
    data: allBusinessData,
    status: businessStatus,
    error: businessError,
    businessSelectedLang,
  } = useSelector((state) => state.businessList);
  const {
    data: allTechnologyData,
    status: technologyStatus,
    error: technologyError,
    technologySelectedLang,
  } = useSelector((state) => state.technologyList);
  const {
    data: allCategoryWiseData,
    status: categoryWiseStatus,
    error: categoryWiseError,
  } = useSelector((state) => state.categoryWiseList);
  const { selectedInterfaceLanguage } = useSelector((state) => state.interfaceLanguageList);
  const { data: feedLanguageData } = useSelector((state) => state.feedLanguageList);
  const { data: allCategoriesList } = useSelector((state) => state.categoriesList);
  const {
    data: allLivetvData,
    status: livetvStatus,
    error: livetvError,
    livetvSelectedLang,
  } = useSelector((state) => state.livetvList);
  const {
    data: allVideosData,
    status: videosStatus,
    error: videosError,
    videosSelectedLang,
  } = useSelector((state) => state.videosList);
  const {
    data: allChannelsData,
    status: channelsStatus,
    error: channelsError,
    channelsSelectedLang,
  } = useSelector((state) => state.channelsList);
  const trendingLives = livetvSelectedLang?.name === "తెలుగు" ? "ట్రెండింగ్ లైవ్స్" : "Trending Lives";
  const politikosLives = livetvSelectedLang?.name === "తెలుగు" ? "పాలిటికోస్  లైవ్" : "Politikos Live";
  const trending = livetvSelectedLang?.name === "తెలుగు" ? "ట్రెండింగ్" : "Trending";
  const imageStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40px",
    height: "40px",
    zIndex: 666,
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [category]);
  useEffect(() => {
    if (category === "రాజకీయాలు" || category === "Politics") {
      selectedLang = politicsSelectedLang;
      let langObj = { name: politicsSelectedLang?.name };
      setSelectedFeedLang(langObj);
      setCategoryData(allPoliticsData);
      ALLWORD = politicsSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
      activeTab = subcategory ?? translateWordFunc();
    } else if (category === "వినోదం" || category === "Entertainment") {
      selectedLang = entertainmentSelectedLang;
      let langObj = { name: entertainmentSelectedLang?.name };
      setSelectedFeedLang(langObj);
      setCategoryData(allEntertainmentData);
      ALLWORD = entertainmentSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
      activeTab = subcategory ?? translateWordFunc();
    } else if (category === "క్రీడలు" || category === "Sports") {
      selectedLang = sportsSelectedLang;
      let langObj = { name: sportsSelectedLang?.name };
      setSelectedFeedLang(langObj);
      setCategoryData(allSportsData);
      ALLWORD = sportsSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
      activeTab = subcategory ?? translateWordFunc();
    } else if (category === "వాణిజ్యం" || category === "Business") {
      selectedLang = businessSelectedLang;
      let langObj = { name: businessSelectedLang?.name };
      setSelectedFeedLang(langObj);
      setCategoryData(allBusinessData);
      ALLWORD = businessSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
      activeTab = subcategory ?? translateWordFunc();
    } else if (category === "సాంకేతికం" || category === "Technology") {
      selectedLang = technologySelectedLang;
      let langObj = { name: technologySelectedLang?.name };
      setSelectedFeedLang(langObj);
      setCategoryData(allTechnologyData);
      ALLWORD = technologySelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
      activeTab = subcategory ?? translateWordFunc();
    } else if (category === "లైవ్ టీవీ" || category === "Livetv") {
      selectedLang = livetvSelectedLang;
      let langObj = { name: livetvSelectedLang?.name };
      setSelectedFeedLang(langObj);
      setCategoryData(allLivetvData);
      ALLWORD = livetvSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
      activeTab = subcategory ?? translateWordFunc();
    } else if (category === "వీడియోలు" || category === "Videos") {
      selectedLang = videosSelectedLang;
      let langObj = { name: videosSelectedLang?.name };
      setSelectedFeedLang(langObj);
      setCategoryData(allVideosData);
      ALLWORD = videosSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
      activeTab = subcategory ?? translateWordFunc();
    } else if (category === "ఛానెల్స్" || category === "channels") {
      //11-12
      selectedLang = channelsSelectedLang;
      let langObj = { name: channelsSelectedLang?.name };
      setSelectedFeedLang(langObj);
      setCategoryData(allChannelsData);
      ALLWORD = channelsSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
      activeTab = subcategory ?? translateWordFunc();
    } else {
      selectedLang = selectedInterfaceLanguage;
      let langObj = { name: selectedInterfaceLanguage?.name };
      setSelectedFeedLang(langObj);
      setCategoryData(allCategoryWiseData);
      ALLWORD = selectedInterfaceLanguage?.name === "తెలుగు" ? "అన్ని" : "All";
      activeTab = subcategory ?? translateWordFunc();
    }
  }, [
    allPoliticsData,
    allEntertainmentData,
    allSportsData,
    allBusinessData,
    allCategoryWiseData,
    allLivetvData,
    allVideosData,
    allChannelsData, //11-12
  ]);

  const translateWordFunc = () => {
    if (category === "రాజకీయాలు" || category === "Politics") {
      return politicsSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
    } else if (category === "వినోదం" || category === "Entertainment") {
      return entertainmentSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
    } else if (category === "క్రీడలు" || category === "Sports") {
      return sportsSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
    } else if (category === "వాణిజ్యం" || category === "Business") {
      return businessSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
    } else if (category === "సాంకేతికం" || category === "Technology") {
      return technologySelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
    } else {
      return selectedInterfaceLanguage?.name === "తెలుగు" ? "అన్ని" : "All";
    }
  };

  // window.scrollTo(0, 0);
  // let storiesData = JSON.parse(localStorage.getItem("allStoriesList"));
  // const allCategoriesList = JSON.parse(
  //   localStorage.getItem("allCategoriesList")
  // );

  const [categoriesNewsData, setCategoriesNewsData] = useState([]);
  const [filteredStories, setFilteredStories] = useState([]);

  const [oldSubcategory, setOldSubcategory] = useState(subcategory);
  const [currentPage, setCurrentPage] = useState(1);
  feedLangData = JSON.parse(localStorage.getItem("feedLanguagesData")) ?? [];
  selectedIngerface = feedLangData?.filter((cat) => {
    if (cat?.newsfeed_lang_id == JSON.parse(localStorage.getItem("LanguageId"))) {
      return cat;
    }
  });

  const [allSubCategoriesList, setAllSubCategoriesList] = useState([]);
  const [activeTabColor, setActiveTabColor] = useState(subcategory ?? translateWordFunc());
  const [showCommentsDrawer, setShowCommentsDrawer] = useState(false);
  const [storyRecord, setStoryRecord] = useState(null);

  const urlPath = decodeURIComponent(window.location.pathname);

  const gettingData = (category, activeTab) => {
    let lang = {};

    if (category === "రాజకీయాలు" || category === "Politics") {
      lang = politicsSelectedLang;
    } else if (category === "వినోదం" || category === "Entertainment") {
      lang = entertainmentSelectedLang;
    } else if (category === "క్రీడలు" || category === "Sports") {
      lang = sportsSelectedLang;
    } else if (category === "వాణిజ్యం" || category === "Business") {
      lang = businessSelectedLang;
    } else if (category === "సాంకేతికం" || category === "Technology") {
      lang = technologySelectedLang;
    } else {
      lang = selectedInterfaceLanguage;
    }

    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === lang?.name;
    });
    console.log("feedLa", userSelectedFeedLanguages);

    let feedLangPayload = [];
    if (loggedInUserData?.user_id) {
      feedLangPayload = userSelectedNewsFeedData?.filter((item) => item.selected)?.map((item) => item.newsfeed_lang_id);
    } else {
      feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
        return feedL?.newsfeed_lang_id;
      });
    }
    setSLang(feedLangPayload);
    const payload = {
      category_name: category === "Livetv" || category === "Videos" ? activeTab : category,
      news_feed_lang_ids: feedLangPayload,
      page: 0,
      pageSize: 9,
      sub_category_name: activeTab !== "" ? activeTab : activeTabColor,
      login_user_id: loggedInUserData?.user_id,
      scetion_name: null,
      sub_category_name: category === "Livetv" || category === "Videos" ? null : activeTab,
    };

    const livetvPayload = {
      category_name: "All",
      interface_language_id: null,
      login_user_id: 66,
      news_feed_lang_ids: null,
      page: 0,
      pageSize: 10,
      scetion_name: null,
      source_id: null,
    };

    if (!loggedInUserData?.user_id) {
      delete payload.login_user_id;
    }
    if (
      activeTab === "Trending" ||
      activeTab === "ట్రెండింగ్" ||
      ((activeTab === "All" || activeTab === "అన్ని") && (category === "వీడియోలు" || category === "Videos"))
    ) {
      delete payload.category_name;
      payload.scetion_name = activeTab;
    }
    if (activeTab === "Trending" || activeTab === "ట్రెండింగ్") {
      //send "Trending" only
      payload.scetion_name = "Trending";
    }
    // if (activeTab === "All" || activeTab === "అన్ని" || activeTabColor === "All" || activeTabColor === "అన్ని") {
    //   delete payload.sub_category_name;
    // }
    if (activeTab === "All" || activeTab === "అన్ని" || activeTab === "") {
      payload.sub_category_name = "All";
    }
    if (payload?.news_feed_lang_ids?.length && (category === "రాజకీయాలు" || category === "Politics")) {
      dispatch(getAllPoliticsData(payload));
    } else if (payload?.news_feed_lang_ids?.length && (category === "వినోదం" || category === "Entertainment")) {
      dispatch(getAllEntertainmentData(payload));
    } else if (payload?.news_feed_lang_ids?.length && (category === "క్రీడలు" || category === "Sports")) {
      dispatch(getAllSportsData(payload));
    } else if (payload?.news_feed_lang_ids?.length && (category === "వాణిజ్యం" || category === "Business")) {
      dispatch(getAllBusinessData(payload));
    } else if (payload?.news_feed_lang_ids?.length && (category === "సాంకేతికం" || category === "Technology")) {
      dispatch(getAllTechnologyData(payload));
    } else if (category === "లైవ్ టీవీ" || category === "Livetv") {
      delete payload.sub_category_name;
      // dispatch(getAllLivetvData(payload));

      axios
        .post(`${BaseUrl}/livetvManagement/web/live/listing`, payload)
        .then((response) => {
          if (response.status === 200) {
            selectedLang = livetvSelectedLang;
            let langObj = { name: livetvSelectedLang?.name };
            setSelectedFeedLang(langObj);
            setCategoryData(response.data);
            ALLWORD = livetvSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
            // activeTab = subcategory ?? translateWordFunc();
          }
        })
        .catch((err) => console.error(err));
    } else if (payload?.news_feed_lang_ids?.length && (category === "వీడియోలు" || category === "Videos")) {
      dispatch(getAllVideosData(payload));
    } else if (payload?.news_feed_lang_ids?.length && (category === "ఛానెల్స్" || category === "Channels")) {
      //11-12
      dispatch(getAllChannelsData(payload)); //11-12
    } else {
      dispatch(getAllCategoryData(payload));
    }
  };
  useEffect(() => {
    let urlPath = window.location.pathname.split("/");
    console.log("Current URL:", urlPath[2]);
    urlPath[2] = decodeURIComponent(urlPath?.[2]?.replace(/%20/g, " "));
    if (oldSubcategory !== urlPath[2]) {
      setOldSubcategory(urlPath[2]);
      // setActiveTab(urlPath[2]);
      setActiveTabColor(urlPath[2]);
      activeTab = urlPath[2];
    }
    category = decodeURIComponent(urlPath[1]);
    // if (urlPath[2] == undefined) setTimeout(()=>{setActiveTab("All")},10);
    if (urlPath[2] == undefined || urlPath[2] == "undefined") {
      activeTab = translateWordFunc();
      setActiveTabColor(translateWordFunc());
    }

    // API calling for getting data from server
    gettingData(category, activeTab);
    // const subCategories = allCategoriesList?.find((item) => item.category_name === category)?.str_sub_categories_names;

    // if (subCategories?.length > 0) setAllSubCategoriesList([ALLWORD, ...subCategories]);

    //existing 08-11-2024.
    //   let subCategories = [];
    //   if (category === "Livetv" || category === "Videos") {
    //     subCategories = allCategoriesList
    //       .filter((allCat) => allCat?.interface_language?.name === livetvSelectedLang?.name)
    //       .map((allcat) => {
    //         return allcat.category_name;
    //       });
    //   } else {
    //     subCategories = allCategoriesList?.find((item) => item.category_name === category)?.str_sub_categories_names;
    //   }
    //   if (subCategories?.length > 0)
    //     if (category === "Livetv") setAllSubCategoriesList([ALLWORD, TRENDINGLIVES, POLITIKOSLIVES, ...subCategories]);
    //     else setAllSubCategoriesList([ALLWORD, ...subCategories]);
    // }, [window.location.pathname]);

    //new 08-11-2024.
    let subCategories = [];
    if (category === "Livetv" || category === "Videos") {
      subCategories = allCategoriesList
        .filter((allCat) => allCat?.interface_language?.name === livetvSelectedLang?.name)
        .map((allcat) => {
          return allcat.category_name;
        });
      category === "Livetv"
        ? subCategories.splice(0, 0, trendingLives, politikosLives)
        : subCategories.splice(0, 0, trending);
    } else {
      subCategories = allCategoriesList?.find((item) => item.category_name === category)?.str_sub_categories_names;
    }
    if (subCategories?.length > 0) setAllSubCategoriesList([ALLWORD, ...subCategories]);
  }, [window.location.pathname]);

  let cardsPerPage = 9;
  let indexOfLastCard = 6;
  let indexOfFirstCard = 0;
  // let displayedData = [];
  // const gettingStoriesData = (subcategory) => {
  //   if (storiesData?.length > 0) {
  //     if (
  //       (oldSubcategory == undefined || subcategory === activeTab) &&
  //       subcategory === translateWordFunc()
  //     ) {
  //       filterCategoryData = storiesData?.filter((entry) => {
  //         const categories = entry.categories || [];
  //         return categories.some((cat) => cat.name === category);
  //       });
  //     } else {
  //       filterCategoryData = storiesData?.filter((record) => {
  //         return (
  //           record?.categories &&
  //           record?.categories?.some((cat) =>
  //             cat?.sub_categories?.includes(subcategory)
  //           )
  //         );
  //       });
  //     }
  //   }

  //   totalCards =
  //     filterCategoryData?.length > 0 ? filterCategoryData?.length : 0;
  //   indexOfLastCard = currentPage * cardsPerPage;
  //   indexOfFirstCard = indexOfLastCard - cardsPerPage;
  //   const displayedData =
  //     filterCategoryData?.length > 0
  //       ? filterCategoryData?.slice(indexOfFirstCard, indexOfLastCard)
  //       : [];
  //   setCategoriesNewsData(displayedData);
  // };

  useEffect(() => {
    // gettingStoriesData(activeTab);
    // setCurrentPage(1);
  }, [category, activeTab]);
  const handlePageChange = (page, pageSize) => {
    // let pagNo = page-1;
    page && setCurrentPage(page);
    const payload = {
      category_name: category,
      news_feed_lang_ids: sLang,
      page: page - 1,
      pageSize: 9,
      sub_category_name: activeTab,
      login_user_id: loggedInUserData?.user_id, //loggedInUserData?.user_id
    };

    const livetvPayload = {
      category_name: "All",
      interface_language_id: null,
      login_user_id: 66,
      news_feed_lang_ids: null,
      page: 0,
      pageSize: 10,
      secction_name: null,
      source_id: null,
    };
    if (
      (activeTab === "All" || activeTab === "అన్ని" || activeTab === "") &&
      (category === "వీడియోలు" || category === "Videos")
    ) {
      payload.scetion_name = "All";
      delete payload.category_name;
    } else if (category === "Trending" || category === "ట్రెండింగ్") {
      payload.scetion_name = "Trending";
      delete payload.category_name;
    }
    if (!loggedInUserData?.user_id) {
      delete payload.login_user_id;
    }
    if (activeTab === "All" || activeTab === "అన్ని" || activeTab === "") {
      delete payload.sub_category_name;
    }
    if (payload?.news_feed_lang_ids?.length && (category === "రాజకీయాలు" || category === "Politics")) {
      dispatch(getAllPoliticsData(payload));
    } else if (payload?.news_feed_lang_ids?.length && (category === "వినోదం" || category === "Entertainment")) {
      dispatch(getAllEntertainmentData(payload));
    } else if (payload?.news_feed_lang_ids?.length && (category === "క్రీడలు" || category === "Sports")) {
      dispatch(getAllSportsData(payload));
    } else if (payload?.news_feed_lang_ids?.length && (category === "వాణిజ్యం" || category === "Business")) {
      dispatch(getAllBusinessData(payload));
    } else if (payload?.news_feed_lang_ids?.length && (category === "సాంకేతికం" || category === "Technology")) {
      dispatch(getAllTechnologyData(payload));
    } else if (payload?.news_feed_lang_ids?.length && (category === "లైవ్ టీవీ" || category === "Livetv")) {
      dispatch(getAllLivetvData(payload));
    } else if (payload?.news_feed_lang_ids?.length && (category === "వీడియోలు" || category === "Videos")) {
      dispatch(getAllVideosData(payload));
    } else if (payload?.news_feed_lang_ids?.length && (category === "ఛానెల్స్" || category === "Channels")) {
      dispatch(getAllChannelsData(payload));
    } else {
      dispatch(getAllCategoryData(payload));
    }
    // if(payload?.news_feed_lang_ids?.length)  dispatch(getAllCategoryData(payload))
    //   .then((action) => {
    //   if (getAllCategoryData.fulfilled.match(action)) {
    //     console.log('action',action)
    //     setCategoryData(action?.payload?.data)
    //   }
    // });;

    // indexOfLastCard = page * cardsPerPage;
    // indexOfFirstCard = indexOfLastCard - cardsPerPage;
    // totalCards =
    //   filterCategoryData?.length > 0 ? filterCategoryData?.length : 0;

    // const displayedData =
    //   filterCategoryData?.length > 0
    //     ? filterCategoryData?.slice(indexOfFirstCard, indexOfLastCard)
    //     : [];
    // setCategoriesNewsData(displayedData);
    // setCurrentPage(page);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [pageUpdate]);
  const hangleSelectingTab = (subcategory) => {
    setCurrentPage(1);
    pageUpdate = 1;
    setActiveTabColor(subcategory);
    activeTab = subcategory;
    gettingData(category, subcategory);
    // if (oldSubcategory != undefined && subcategory === translateWordFunc())
    //   setOldSubcategory(undefined);
    // setActiveTabColor(subcategory);
    // activeTab = subcategory;
  };

  const getLatestData = () => {
    gettingData(category, activeTabColor);
  };
  const hangleCommentsDrawer = (rec) => {
    setShowCommentsDrawer(true);
    setStoryRecord(rec);
  };

  const handleCloseDrawer = () => {
    setShowCommentsDrawer(false);
    setStoryRecord(null);
  };
  if (politicsStatus === "loading") {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }
  if (entertainmentStatus === "loading") {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }
  if (sportsStatus === "loading") {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  const goToSourceWitAuthorId = (input_user_id) => {
    navigate(`/GotoSource?param1=${input_user_id}&activeTab=${"Latest"}`);
  };
  // const subCategoryData = allStoriesDataList?.find(item => item?.category_name === "Business")?.map(val => val.str_sub_categories_names )
  return (
    <>
      <Row justify="center" align="middle">
        <Col span={24} style={{ textAlign: "center" }}>
          <Row justify="center">
            <Col>
              <h1
                className={`${
                  selectedInterfaceLanguage?.name === "తెలుగు" ? "telugu-text-title_lg" : "english-text-title_lg"
                }`}
                // style={selectedIngerface?.[0]?.name === "తెలుగు" ? teluguFontStyleHeading : {}}
              >
                {category}
              </h1>
            </Col>
          </Row>
          <Row justify="center">
            <Col style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div>
                <strong
                  className={`${selectedInterfaceLanguage?.name === "తెలుగు" ? "telugu-text" : "english-text-title"}`}>
                  <Link to="/">
                    {selectedFeedLang && <TranslateWord key={"home"} text={"HOME"} lang={selectedFeedLang} />}
                  </Link>
                </strong>
              </div>
              <div style={{ margin: "0 5px" }}>/</div>
              <div>
                {" "}
                <strong
                  className={`${selectedInterfaceLanguage?.name === "తెలుగు" ? "telugu-text" : "english-text-title"}`}>
                  {category}
                </strong>
              </div>
              {activeTabColor && (
                <>
                  <div style={{ margin: "0 5px" }}>/</div>
                  <div>
                    <strong
                      className={`${
                        selectedInterfaceLanguage?.name === "తెలుగు" ? "telugu-text" : "english-text-title"
                      }`}>
                      {activeTabColor}
                    </strong>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{padding: "5px" }}>
        <Col span={24}>
          {allSubCategoriesList?.map((type) => (
            <span
              key={type}
              onClick={() => hangleSelectingTab(type)}
              style={{
                display: "inline-block",
                marginRight: "10px",
                fontSize: "16px",
                cursor: "pointer",

                padding: "5px 10px",
                borderRadius: "4px",
              }}>
              <span>
                <strong
                  className={`${selectedInterfaceLanguage?.name === "తెలుగు" ? "telugu-text" : "english-text"}`}
                  style={{
                    color: activeTabColor === type ? "red" : "black",
                    borderBottom: activeTabColor === type ? "2px solid red" : "none",
                  }}>
                  {type}
                </strong>
              </span>
            </span>
          ))}
        </Col>
      </Row>

      <Row style={{ margin: "0vh 0.5vw" }}>
        <Col span={18} xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
          <Row gutter={[10, 16]}>
            {categoryData?.data?.length > 0 ? (
              categoryData?.data?.map((record) => {
                console.log("livcatd", record);
                const originalDate = new Date(record?.pubDate);
                const currentDate = new Date();
                const timeDifference = currentDate - originalDate;
                const hours = Math.floor(timeDifference / (1000 * 60 * 60));
                const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                const title = record.title ? record.title : record.video_title;
                const redirectURL =
                  category === "Livetv" || category === "Videos"
                    ? `/livetvdetails?param1=${record?.id}&category=${category}&subcategory=${activeTab}`
                    : `/about?param1=${record?.id}&category=${category}&subcategory=${activeTab}`;
                let displayTime;
                if (timeDifference < 24 * 60 * 60 * 1000) {
                  // Less than 24 hours
                  displayTime = hours > 0 ? `${hours} hrs ago` : minutes > 0 ? `${minutes} min ago` : "Just now";
                } else {
                  // More than 24 hours
                  displayTime = originalDate.toLocaleDateString("en-GB"); // Format the date as DD-MM-YYYY
                }
                return (
                  <>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                      <Card
                        // style={{
                        //   width: 300,
                        // }}
                        cover={
                          <Link
                            to={redirectURL}
                            class="img-opacity-hover"
                            onClick={() => {
                              window.scrollTo(0, 0);
                            }}>
                            {/* <img src={record?.image_urls?.[0]} alt="news" class="img-fluid width-100 mb-15 title_img" /> */}
                            {record?.image_urls?.[0] ? (
                              <img
                                src={record?.image_urls?.[0]}
                                alt="news"
                                style={{
                                  display: "block", // Ensures the image is treated as a block element
                                  margin: "0 auto", // Centers the image horizontally
                                  width: "100%", // Ensures the image doesn’t exceed the container width
                                  height: "auto",
                                  maxHeight: "28vh", // Limits height for small devices
                                  objectFit: "cover", // Prevents image distortion
                                  borderTopLeftRadius: "2%",
                                  borderTopRightRadius: "2%",
                                }}
                              />
                            ) : record?.video_link && record?.video_thumbnail_url ? ( //
                              <>
                                <span>
                                  <img style={imageStyle} src={videoPlayIcon} />
                                </span>{" "}
                                {category === "Livetv" && (
                                  <span style={{ display: "flex", justifyContent: "end" }}>
                                    <Button
                                      style={{
                                        backgroundColor: "#CC0001",
                                        color: "white",
                                        fontWeight: 600,
                                        borderWidth: "0px",
                                        position: "absolute",
                                      }}>
                                      Live
                                    </Button>
                                  </span>
                                )}
                                <img
                                  src={record?.video_thumbnail_url}
                                  alt="news"
                                  style={{
                                    display: "block",
                                    margin: "0 auto",
                                    width: "100%",
                                    height: "auto",
                                    maxHeight: "28vh",
                                    objectFit: "cover",
                                    backgroundColor: "#000000",
                                    borderTopLeftRadius: "2%",
                                    borderTopRightRadius: "2%",
                                  }}
                                />
                                {/* <video                               
                                  style={{
                                    display: "block", // Treats video as a block element
                                    margin: "0 auto", // Centers the video horizontally
                                    width: "100%",
                                    height: "32vh",
                                    objectFit: "contain", // Ensures video fits without distortion
                                  }}
                                  preload="metadata"
                                  src={`${record?.video_url}#t=0.1`}
                                  controls
                                  muted></video> */}
                              </>
                            ) : (
                              <>
                                <img
                                  src={noImgJPG}
                                  alt="No-Image"
                                  style={{
                                    display: "block",
                                    margin: "0 auto",
                                    width: "100%",
                                    height: "100%",
                                    maxHeight: "28vh",
                                    objectFit: "cover",
                                    backgroundColor: "#000000",
                                    borderTopLeftRadius: "2%",
                                    borderTopRightRadius: "2%",
                                  }}
                                />
                              </>
                            )}
                          </Link>
                        }>
                        <strong className={`${record?.language === "తెలుగు" ? "telugu-text" : "english-text"}`}>
                          {" "}
                          <Link
                            to={redirectURL}
                            onClick={() => {
                              window.scrollTo(0, 0);
                            }}>
                            <Tooltip
                              title={title
                                .replace(/<[^>]*>/g, "")
                                .replace(/&nbsp;|&emsp;/g, "")
                                .trim()}>
                              {title
                                .replace(/<[^>]*>/g, "")
                                .replace(/&nbsp;|&emsp;/g, "")
                                .trim()?.length > 35
                                ? title
                                    .replace(/<[^>]*>/g, "")
                                    .replace(/&nbsp;|&emsp;/g, "")
                                    .trim()
                                    ?.substring(0, 35) + "..."
                                : title
                                    .replace(/<[^>]*>/g, "")
                                    .replace(/&nbsp;|&emsp;/g, "")
                                    .trim()}
                            </Tooltip>
                          </Link>
                        </strong>
                        {category !== "Livetv" && category !== "Videos" && (
                          <>
                            <Row style={{ margin: "2vh 0vh" }}>
                              <Col span={12}>
                                <span>
                                  <i className="fa fa-user" aria-hidden="true"></i>
                                </span>
                                &nbsp;
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    goToSourceWitAuthorId(record?.user_id);
                                  }}>
                                  {record?.author?.length > 12 ? `${record?.author?.slice(0, 12)}...` : record?.author}
                                </span>
                              </Col>
                              <Col span={12} style={{ display: "flex", justifyContent: "end" }}>
                                <strong
                                  className="duration_slider"
                                  style={{
                                    marginTop: "3px",
                                  }}>
                                  {displayTime}
                                </strong>
                              </Col>
                            </Row>
                          </>
                        )}

                        {category !== "Livetv" && category !== "Videos" && (
                          <>
                            <Row style={{ margin: "2vh 0vh" }}>
                              <Col span={12}>
                                {record?.emoji_comments?.map((emj) => {
                                  return (
                                    <>
                                      <Badge count={emj?.count} size="small" placement="start">
                                        {/* <Avatar size="small" src={<img style={{width:'25px',height:'25px',cursor:'pointer'}} src={angryIcon}/>} /> */}
                                        {emj?.emoji_name === "angry" && (
                                          <Avatar
                                            size="small"
                                            src={
                                              <img
                                                style={{
                                                  width: "18px",
                                                  height: "18px",
                                                  cursor: "pointer",
                                                }}
                                                src={angryIcon}
                                              />
                                            }
                                          />
                                        )}
                                        {emj?.emoji_name === "love" && (
                                          <Avatar
                                            size="small"
                                            src={
                                              <img
                                                style={{
                                                  width: "18px",
                                                  height: "18px",
                                                  cursor: "pointer",
                                                }}
                                                src={heartIcon}
                                              />
                                            }
                                          />
                                        )}
                                        {emj?.emoji_name === "like" && (
                                          <Avatar
                                            size="small"
                                            src={
                                              <img
                                                style={{
                                                  width: "18px",
                                                  height: "18px",
                                                  cursor: "pointer",
                                                }}
                                                src={likeIcon}
                                              />
                                            }
                                          />
                                        )}
                                        {emj?.emoji_name === "laugh" && (
                                          <Avatar
                                            size="small"
                                            src={
                                              <img
                                                style={{
                                                  width: "18px",
                                                  height: "18px",
                                                  cursor: "pointer",
                                                }}
                                                src={smileGif}
                                              />
                                            }
                                          />
                                        )}
                                        {emj?.emoji_name === "sad" && (
                                          <Avatar
                                            size="small"
                                            src={
                                              <img
                                                style={{
                                                  width: "18px",
                                                  height: "18px",
                                                  cursor: "pointer",
                                                }}
                                                src={cryIcon}
                                              />
                                            }
                                          />
                                        )}
                                        {emj?.emoji_name === "wow" && (
                                          <Avatar
                                            size="small"
                                            src={
                                              <img
                                                style={{
                                                  width: "18px",
                                                  height: "18px",
                                                  cursor: "pointer",
                                                }}
                                                src={wowIcon}
                                              />
                                            }
                                          />
                                        )}
                                      </Badge>
                                    </>
                                  );
                                })}
                              </Col>
                              <Col span={12}>
                                <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                  <div style={{ marginTop: "2px" }}>
                                    <Emojis record={record} sendLatestData={getLatestData} />
                                  </div>
                                  <div>
                                    <Tooltip title="Coming soon" color="gray">
                                      <img src={headphoneIcon} className="icons_img" />
                                    </Tooltip>
                                  </div>
                                  <div style={{ marginTop: "2px" }}>
                                    <Tooltip title="Coming soon" color="gray">
                                      <img
                                        src={messageIcon}
                                        alt="icon"
                                        className="icons_img"

                                        // onClick={() => {
                                        //   hangleCommentsDrawer(
                                        //     record
                                        //   );
                                        // }}
                                      />
                                    </Tooltip>
                                  </div>
                                  <div>
                                    <WhatsappShareButton url={`${shareStoryUrl}/about?param1=${record?.id}`}>
                                      <img src={whatsappIcon} alt="icon" />
                                    </WhatsappShareButton>
                                  </div>
                                  <div style={{ marginTop: "2px" }}>
                                    <MorEellipsisIcon
                                      story={record}
                                      sendLatestData={getLatestData}
                                      bookMarkStoryId={record?.id}
                                      page="CategoryNewsPage"
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </Card>
                    </Col>
                  </>
                );
              })
            ) : (
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "center",
                  marginTop: "100px",
                }}>
                <Empty />
              </div>
            )}
          </Row>
        </Col>

        <Col
          span={5}
          xs={24}
          sm={24}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          style={{ borderLeft: "2px solid lightgray", marginLeft: "5px" }}
          className="responsive-border">
          <div style={{ marginLeft: "10px" }}>
            {/* <img src={adv12} alt="adv" class="img-fluid" style={{ marginBottom: "5px" }} /> */}
            {/* <img src={AdvtHereImg} alt="adv" class="img-fluid" style={{ marginBottom: "5px" }} />/ */}
          </div>
          <div style={{ marginLeft: "10px" }}>
            {/* <img src={adv13} alt="adv" class="img-fluid" style={{ marginBottom: "5px" }} /> */}
            {/* <img src={AdvtHereImg} alt="adv" class="img-fluid" style={{ marginBottom: "5px" }} /> */}
          </div>
          <div style={{ marginLeft: "10px" }}>
            {/* <img src={adv14} alt="adv" class="img-fluid" style={{ marginBottom: "5px" }} /> */}
            {/* <img src={AdvtHereImg} alt="adv" class="img-fluid" style={{ marginBottom: "5px" }} /> */}
          </div>
        </Col>
      </Row>
      <Row justify="end">
        <Col span={18}>
          {categoryData?.data?.length !== 0 && (
            <Pagination
              className="pull-right"
              current={currentPage}
              pageSize={cardsPerPage}
              total={categoryData?.totalElements}
              showSizeChanger={false}
              onChange={handlePageChange}
            />
          )}
        </Col>
      </Row>
      {showCommentsDrawer && (
        <CommentsModal showDrawer={showCommentsDrawer} closeDrawer={handleCloseDrawer} story={storyRecord} />
      )}
    </>
  );
};

export default CategoryNews;
