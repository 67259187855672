import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Avatar, Badge, Card, Col, Empty, Pagination, Row, Tabs, Tooltip } from "antd";

import whatsappIcon from "../../../imgaes/whatsappIcon.svg";
import messageIcon from "../../../imgaes/messageIcon.svg";
import smileIcon from "../../../imgaes/smileIcon.svg";
import headphoneIcon from "../../../imgaes/headphoneIcon.svg";
import adv1 from "../../../imgaes/adv1.svg";
import adv2 from "../../../imgaes/adv2.svg";
import noImg from "../../../imgaes/noImg.svg";
import noImgJPG from "../../../imgaes/noImgJPG.jpg";
import smileGif from '../../../imgaes/smileGif.gif';
import cryIcon from '../../../imgaes/cryIcon.gif';
import heartIcon from '../../../imgaes/heartIcon.gif';
import angryIcon from '../../../imgaes/angryIcon.gif';
import likeIcon from '../../../imgaes/likeIcon.gif';
import wowIcon from '../../../imgaes/wowIcon.gif';
import Modal from "../../Modal";
import Emojis from "../../sharedComponents/Emojis";
import Header from './../../Header';
import Footer from './../../Footer';
import MorEellipsisIcon from "./MorEellipsisIcon";
import { WhatsappShareButton } from "react-share";
import { shareStoryUrl } from "../../../utils/config";


let totalCards = 0;
let displayedData = [];
let cardsPerPage = 6;
let indexOfLastCard = 6
let indexOfFirstCard = 0
function BookMarkedStories() {
  const { TabPane } = Tabs;
  // window.scrollTo(0, 0);
  const navigate = useNavigate();
  const storiesData = JSON.parse(localStorage.getItem("bookmarkedStoriesList"));
  const allCategoriesData = JSON.parse(
    localStorage.getItem("allCategoriesList")
  );
  console.log("allCategoriesData", allCategoriesData);
  const [allbookMarkedStoriesList, setAllbookMarkedStoriesList] = useState(storiesData);
  const [filteredStories, setFilteredStories] = useState([]);
  let { category, subcategory } = useParams();
  const [oldSubcategory, setOldSubcategory] = useState(subcategory);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState(subcategory ?? "All");
  const [storyRecord, setStoryRecord] = useState(null);
  const [showCommentsDrawer, setShowCommentsDrawer] = useState(false);
  const subCategories = allCategoriesData?.find(
    (item) => item.category_name === category
  )?.str_sub_categories_names;

  subCategories?.unshift("All");
  console.log("subCategories", subCategories);

  useEffect(() => {
    const urlPath = window.location.pathname.split("/");
    console.log("Current URL:", urlPath[2]);
    urlPath[2] = urlPath?.[2]?.replace(/%20/g, ' ');
    if (oldSubcategory !== urlPath[2]) {
      setOldSubcategory(urlPath[2]);
      setActiveTab(urlPath[2]);
    }
    category = urlPath[1];    
    if (urlPath[2] == undefined) setActiveTab("All");
  }, [window.location.pathname]);

 

  const gettingStoriesData = () => {
    // let filterCategoryData = [];
    // if (allStoriesDataList?.length > 0) {
    //   if (oldSubcategory == undefined && subcategory === "All") {
    //     filterCategoryData = allStoriesDataList?.filter((entry) => {
    //       const categories = entry.categories || [];
    //       return categories.some((cat) => cat.name === category);
    //     });
    //   } else {
    //     filterCategoryData = allStoriesDataList?.filter((record) => {
    //       return (
    //         record?.categories &&
    //         record?.categories?.some((cat) =>
    //           cat?.sub_categories?.includes(subcategory)
    //         )
    //       );
    //     });
    //   }
    // }

    totalCards =
    storiesData?.length > 0 ? storiesData?.length : 0;
     indexOfLastCard = currentPage * cardsPerPage;
     indexOfFirstCard = indexOfLastCard - cardsPerPage;
  
     displayedData =
     storiesData?.length > 0
          ? storiesData?.slice(indexOfFirstCard, indexOfLastCard)
          : [];

       setAllbookMarkedStoriesList(displayedData)   
      // const stories_ids = storiesData?.map((val) =>{
      //        return val?.story_id
      // });
      // const allStoriesData = JSON.parse(localStorage.getItem("allStoriesList"));
      // const filterData = allStoriesData?.filter((s) =>{
      //   if(stories_ids?.includes(s?.id))
      //   return s
      // })
      // displayedData =
      // filterData?.length > 0
      //     ? filterData?.slice(indexOfFirstCard, indexOfLastCard)
      //     : [];
      //     // console.log('displayedData',stories_ids)
      //     console.log('displayedData',storiesData)
      //     console.log('displayedData',displayedData)

  };

useEffect(()=>{  gettingStoriesData();},[])
  const handlePageChange = (page, pageSize) => {
    indexOfLastCard = page * cardsPerPage;
    indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const updatedBookMarksStoriesData = JSON.parse(localStorage.getItem("bookmarkedStoriesList"));
    totalCards = updatedBookMarksStoriesData?.length > 0 ? updatedBookMarksStoriesData?.length : 0;

  const displayedData =
  updatedBookMarksStoriesData?.length > 0
      ? updatedBookMarksStoriesData?.slice(indexOfFirstCard, indexOfLastCard)
      : [];
  setAllbookMarkedStoriesList(displayedData);  
  setCurrentPage(page); 
  };
  const getLatestData = (data) =>{
    // console.log('latestData',data);
    // let url = window.location.pathname   
    // navigate(`/loading`)
    gettingStoriesData()
  
// if(displayedData?.length > 0)  setTimeout(()=>{ navigate(`${url}`)},10);    
//   else  setTimeout(()=>{ navigate(`/`)},10);
}


const hangleCommentsDrawer = (rec)=>{
  setShowCommentsDrawer(true) 
  setStoryRecord(rec)     
}  

const handleCloseDrawer = ()=>{
  setShowCommentsDrawer(false);
  setStoryRecord(null)
}

  // const subCategoryData = allStoriesDataList?.find(item => item?.category_name === "Business")?.map(val => val.str_sub_categories_names )
  return (
    <>     
        <section class="bg-body">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <div class="politics foryou_page">
                  <div class="heading_page">
                    <h1>Book Marked Stories</h1>
                  </div>
                  {/* <div class="breadcrumbs-area">
                    <div class="breadcrumbs-content">
                      <ul>
                        <li>
                          <Link to="/">Home </Link> /{" "}
                        </li>
                        <li>{category} </li>
                        {activeTab && <li> / {activeTab} </li>}
                      </ul>
                    </div>
                  </div> */}
                  <div class="mb-20-r ne-isotope mt-30">
                    <div class="color-cinnabar ">
                      {/* <div class="isotope-classes-tab isotop-btn">
                        {subCategories?.map((type) => {
                          return (
                            <span
                              onClick={() => hangleSelectingTab(type)}
                              style={{
                                marginRight: "20px",
                                fontSize: "16px",
                                cursor: "pointer",
                                color: activeTab === type ? "red" : "black",
                              }}
                            >
                              {type}
                            </span>
                          );
                        })}
                      </div> */}
                      <div class="featuredContainer politics_container">
                        <div class="row allforyou slider_setting">
                          <div class="col-lg-9 col-md-9 mt-20">
                            <div class="row">
                              {allbookMarkedStoriesList?.length > 0 ? (
                                allbookMarkedStoriesList?.map((record) => {
                                  const originalDate = new Date(
                                    record?.pubDate
                                  );
                                  const currentDate = new Date();
                                  const timeDifference =
                                    currentDate - originalDate;
                                  const hours = Math.floor(
                                    timeDifference / (1000 * 60 * 60)
                                  );
                                  const minutes = Math.floor(
                                    (timeDifference % (1000 * 60 * 60)) /
                                      (1000 * 60)
                                  );

                                  let displayTime;
                                  if (timeDifference < 24 * 60 * 60 * 1000) {
                                    // Less than 24 hours
                                    displayTime =
                                      hours > 0 ? `${hours} hrs ago` : minutes > 0 ? `${minutes} min ago` : "Just now";
                                  } else {
                                    // More than 24 hours
                                    displayTime =
                                      originalDate.toLocaleDateString("en-GB"); // Format the date as DD-MM-YYYY
                                  }
                                  return (
                                    <>
                                      <div class="col-sm-4 col-12">
                                        <div class="position-relative mt-10 mt-10 forpage_content">
                                          <Link
                                            to={`/about?param1=${record?.id}&category=${record?.categories?.[0]?.name ?? category}&subcategory=${activeTab}`}
                                            class="img-opacity-hover"
                                          >
                                            {/* <img src={record?.image_urls?.[0]} alt="news" class="img-fluid width-100 mb-15 title_img" /> */}
                                            {record?.image_urls?.[0] ? (
                                              <img
                                                src={record?.image_urls?.[0]}
                                                alt="news"
                                                className="img-fluid width-100 mb-15 title_img"
                                              />
                                            ) : (
                                              <img src={noImgJPG} alt="No-Image" />
                                            )}
                                          </Link>
                                          <Link
                                            to={`/about?param1=${record?.id}&category=${record?.categories?.[0]?.name ?? category}&subcategory=${activeTab}`}
                                            class="title-medium-dark size-lg mb-none"
                                          >
                                             {/* {record?.title.length > 60 ? record?.title.substring(0, 60) + "..." : record?.title} */}
                                             {record?.title.replace(/<[^>]*>/g, "").replace(/&nbsp;|&emsp;/g, "").trim()}
                                          </Link>
                                          <Row>
                                              <Col
                                                span={16}
                                                style={{
                                                  marginLeft: "8px",
                                                }}>
                                                <span>
                                                  <i
                                                    className='fa fa-user'
                                                    aria-hidden='true'></i>
                                                </span>{" "}
                                                &nbsp;
                                                {/* {record?.author.length > 5 ? record?.author.substring(0, 5) + "..." : record?.author} */}
                                                {record?.author}
                                              </Col>
                                              <Col span={7}>
                                                <p
                                                  className='duration_slider'
                                                  style={{
                                                    marginTop: "3px",
                                                  }}>
                                                  {displayTime}
                                                </p>
                                              </Col>
                                            </Row>
                                            <Row
                                              style={{
                                                marginBottom: "10px",
                                                marginTop: "5px",
                                              }}>
                                              <Col
                                                span={11}
                                                style={{
                                                  marginTop: "-8px",
                                                  marginLeft: "5px",
                                                }}>
                                                {record?.emoji_comments?.map(
                                                  (emj) => {
                                                    return (
                                                      <>
                                                        <Badge count={emj?.count} size="small" placement='start'
                                                       
                                                          >
                                                          {/* <Avatar size="small" src={<img style={{width:'25px',height:'25px',cursor:'pointer'}} src={angryIcon}/>} /> */}
                                                          {emj?.emoji_name ===
                                                            "angry" && (                                                           
                                                            <Avatar size="small" src={<img style={{width:'18px',height:'18px',cursor:'pointer'}} src={angryIcon}/>} />
                                                          )}
                                                          {emj?.emoji_name ===
                                                            "love" && (
                                                              <Avatar size="small" src={<img style={{width:'18px',height:'18px',cursor:'pointer'}} src={heartIcon}/>} />
                                                          
                                                          )}
                                                          {emj?.emoji_name ===
                                                            "like" && (
                                                              <Avatar size="small" src={<img style={{width:'18px',height:'18px',cursor:'pointer'}} src={likeIcon}/>} />
                                                          
                                                          )}
                                                          {emj?.emoji_name ===
                                                            "laugh" && (
                                                              <Avatar size="small" src={<img style={{width:'18px',height:'18px',cursor:'pointer'}} src={smileGif}/>} />
                                                           
                                                          )}
                                                          {emj?.emoji_name ===
                                                            "sad" && (
                                                              <Avatar size="small" src={<img style={{width:'18px',height:'18px',cursor:'pointer'}} src={cryIcon}/>} />
                                                           
                                                          )}
                                                          {emj?.emoji_name ===
                                                            "wow" && (
                                                              <Avatar size="small" src={<img style={{width:'18px',height:'18px',cursor:'pointer'}} src={wowIcon}/>} />
                                                           
                                                          )}
                                                        </Badge>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </Col>
                                              <Col span={3}
                                                style={{
                                                  marginTop: "-6px",
                                                }}>
                                                  <ul>
                                                  <li className='float-right'>
                                                    <span className='social_icons'>
                                                    <Emojis
                                                          record={record}
                                                          sendLatestData={
                                                            getLatestData
                                                          }
                                                        />
                                                    </span>
                                                    </li>
                                                    </ul>
                                              </Col>
                                              <Col
                                                span={9}
                                                style={{
                                                  marginTop: "-6px",
                                                }}>
                                                <ul>
                                                  <li className='float-right'>
                                                    <span className='social_icons'>
                                                      {/* <Tooltip
                                                    title="Coming soon"
                                                    color="gray"
                                                  >
                                                    <img
                                                      src={smileIcon}
                                                      alt="icon"
                                                      className="icons_img"
                                                    />
                                                  </Tooltip> */}                                                     
                                                     
                                                    
                                                      <Tooltip
                                                        title='Coming soon'
                                                        color='gray'>
                                                        <img
                                                          src={headphoneIcon}
                                                          className='icons_img'
                                                        />
                                                      </Tooltip>
                                                      <Tooltip title="Coming soon" color="gray">
                                    <img
                                      src={messageIcon}
                                      alt="icon"
                                      className="icons_img"
                                      style={{
                                        position: "relative",
                                        top: "2px",
                                      }}
                                      // onClick={() => {
                                      //   hangleCommentsDrawer(record);
                                      // }}
                                    />                                   
                                     </Tooltip>
                                                       <WhatsappShareButton
                                                      url={`${shareStoryUrl}/about?param1=${record?.id}`}
                                                      className="Demo__some-network__share-button"
                                                    >
                                                      <img
                                                          src={whatsappIcon}
                                                          alt='icon'
                                                          className='icons_img'
                                                        />
                                                    </WhatsappShareButton>

                                                      <MorEellipsisIcon
                                                        story={record}
                                                        sendLatestData={
                                                          getLatestData
                                                        }
                                                        bookMarkStoryId={
                                                          record?.id
                                                        }
                                                        page='BookmarkedPage'
                                                      />
                                                    </span>
                                                  </li>
                                                </ul>
                                              </Col>
                                            </Row>
                                          
                                        </div>
                                      </div>
                                    </>
                                  );
                                })
                              ) : (
                                <div
                                  style={{
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    textAlign: "center",
                                    marginTop: "100px",
                                  }}
                                >
                                  <Empty />
                                </div>
                              )}
                            </div>
                            {allbookMarkedStoriesList?.length !== 0 && (
                              <Pagination
                                className="pull-right"
                                current={currentPage}
                                pageSize={cardsPerPage}
                                total={totalCards}
                                onChange={handlePageChange}
                              />
                            )}
                          </div>
                          <div class="ne-sidebar sidebar-break-md col-lg-3 col-md-12 mt-30">
                            <div class="sidebar-box">
                              <div class="ne-banner-layout1 text-center">
                                <a href="/">
                                  <img
                                    src={adv2}
                                    alt="adv"
                                    class="img-fluid"
                                    style={{ marginBottom: "5px" }}
                                  />
                                </a>
                                <a href="/">
                                  <img src={adv1} alt="adv" class="img-fluid" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr></hr>
          </div>
        </section>       
    </>
  );
}

export default BookMarkedStories;
