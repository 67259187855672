import React, { useEffect, useState } from "react";
import { Button, Grid, Empty } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { getAllLensData } from "../../../Store/LensSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const { useBreakpoint } = Grid;
const LensSection = ({ categoryName }) => {
  let category = categoryName;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const [currentIndex, setCurrentIndex] = useState(0);
  let loggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserDetails"));
  const { userSelectedNewsFeedData } = useSelector((state) => state.profileSettingsList);
  const { data: interfaceLanguagesData, selectedInterfaceLanguage } = useSelector(
    (state) => state.interfaceLanguageList
  );
  const {
    data: allLensData,
    allLenscategories,
    status,
    error,
    lensSelectedLang,
  } = useSelector((state) => state.lensList);
  const { data: feedLanguageData, selectedfeedLanguage } = useSelector((state) => state.feedLanguageList);

  const [currentPage, setCurrentPage] = useState(0);

  const gettingLensData = () => {
    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === lensSelectedLang?.name;
    });

    let feedLangPayload = [];
    if (loggedInUserData?.user_id) {
      feedLangPayload = userSelectedNewsFeedData?.filter((item) => item.selected)?.map((item) => item.newsfeed_lang_id);
    } else {
      feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
        return feedL?.newsfeed_lang_id;
      });
    }
    const payload = {
      category_name: "",
      interface_language_id: lensSelectedLang?.interface_lang_id,
      is_all: true,
      is_category: false,
      is_trending: false,
      login_user_id: loggedInUserData?.user_id,
      news_feed_lang_ids: feedLangPayload,
      page: 0,
      pageSize: 10,
    };
    if (!loggedInUserData?.user_id) {
      payload.login_user_id = null;
    }
    if (categoryName === "అన్నీ" || categoryName === "All") {
      //అన్ని
      payload.is_all = true;
      payload.is_trending = false;
      category = "All";
    } else if (categoryName === "Trending" || categoryName === "ట్రెండింగ్") {
      payload.is_all = false;
      payload.is_trending = true;
      category = "Trending";
    } else {
      payload.is_all = false;
      payload.is_category = true;
      payload.category_name = categoryName;
    }
    dispatch(getAllLensData(payload));
  };
  useEffect(() => {
    gettingLensData();
  }, [lensSelectedLang, selectedfeedLanguage]);
  const itemsPerPage = 3;

  const lensData =
    allLensData?.data && allLensData?.data[categoryName] && allLensData?.data[categoryName].length > 0
      ? allLensData?.data[categoryName]
      : [];
  const handleScroll = (direction) => {
    const container = document.getElementById("lens-container");
    if (container) {
      const scrollAmount = 600;
      const scrollPosition =
        direction === "left" ? container.scrollLeft - scrollAmount : container.scrollLeft + scrollAmount;
      container.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
    if (direction === "left") {
      setCurrentIndex((prev) => Math.max(0, prev - 1));
    } else {
      setCurrentIndex((prev) => Math.min(allLensData?.data?.length - 1, prev + 1));
    }
  };
  const gotoLensWithId = (id) => {
    navigate(`/lensDetails?param1=${id}&category=${category}`);
  };
  const english = /^[A-Za-z0-9]*$/;
  const titleFont = (slideTitle) => {
    if (slideTitle && english.test(slideTitle[0])) {
      return " Poppins";
    }
    return "Mallanna";
  };

  const titleFontSize = (slideTitle) => {
    if (slideTitle && english.test(slideTitle[0])) {
      return "16px";
    }
    return "20px";
  };
  return (
    <>
      {status === "idle" && allLensData?.data?.length === 0 ? (
        <div className="item">
          <div className="img-overlay-69 img-scale-animate main_slider_tabs  main_slider_all_politics">
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
                marginTop: "100px",
                marginBottom: "70px",
              }}>
              <Empty />
              <h4 style={{ color: "white" }}>No data </h4>
            </div>
          </div>
        </div>
      ) : (
        <div className="news-cards-wrapper" style={{ marginTop: screens.xs ? "0px" : "-30px" }}>
          {screens.xs ? (
            ""
          ) : (
            <>
              {currentIndex > 0 && (
                <Button
                  style={{ marginLeft: "10px" }}
                  className="scroll-button left"
                  icon={<LeftOutlined />}
                  onClick={() => handleScroll("left")}
                />
              )}
              {currentIndex < allLensData?.data?.length - 6 && (
                <Button
                  style={{ marginRight: "10px" }}
                  className="scroll-button right"
                  icon={<RightOutlined />}
                  onClick={() => handleScroll("right")}
                />
              )}
            </>
          )}
          <div
            id="lens-container"
            className="news-cards-container-lens"
            style={{ display: "flex", flexDirection: "row", overflowX: "auto" }}>
            {allLensData?.data?.map((lensData) => (
              <div style={{ height: "425px", width: "300px" }}>
                <div
                  onClick={() => gotoLensWithId(lensData?.lens_id)}
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    textAlign: "center",
                    cursor: "pointer",
                    width: "197px",
                    height: "350px",
                    objectFit: "contain",
                    backgroundColor: "black",
                    marginRight: "25px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}>
                  {lensData?.lens_url && lensData?.video_thunbnail_url ? (
                    <img
                      src={lensData?.video_thunbnail_url}
                      alt={lensData.title}
                      layout="fill"
                      style={{ width: "200px", height: "350px", objectFit: "contain" }}
                    />
                  ) : (
                    <img
                      src={lensData?.lens_url}
                      alt={lensData.title}
                      layout="fill"
                      style={{ width: "199px", height: "352px", objectFit: "contain" }}
                    />
                  )}
                </div>
                <div style={{ width: "199px", height: "50px" }}>
                  <h1
                    style={{
                      fontFamily: `${titleFont(lensData?.title)}`,
                      fontSize: `${titleFontSize(lensData?.title)}`,
                      fontWeight: "600",
                      lineHeight: "25px",
                    }}>
                    {lensData?.title?.length > 30 ? lensData.title.slice(0, 38) + "..." : lensData?.title}
                  </h1>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default LensSection;
