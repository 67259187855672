import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = {
  data: [],
  status: "idle",
  error: "",
  lensSelectedLang: {},
  lensCategories: [],
  selectedLensRecordDetails: [],
  lensListingDetails: [],
};

const LensSlice = createSlice({
  name: "lens",
  initialState,
  reducers: {
    selectedLensInterfaceAction: (state, action) => {
      state.lensSelectedLang = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllLensData.pending, (state, action) => {
      state.data = [];
      state.status = "loading";
    });
    builder.addCase(getAllLensData.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.status = "idle";
    });
    builder.addCase(getAllLensData.rejected, (state, action) => {
      state.data = [];
      state.status = "error";
    });
    builder.addCase(getLenscategories.pending, (state, action) => {
      state.lensCategories = [];
      state.status = "loading";
    });
    builder.addCase(getLenscategories.fulfilled, (state, action) => {
      state.lensCategories = action?.payload;
      state.status = "idle";
    });
    builder.addCase(getLenscategories.rejected, (state, action) => {
      state.lensCategories = [];
      state.status = "error";
    });
    builder.addCase(getLensDetailsByID.pending, (state, action) => {
      state.selectedLensRecordDetails = [];
      state.status = "loading";
    });
    builder.addCase(getLensDetailsByID.fulfilled, (state, action) => {
      state.selectedLensRecordDetails = action?.payload;
      state.status = "idle";
    });
    builder.addCase(getLensDetailsByID.rejected, (state, action) => {
      state.selectedLensRecordDetails = [];
      state.status = "error";
    });
    builder.addCase(getLenslistingData.pending, (state, action) => {
      state.selectedLensRecordDetails = [];
      state.status = "loading";
    });
    builder.addCase(getLenslistingData.fulfilled, (state, action) => {
      state.selectedLensRecordDetails = action?.payload;
      state.status = "idle";
    });
    builder.addCase(getLenslistingData.rejected, (state, action) => {
      state.selectedLensRecordDetails = [];
      state.status = "error";
    });
    builder.addCase(addBookmarklens.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(addBookmarklens.fulfilled, (state, action) => {
      state.status = "idle";
    });
    builder.addCase(addBookmarklens.rejected, (state, action) => {
      state.status = "error";
    });
  },
});

export const getAllLensData = createAsyncThunk("lens/get", async (payload) => {
  const data = await fetch(`${BaseUrl}/lens/web/listing`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});
export const getLenscategories = createAsyncThunk("lenscategories/get", async (payload) => {
  const data = await fetch(`${BaseUrl}/LensCategory/available/${payload}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const result = await data.json();
  return result;
});
export const getLensDetailsByID = createAsyncThunk("lensDetailsById/get", async (payload) => {
  const URL = payload?.login_user_id
    ? `${BaseUrl}/lens/loginuser?login_user_id=${payload.login_user_id}&lens_id=${payload.lens_id}`
    : `${BaseUrl}/lens/loginuser?lens_id=${payload.lens_id}`;
  const data = await fetch(URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const result = await data.json();
  return result;
});
export const getLenslistingData = createAsyncThunk("lenslisting/get", async (payload) => {
  const data = await fetch(`${BaseUrl}/lens/listing`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});
export const addBookmarklens = createAsyncThunk("addBookmarkLens/post", async (payload) => {
  const data = await fetch(`${BaseUrl}/lensbookmark`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});
export const removeBookmarkLens = createAsyncThunk("removeBookmarkLens/get", async (payload) => {
  const data = await fetch(`${BaseUrl}/lensbookmark?login_user_id=${payload.userId}&lens_id=${payload.lens_id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const result = await data.json();
  return result;
});
export const { selectedLensInterfaceAction } = LensSlice.actions;
export default LensSlice.reducer;
