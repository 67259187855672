import { Button, Col, Divider, Row, Grid, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import userProfilePic from "../../imgaes/userProfilePic.svg";
import settingsIcon from "../../imgaes/settingsIcon.svg";
import FAQsIcon from "../../imgaes/FAQsIcon.svg";
import SettingsModel from "./SettingsModel";
import ProfileAboutYourSelf from "./ProfileAboutYourSelf";
import { useDispatch, useSelector } from "react-redux";
import {
  getEducationalQualificationData,
  getUserProfileData,
  getWorkExperienceData,
} from "../../Store/ProfileSettingsSlice";
import { getGenderData } from "../../Store/GenderSlice";
import { getMaritalStatusData } from "../../Store/MaritalStatusSlice";
import { useNavigate } from "react-router-dom";
import SubmissionsTab from "./SubmissionsTab";
import TranslateWord from "../sharedComponents/TranslateWord";
import { getJoinVolunteerInfoData } from "../../Store/JoinVolunteerSlice";
import { getAutoPlayData } from "../../Store/AutoPlaySlice";
const { useBreakpoint } = Grid;
const ProfileDB = () => {
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const [settingsFlag, setSettingsFlag] = useState(false);
  const [type, setType] = useState("About your self");
  const dispatch = useDispatch();
  let user = JSON.parse(localStorage.getItem("LoggedInUserDetails"));
  const { userProfileData } = useSelector((state) => state.profileSettingsList);
  const { selectedInterfaceLanguage } = useSelector((state) => state.interfaceLanguageList);
  const getWEandEQ = (interface_lang_id) => {
    dispatch(getWorkExperienceData(interface_lang_id));
    dispatch(getEducationalQualificationData(interface_lang_id));
  };
  useEffect(() => {
    dispatch(getUserProfileData(user?.user_id)).then((action) => {
      if (getUserProfileData.fulfilled.match(action)) {
        console.log("getUserProfileData", action);
        getWEandEQ(action?.payload?.data?.selected_interface_language?.interface_lang_id);
      }
    });
    dispatch(getGenderData());
    dispatch(getMaritalStatusData());
    dispatch(getAutoPlayData(user?.user_id));
  }, []);
  const handleSettingsModel = () => {
    setSettingsFlag(true);
  };
  const navigateToFAQs = () => {
    navigate("/faqs");
  };
  const handleJoinVolunteer = () => {
    navigate("/JoinVolunteer");
    let user_id = 239;
    dispatch(getJoinVolunteerInfoData(user_id));
  };

  const handleModel = () => {
    setSettingsFlag(false);
  };
  const handleDashboardData = (type) => {
    setType(type);
  };
  return (
    <>
      <div className="container">
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col>
            <h1
              className={`${
                selectedInterfaceLanguage?.name === "తెలుగు" ? "telugu-text-title_lg" : "english-text-title_xlg"
              }`}>
              <TranslateWord key={"PROFILE"} text={"PROFILE"} lang={selectedInterfaceLanguage} headingStyle={true} />
            </h1>
          </Col>
        </Row>
        <Row justify="space-evenly" gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Row justify="space-evenly">
              <Col xs={24} sm={24} md={6}>
                {userProfileData?.profile_url ? (
                  <img
                    src={userProfileData?.profile_url}
                    style={{ borderRadius: "50%", height: "150px", width: "150px" }}
                  />
                ) : (
                  <img src={userProfilePic} />
                )}
              </Col>
              <Col xs={24} sm={24} md={18} style={{ position: "relative", top: "3vh" }}>
                <h1 style={{ fontWeight: "bold" }}>
                  {userProfileData?.user_profile?.display_name || userProfileData?.user_profile?.firstname}
                </h1>
                <h2>
                  <TranslateWord key={"USER"} text={"USER"} lang={selectedInterfaceLanguage} headingStyle={true} />{" "}
                  {`(ID#${userProfileData?.user_id})`}
                </h2>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h2>
                    {" "}
                    {`${userProfileData?.stories}`}{" "}
                    <TranslateWord key={"POSTS"} text={"POSTS"} lang={selectedInterfaceLanguage} headingStyle={true} />{" "}
                  </h2>
                  <h2>
                    {" "}
                    {`${userProfileData?.followers_count}`}{" "}
                    <TranslateWord
                      key={"FOLLOWERS"}
                      text={"FOLLOWERS"}
                      lang={selectedInterfaceLanguage}
                      headingStyle={true}
                    />{" "}
                  </h2>
                  <h2>
                    {" "}
                    {`${userProfileData?.following_count}`}{" "}
                    <TranslateWord
                      key={"FOLLOWING"}
                      text={"FOLLOWING"}
                      lang={selectedInterfaceLanguage}
                      headingStyle={true}
                    />{" "}
                  </h2>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={4} style={{ margin: "relative", top: "2vh" }}>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div onClick={handleSettingsModel} style={{ cursor: "pointer" }}>
                <img src={settingsIcon} />
                <TranslateWord
                  key={"SETTINGS"}
                  text={"SETTINGS"}
                  lang={selectedInterfaceLanguage}
                  headingStyle={false}
                />
              </div>
              <div onClick={navigateToFAQs} style={{ cursor: "pointer" }}>
                <img src={FAQsIcon} />
                <TranslateWord key={"FAQs"} text={"FAQs"} lang={selectedInterfaceLanguage} headingStyle={false} />
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-around", marginTop: screens.xs ? "20px" : "50px" }}>
              <div style={{ fontSize: "18px", fontFamily: "Poppins", fontWeight: "600", color: "#01579B" }}>
                <TranslateWord
                  key={"VOLUNTEER"}
                  text={"VOLUNTEER"}
                  lang={selectedInterfaceLanguage}
                  headingStyle={false}
                />
              </div>
              <div>
              <Tooltip title="Coming soon" color="gray">
                <Button style={{ backgroundColor: "#CC0001", color: "white" }}>
                  <TranslateWord key={"JOIN"} text={"JOIN"} lang={selectedInterfaceLanguage} headingStyle={false} />
                </Button>
                </Tooltip>
              </div>
            </div>

            {/* <div style={{margin:'5vh'}}>
            Volunteers <Button danger
              onClick={handleJoinVolunteer} style={{backgroundColor:'red',color:'#FFFFFF'}}>Join</Button>
          </div> */}
          </Col>
        </Row>
        <div style={{ minHeight: "500px" }}>
          <Row justify="space-evenly" style={{ marginBottom: "40px" }}>
            <Col xs={24} sm={24} md={14}>
              <span style={{ marginRight: "5px" }}>
                <Button
                  onClick={() => handleDashboardData("About your self")}
                  style={{
                    position: "relative",
                    top: "20px",
                    cursor: "pointer",
                    color: type === "About your self" ? "white" : "#616161",
                    backgroundColor: type === "About your self" ? "#01579B" : "white",
                    border: type === "About your self" ? "1px solid white" : "1px solid #616161",
                    borderRadius: "5px",
                  }}
                  ghost>
                  {/* { selectedInterfaceLanguage && (
              <TranslateWord
                key={"STORY_ID"}
                text={"STORY_ID"}
                lang={selectedInterfaceLanguage}
              />
            )
          } */}

                  <TranslateWord
                    key={"ABOUT_YOUR-SELF"}
                    text={"ABOUT_YOUR-SELF"}
                    lang={selectedInterfaceLanguage}
                    headingStyle={false}
                  />
                </Button>
              </span>
              <span style={{ marginRight: "5px" }}>
                <Button
                  onClick={() => handleDashboardData("Submissions")}
                  style={{
                    position: "relative",
                    top: "20px",
                    cursor: "pointer",
                    color: type === "Submissions" ? "white" : "#616161",
                    backgroundColor: type === "Submissions" ? "#01579B" : "white",
                    border: type === "Submissions" ? "1px solid white" : "1px solid #616161",
                    borderRadius: "5px",
                  }}
                  ghost>
                  <TranslateWord
                    key={"SUBMISSIONS"}
                    text={"SUBMISSIONS"}
                    lang={selectedInterfaceLanguage}
                    headingStyle={false}
                  />
                </Button>
              </span>
              <span style={{ marginRight: "5px" }}>
                <Button
                  onClick={() => handleDashboardData("Wallet")}
                  style={{
                    position: "relative",
                    top: "20px",
                    cursor: "pointer",
                    color: type === "Wallet" ? "white" : "#616161",
                    backgroundColor: type === "Wallet" ? "#01579B" : "white",
                    border: type === "Wallet" ? "1px solid white" : "1px solid #616161",
                    borderRadius: "5px",
                  }}
                  ghost>
                  <TranslateWord key={"WALLET"} text={"WALLET"} lang={selectedInterfaceLanguage} headingStyle={false} />
                </Button>
              </span>
            </Col>
          </Row>
          <Divider />
          <Row justify="space-evenly">
            <Col xs={24} sm={24} md={20} style={{ position: "relative", top: "-26px" }}>
              {type === "About your self" && <ProfileAboutYourSelf />}
            </Col>
            <Col xs={24} sm={24} md={20} style={{ position: "relative", top: "-24px" }}>
              {type === "Submissions" && <SubmissionsTab />}
            </Col>
          </Row>
        </div>

        {settingsFlag && <SettingsModel openFlag={settingsFlag} handleCloseModel={handleModel} />}
      </div>
    </>
  );
};

export default ProfileDB;
