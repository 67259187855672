import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TranslateWord from "./sharedComponents/TranslateWord";
import apple from "../imgaes/apple.png";
import google from "../imgaes/google.png";
import { Col, Row } from "antd";
function Footer() {
  const {
    data: interfaceLanguagesData,
    state,
    selectedInterfaceLanguage,
  } = useSelector((state) => state.interfaceLanguageList);
  const handlePlayStore = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=cloud.politikos.mob_app_prod.twa&pcampaignid=web_share",
      "_blank"
    );
  };

  const handleIOSplaystore = () => {
    window.open("https://m.politikos.in/", "_blank");
  };
  return (
    <>
      <footer>
        <div className="footer-area-top">
          <div className="container">
            <h4 className="font-weight-bold mb-4">
              {selectedInterfaceLanguage && (
                <TranslateWord key={"LINKS"} text={"LINKS"} lang={selectedInterfaceLanguage} />
              )}
            </h4>

            <div className="row">
              <div className="col-lg-2 col-md-6 mb-5">
                <div className="d-flex flex-column justify-content-start">
                  <ul>
                    <li>
                      <Link to="/AboutUs" className="text-secondary mb-2">
                        {selectedInterfaceLanguage && (
                          <TranslateWord key={"ABOUTUS"} text={"ABOUTUS"} lang={selectedInterfaceLanguage} />
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link to="/Features" className="text-secondary mb-2">
                        {selectedInterfaceLanguage && (
                          <TranslateWord key={"FEATURES"} text={"FEATURES"} lang={selectedInterfaceLanguage} />
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link to="/Partnerwithus" className="text-secondary">
                        {selectedInterfaceLanguage && (
                          <TranslateWord
                            key={"PARTNERWITHUS"}
                            text={"PARTNERWITHUS"}
                            lang={selectedInterfaceLanguage}
                          />
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link to="/CallforPilots" className="text-secondary mb-2">
                        {selectedInterfaceLanguage && (
                          <TranslateWord
                            key={"CALLFORPILOTS"}
                            text={"CALLFORPILOTS"}
                            lang={selectedInterfaceLanguage}
                          />
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link to="/AdvertiseWithUs" className="text-secondary mb-2" href="/">
                        {selectedInterfaceLanguage && (
                          <TranslateWord
                            key={"ADVERTISEWITHUS"}
                            text={"ADVERTISEWITHUS"}
                            lang={selectedInterfaceLanguage}
                          />
                        )}
                      </Link>
                    </li>
                    {/* <li><Link to='/Products' className="text-secondary mb-2">Products</Link></li>
                              <li><Link to='/Services' className="text-secondary mb-2" >Services</Link></li> 
                              <li><Link to='/Programs' className="text-secondary">Programs</Link></li>*/}
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 mb-5">
                <div className="d-flex flex-column justify-content-start">
                  <ul>
                    <li>
                      <Link to="/Policies" className="text-secondary mb-2">
                        {selectedInterfaceLanguage && (
                          <TranslateWord key={"POLICIES"} text={"POLICIES"} lang={selectedInterfaceLanguage} />
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link to="/Disclaimer" className="text-secondary">
                        {selectedInterfaceLanguage && (
                          <TranslateWord key={"DISCLAIMER"} text={"DISCLAIMER"} lang={selectedInterfaceLanguage} />
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link to="/SIIMW" className="text-secondary mb-2">
                        {selectedInterfaceLanguage && (
                          <TranslateWord key={"SIIMW"} text={"SIIMW"} lang={selectedInterfaceLanguage} />
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link to="/BookTalent" className="text-secondary mb-2">
                        {selectedInterfaceLanguage && (
                          <TranslateWord key={"BOOKTALENT"} text={"BOOKTALENT"} lang={selectedInterfaceLanguage} />
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link to="/Career" className="text-secondary" href="/">
                        {selectedInterfaceLanguage && (
                          <TranslateWord key={"CAREERS"} text={"CAREERS"} lang={selectedInterfaceLanguage} />
                        )}
                      </Link>
                    </li>
                    {/* <li><Link to='/' className="text-secondary mb-2">Our Editorial Charter</Link></li> */}
                    {/* <li><Link to='OurBrands' className="text-secondary">Our Brands</Link></li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 mb-5">
                <div className="d-flex flex-column justify-content-start">
                  <ul>
                    <li>
                      <Link to="/PeopleCenter" className="text-secondary mb-2" href="/">
                        {selectedInterfaceLanguage && (
                          <TranslateWord key={"PEOPLECENTER"} text={"PEOPLECENTER"} lang={selectedInterfaceLanguage} />
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link to="/PolitikosVolunteers" className="text-secondary mb-2" href="/">
                        {selectedInterfaceLanguage && (
                          <TranslateWord
                            key={"POLITIKOSVOLUNTEERS"}
                            text={"POLITIKOSVOLUNTEERS"}
                            lang={selectedInterfaceLanguage}
                          />
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link to="/PAffiliates" className="text-secondary mb-2" href="/">
                        {selectedInterfaceLanguage && (
                          <TranslateWord
                            key={"POLITIKOSAFFILIATES"}
                            text={"POLITIKOSAFFILIATES"}
                            lang={selectedInterfaceLanguage}
                          />
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link to="/Classifieds" className="text-secondary mb-2" href="/">
                        {selectedInterfaceLanguage && (
                          <TranslateWord key={"CLASSIFIEDS"} text={"CLASSIFIEDS"} lang={selectedInterfaceLanguage} />
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link to="/SubmitCorrections" className="text-secondary" href="/">
                        {selectedInterfaceLanguage && (
                          <TranslateWord
                            key={"SUBMITCORRECTION"}
                            text={"SUBMITCORRECTION"}
                            lang={selectedInterfaceLanguage}
                          />
                        )}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
             
              <div className="col-lg-3 col-md-6 mb-5" style={{ position: "relative" }}>
                <h4 className="font-weight-bold mb-4">
                  {selectedInterfaceLanguage && (
                    <TranslateWord key={"CONTACTUS"} text={"CONTACTUS"} lang={selectedInterfaceLanguage} />
                  )}
                 <div style={{textDecoration:'underline'}}>
                 Head Office:
                 </div>
                </h4>

                <p>
                  {" "}
                  Plot No.42 (D No. 8-2-269/S/42) Sagar Society, Rood No. 2, Banjara Hills, Hyderabad, Telangana, PIN –
                  500034
                </p>
                <p style={{ marginBottom: "-0.1em" }}>
                  <strong>Email : </strong> contact@politikos.in
                </p>
                <p style={{ marginBottom: "-0.1em" }}>
                  <strong>Phone : </strong> +91 83414 40453
                </p>
              
              </div>
              <div className="col-lg-3 col-md-6 mb-5" style={{ position: "relative" }}>
                <div style={{height:'30px'}}></div>
                <h4 className="font-weight-bold mb-4">
                  {/* {selectedInterfaceLanguage && (
                    <TranslateWord key={"CONTACTUS"} text={"CONTACTUS"} lang={selectedInterfaceLanguage} />
                  )} */}
                  <div  style={{textDecoration:'underline'}}>
                  Politikos - USA:
                  </div>
              
                </h4>

                <p>
                  {" "}
                  5 Independence Way, Ste # 300
                  Princeton, NJ, USA 08540
                </p>
                <p style={{ marginBottom: "-0.1em" }}>
                  <strong>Email : </strong> contact@politikos.in
                </p>  
                <h3 style={{ color: "#FFFFFF", marginTop: "10px" }}>Download On</h3>
                <Row gutter={5}>
                  <Col xs={12} sm={12} md={8} lg={12} xl={12} xxl={12}>
                    <img
                      src={apple}
                      alt="appleImg"
                      style={{ width: "124px", height: "40px", cursor: "pointer", marginRight: "10%" }}
                      onClick={handleIOSplaystore}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={8} lg={12} xl={12} xxl={12}>
                    <img
                      src={google}
                      alt="googleImg"
                      style={{ width: "124px", height: "40px", cursor: "pointer" }}
                      onClick={handlePlayStore}
                    />
                  </Col>
                </Row>            
              </div>
            </div>
          </div>
        </div>
        <div className="footer-area-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <p className="m-0">
                  <a href="/">Copyright &copy; 2022 Politikos. All rights reserved.</a>
                </p>
                <p>
                  <a href="">Privacy Policy | </a> <a href=""> Terms & Conditions</a>
                </p>
              </div>
              <div className="col-lg-6">
                <ul className="footer-social float-right">
                  <li>
                    <Link to="https://www.facebook.com/imwpolitikos/" title="facebook" target="_blank">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://twitter.com/imwpolitikos" title="twitter" target="_blank">
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.instagram.com/imwpolitikos/" title="instagram" target="_blank">
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.linkedin.com/company/imwpolitikos/" target="_blank" title="linkedin">
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.youtube.com/@PolitikosMedia" target="_blank" title="youtube">
                      <i className="fa fa-youtube-play" aria-hidden="true"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://in.pinterest.com/imwpolitikos/" target="_blank" title="pinterest">
                      <i className="fa fa-pinterest" aria-hidden="true"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.tumblr.com/imwpolitikos" target="_blank" title="tumblr">
                      <i className="fa fa-tumblr" aria-hidden="true"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://vimeo.com/user205451233" target="_blank" title="vimeo">
                      <i className="fa fa-vimeo" aria-hidden="true"></i>
                    </Link>
                  </li>
                  {/* <li>
                              <Link to="https://profile.snapchat.com/24dab553-112b-441d-82a7-3f2a53715653/profiles/9e9a271e-0a23-4013-b5cb-e6edaeba74cc/details/public-stories" target='_blank' title="snapchat">
                              <i className="fa fa-snapchat" aria-hidden="true"></i>
                              </Link>
                           </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
