import { Button, Col, Grid, Row, Modal, message, Avatar, Dropdown } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { UpOutlined, DownOutlined, HeartOutlined, HeartFilled } from "@ant-design/icons";
import { FaPlay, FaPause, FaVolumeHigh, FaVolumeXmark, FaRegBookmark, FaBan, FaBookmark } from "react-icons/fa6";
import { FaRegCommentDots } from "react-icons/fa";
import { IoIosShareAlt } from "react-icons/io";
import { IoEllipsisVerticalOutline, IoExitOutline, IoFlagOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import BaseUrl from "../utils/config";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterShareButton,
  XIcon,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { addBookmarklens, removeBookmarkLens } from "../Store/LensSlice";
import { shareStoryUrl } from "../utils/config";
import { getLensDetailsByID, getLenslistingData } from "../Store/LensSlice";
const { useBreakpoint } = Grid;
const LensDetails = (categoryName) => {
  let category = categoryName;
  const dispatch = useDispatch();
  const timerRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const audioRef = useRef(null);
  const screens = useBreakpoint();
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [swiperReady, setSwiperReady] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  let loggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserDetails"));
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedShareInstance, setSelectedShareInstance] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [currentShortIndex, setCurrentShortIndex] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(-1);
  const [like, setLike] = useState(false);
  const [unLike, setUnLike] = useState(false);
  const { userSelectedNewsFeedData } = useSelector((state) => state.profileSettingsList);
  const {
    data: allLensData,
    lensCategories: allLenscategories,
    selectedLensRecordDetails: selectedLensData,
    lensListingDetails: lensListingData,
    status,
    error,
    lensSelectedLang,
  } = useSelector((state) => state.lensList);
  let LensData = [];
  if (selectedLensData?.data) {
    if (Array.isArray(selectedLensData?.data)) {
      LensData = selectedLensData?.data;
    } else {
      LensData.push(selectedLensData?.data);
    }
  }
  const { data: feedLanguageData } = useSelector((state) => state.feedLanguageList);
  const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
    return feed?.name === lensSelectedLang?.name;
  });
  let feedLangPayload = [];
  if (loggedInUserData?.user_id) {
    feedLangPayload = userSelectedNewsFeedData?.filter((item) => item.selected)?.map((item) => item.newsfeed_lang_id);
  } else {
    feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
      return feedL?.newsfeed_lang_id;
    });
  }
  const recordImages = (LensData.length && LensData[currentShortIndex]?.images_details) || []; //[]
  const recordVideos = (LensData.length && LensData[currentShortIndex]?.video_details) || {}; //{}
  useEffect(() => {
    if (swiperReady) {
      const timer = setTimeout(() => {
        setIsTransitioning(true);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [activeSlideIndex, swiperReady]);
  const handleSlideChange = (swiper) => {
    setIsTransitioning(false);
    setActiveSlideIndex(swiper.activeIndex);
  };
  const urlParams = new URLSearchParams(window.location.search);
  const lensId = urlParams.get("param1");
  useEffect(() => {
    getSelectedLensData();
  }, [dispatch]);
  const getSelectedLensData = (selectedId) => {
    const payload = {
      login_user_id: loggedInUserData?.user_id,
      lens_id: selectedId ? selectedId : lensId,
    };
    dispatch(getLensDetailsByID(payload));
  };
  const handleShare = () => {
    setIsModalVisible(!isModalVisible);
  };
  const updateShareInstance = (record) => {
    setSelectedShareInstance(record);
    handleShare();
  };
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.load();
    }
  }, [LensData[currentShortIndex]?.audio_url]);
  const handleMuteToggle = () => {
    if (LensData[currentShortIndex]?.audio_url) {
      setIsMuted((prev) => !prev);
      if (audioRef.current) {
        audioRef.current.muted = !isMuted;
      }
    }
  };
  const videoRef = useRef(null);
  const handlePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  const handleMute = () => {
    setIsMuted(!isMuted);
    videoRef.current.muted = !isMuted;
  };
  const stopInteraction = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };
  const handleListingData = (pageNumber) => {
    const payload = {
      login_user_id: loggedInUserData?.user_id,
      news_feed_lang_ids: feedLangPayload,
      page: pageNumber,
      pageSize: 2,
    };
    dispatch(getLenslistingData(payload));
  };
  const handleUpArrowClick = () => {
    const previousIndex = currentShortIndex - 1;
    if (previousIndex >= 0) {
      setTimeout(() => {
        setCurrentShortIndex(previousIndex);
      }, 500);
    }
    if (previousIndex === -1) {
      setCurrentShortIndex(currentPageNumber === 0 ? 0 : 1);
      if (currentPageNumber === 0) {
        setCurrentPageNumber(0);
        handleListingData(0);
      } else {
        setCurrentPageNumber((prevPageNum) => prevPageNum - 1);
        handleListingData(currentPageNumber - 1);
      }
    }
  };

  const handleDownArrowClick = () => {
    const nextIndex = currentShortIndex + 1;

    if (nextIndex < LensData.length) {
      setTimeout(() => {
        setCurrentShortIndex(nextIndex);
      }, 500);
    }

    if (nextIndex >= LensData.length) {
      setCurrentShortIndex(0);
      setCurrentPageNumber((prevPageNum) => prevPageNum + 1);
      handleListingData(currentPageNumber + 1);
    }
  };

  const handleLike = (lens_id) => {
    const payload = {
      lens_id: LensData[currentShortIndex]?.lens_id,
      login_user_id: loggedInUserData?.user_id,
    };
    axios
      .post(`${BaseUrl}/lens/like/website`, payload)
      .then((response) => {
        if (response.status === 200) {
          setLike(!like);
          getSelectedLensData(payload.lens_id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUnLike = (lens_id) => {
    const payload = {
      lens_id: lens_id,
      login_user_id: loggedInUserData?.user_id,
    };
    axios
      .delete(`${BaseUrl}/lens/unlike/website`, { data: payload })
      .then((response) => {
        if (response.data?.is_success === true) {
          setLike(!like);
          getSelectedLensData(payload.lens_id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const handleLikeActions = (lens_id, isUpdate) => {
  //   if (isUpdate) {
  //     handleUnLike(lens_id);
  //   } else {
  //     handleLike(lens_id);
  //   }
  // };
  const handleBookMark = (id) => {
    const payload = {
      id: id,
      login_user_id: loggedInUserData?.user_id,
    };
    dispatch(addBookmarklens(payload)).then((action) => {
      if (addBookmarklens.fulfilled.match(action)) {
        if (action.payload.is_success) {
          getSelectedLensData();
          message.success({
            content: "Bookmarked successfully",
            duration: 5,
            style: {
              marginTop: "20px",
              marginBottom: "20px",
            },
          });
        }
      }
      if (!loggedInUserData?.user_id) {
        message.warning({
          content: "Please Login",
          duration: 5,
          style: {
            marginTop: "20px",
            marginBottom: "20px",
          },
        });
        return;
      }
    });
  };
  const removeBookmark = async (bookmarked_id) => {
    const login_user_id = loggedInUserData?.user_id;
    const lens_id = bookmarked_id;
    dispatch(removeBookmarkLens({ userId: login_user_id, lens_id: bookmarked_id })).then((action) => {
      if (removeBookmarkLens.fulfilled.match(action)) {
        if (action.payload.is_success) {
          getSelectedLensData();
          message.success({
            content: "Removed Bookmark successfully",
            duration: 5,
            style: {
              marginTop: "20px",
              marginBottom: "20px",
            },
          });
        }
      }
    });
  };
  const handleBookmarkActions = (lens_id, isUpdate) => {
    if (isUpdate) {
      removeBookmark(lens_id);
    } else {
      handleBookMark(lens_id);
    }
  };
  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={() =>
            handleBookmarkActions(LensData[currentShortIndex]?.lens_id, LensData[currentShortIndex]?.is_bookmarked)
          }>
          {LensData[currentShortIndex]?.is_bookmarked ? (
            <>
              <FaBookmark style={{ color: "red" }} />
              <span style={{ marginLeft: screens.xs ? "5px" : "15px" }}>Unbookmark</span>
            </>
          ) : (
            <>
              <FaRegBookmark />
              <span style={{ marginLeft: screens.xs ? "5px" : "15px" }}>Bookmark</span>
            </>
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div>
          <IoExitOutline />
          <span style={{ marginLeft: screens.xs ? "5px" : "15px" }}>Goto Source</span>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div>
          <FaBan />
          <span style={{ marginLeft: screens.xs ? "5px" : "15px" }}>Block</span>
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div>
          <IoFlagOutline />
          <span style={{ marginLeft: screens.xs ? "5px" : "15px" }}>Report</span>
        </div>
      ),
    },
  ];
  useEffect(() => {
    if (Array.isArray(selectedLensData?.data)) {
      LensData = selectedLensData?.data;
    } else {
      LensData.push(selectedLensData?.data);
    }
  }, [like]);
  return (
    <>
      <Row
        className="mb-8"
        justify="center"
        align="middle"
        style={{
          height: screens.xs ? "55%" : "100%",
          background: "linear-gradient(to bottom, #dce8f2, #d7d2b8)",
          objectFit: screens.xs ? "cover" : "",
        }}>
        <Col span={screens.xs ? 20 : 16} md={16} lg={9} xl={9} xxl={9} style={{ textAlign: "center" }}>
          <div>
            {LensData[currentShortIndex]?.is_video ? (
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  maxWidth: "calc(100vh * 9 / 16)",
                  height: "calc(100vh - 56px)",
                  background: "#000",
                  overflow: "hidden",
                  margin: "0 auto",
                }}>
                <video
                  ref={videoRef}
                  src={recordVideos?.file_url}
                  controls={false}
                  loop
                  muted={isMuted}
                  playsInline
                  preload="auto"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: "0px",
                    left: "10px",
                    right: "10px",
                    color: "white",
                    background:
                      "linear-gradient(rgba(255, 255, 255, 0) 9.52%, rgba(0, 0, 0, 0.75) 44.7%, rgb(0, 0, 0) 100%)",
                    padding: "10px",
                  }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Avatar src={LensData[currentShortIndex]?.user_profile_url} height={25} width={25} />
                    <h3 style={{ color: "white", paddingTop: "5px", marginLeft: "10px" }}>
                      {LensData[currentShortIndex]?.submitterName}
                    </h3>
                  </div>
                  <div>
                    <Button
                      onClick={handlePlay}
                      shape="circle"
                      icon={isPlaying ? <FaPause /> : <FaPlay />}
                      style={{
                        position: "absolute",
                        top: "20%",
                        left: "78%",
                        transform: "translate(-50%, -50%)",
                        padding: "10px 20px",
                        backgroundColor: "transparent",
                        color: "white",
                        fontSize: "20px",
                        fontWeight: "900",
                        border: "none",
                        cursor: "pointer",
                      }}
                    />
                    <Button
                      onClick={handleMute}
                      shape="circle"
                      icon={isMuted ? <FaVolumeXmark /> : <FaVolumeHigh />}
                      style={{
                        position: "absolute",
                        top: "6%",
                        right: "10%",
                        padding: "10px",
                        backgroundColor: "transparent",
                        color: "white",
                        fontSize: "20px",
                        fontWeight: "900",
                        border: "none",
                        cursor: "pointer",
                      }}
                    />
                  </div>

                  <h3
                    style={{
                      margin: 0,
                      fontSize: lensSelectedLang?.name === "English" ? "16px" : "18px",
                      color: "white",
                      fontFamily: lensSelectedLang?.name === "English" ? "Poppins" : "Mallanna",
                    }}>
                    {recordVideos?.title || "Default Title"}
                  </h3>
                  <p
                    style={{
                      margin: 0,
                      fontSize: "12px",
                      fontWeight: "300",
                      fontFamily: lensSelectedLang?.name === "English" ? "Poppins" : "Mallanna",
                    }}>
                    {recordVideos?.description || "This is a default description."}
                  </p>
                </div>
              </div>
            ) : (
              <Swiper
                spaceBetween={10}
                slidesPerView={1}
                modules={[Navigation, Pagination, Autoplay]}
                // navigation={screens.xs ? false : true} //for arrow buttons
                // pagination={{ clickable: true }} // for dots
                autoplay={{
                  delay: 5450,
                  disableOnInteraction: false,
                }}
                speed={1000}
                // className="webStoriesSwiper"  // for pagination style
                onSlideChange={handleSlideChange}
                onInit={() => setSwiperReady(true)}
                style={{ width: "100%", height: "100%", position: "relative" }}>
                {recordImages?.map((slide, storyIndex) => (
                  <SwiperSlide
                    key={storyIndex}
                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ alignItems: "center" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "auto",
                          position: "relative",
                          overflow: "hidden",
                        }}>
                        <img
                          src={slide?.url}
                          alt={`Slide ${slide.file_id}`}
                          style={{
                            maxHeight: "90vh",
                            width: "100%",
                            borderRadius: "10px",
                            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                          }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            bottom: "20px",
                            left: "20px",
                            right: "20px",
                            color: "white",
                            padding: "10px",
                          }}>
                          <h3 style={{ margin: 0, fontSize: "26px", color: "white" }}>{slide?.title}</h3>
                          <p style={{ margin: 0, fontSize: "12px", fontWeight: "300" }}>{slide?.description}</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </Col>
        <Col span={2} md={2} lg={2} xl={2} xxl={2}>
          <div>
            <div style={{ paddingBottom: "30%", cursor: "pointer" }}>
              {LensData[currentShortIndex]?.is_user_like ? (
                <HeartFilled
                  style={{ fontSize: "25px", color: "red" }}
                  onClick={() => {
                    handleUnLike(LensData[currentShortIndex]?.lens_id);
                  }}
                />
              ) : (
                <HeartOutlined
                  style={{ fontSize: "25px" }}
                  onClick={() => {
                    handleLike(LensData[currentShortIndex]?.lens_id);
                  }}
                />
              )}
            </div>
            <div style={{ paddingBottom: "30%", cursor: "pointer" }}>
              <FaRegCommentDots size={25} />
            </div>
            <div style={{ paddingBottom: "30%", cursor: "pointer" }}>
              <IoIosShareAlt size={25} onClick={() => updateShareInstance(selectedLensData?.data)} />
            </div>
            <div style={{ paddingBottom: "30%", cursor: "pointer" }}>
              <WhatsappShareButton url={`${shareStoryUrl}/about?param1=${selectedShareInstance?.lens_id}`}>
                <WhatsappIcon size={25} round />
              </WhatsappShareButton>
            </div>

            <div style={{ paddingBottom: "30%", cursor: "pointer" }}>
              <Dropdown
                menu={{
                  items,
                }}
                placement={screens.xs ? "right" : "Left"}>
                <IoEllipsisVerticalOutline size={25} />
              </Dropdown>
            </div>

            <div>
              {LensData[currentShortIndex]?.audio_url && (
                <audio ref={audioRef} src={LensData[currentShortIndex]?.audio_url} autoPlay loop muted={isMuted} />
              )}
              <Button
                style={{ backgroundColor: "transparent", borderWidth: "0px", marginLeft: "-10px" }}
                onClick={handleMuteToggle}>
                {LensData[currentShortIndex]?.audio_url ? (
                  isMuted ? (
                    <FaVolumeXmark
                      style={{
                        fontSize: "25px",
                      }}
                    />
                  ) : (
                    <FaVolumeHigh
                      style={{
                        fontSize: "25px",
                      }}
                    />
                  )
                ) : (
                  <FaVolumeHigh
                    style={{
                      fontSize: "25px",
                    }}
                  />
                )}
              </Button>
            </div>
          </div>
        </Col>

        <Col span={2} md={2} lg={2} xl={2} xxl={2}>
          <div style={{ height: "100px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Button onClick={handleUpArrowClick} disabled={currentPageNumber === 0 && currentShortIndex === 0}>
              <UpOutlined />
            </Button>
            <Button onClick={handleDownArrowClick}>
              <DownOutlined />
            </Button>
          </div>
        </Col>
      </Row>
      <Modal className="share_more_modal" title="Share More" open={isModalVisible} onCancel={handleShare}>
        <div>
          <Row gutter={16}>
            <Col span={12} style={{ marginBottom: "10px" }}>
              <FacebookShareButton url={`${shareStoryUrl}/about?param1=${selectedShareInstance?.lens_id}`}>
                <FacebookIcon size={25} round /> Facebook
              </FacebookShareButton>
            </Col>
            <Col span={12} style={{ marginBottom: "10px" }}>
              <TwitterShareButton
                url={`${shareStoryUrl}/about?param1=${selectedShareInstance?.lens_id}`}
                title={
                  selectedShareInstance?.images_details && selectedShareInstance?.images_details.length > 0
                    ? selectedShareInstance?.images_details[0].title
                    : ""
                }>
                <XIcon size={25} round />
                &nbsp;Twitter
              </TwitterShareButton>
            </Col>
            <Col span={12} style={{ marginBottom: "10px" }}>
              <TelegramShareButton
                url={`${shareStoryUrl}/about?param1=${selectedShareInstance?.lens_id}`}
                title={
                  selectedShareInstance?.images_details && selectedShareInstance?.images_details.length > 0
                    ? selectedShareInstance?.images_details[0].title
                    : ""
                }>
                <TelegramIcon size={25} round />
                &nbsp;Telegram
              </TelegramShareButton>
            </Col>
            <Col span={12} style={{ marginBottom: "10px" }}>
              <LinkedinShareButton url={`${shareStoryUrl}/about?param1=${selectedShareInstance?.lens_id}`}>
                <LinkedinIcon size={25} round /> Linkedin
              </LinkedinShareButton>
            </Col>
            <Col span={12}>
              <TumblrShareButton url={`${shareStoryUrl}/about?param1=${selectedShareInstance?.id}`}>
                <TumblrIcon size={25} round /> Tumblr
              </TumblrShareButton>
            </Col>
            <Col span={12}>
              <WhatsappShareButton url={`${shareStoryUrl}/about?param1=${selectedShareInstance?.lens_id}`}>
                <WhatsappIcon size={25} round /> Whatsapp
              </WhatsappShareButton>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default LensDetails;
