import React, { Suspense, lazy } from "react";

import { Link } from "react-router-dom";
import { Col, Grid, Row, Skeleton } from "antd";
import Trending from "../Trending";
import BreakingNewsMain from "../Sections/Breaking News/BreakingNewsMain";
import PoliticsSectionDB from "../Sections/PoliticsSection/PoliticsSectionDB";
import TranslateWord from "../sharedComponents/TranslateWord";
import { useSelector } from "react-redux";
import EntertainmentSectionDB from "../Sections/EntertainmentSection/EntertainmentSectionDB";
import SportsSectionDB from "../Sections/SportsSection/SportsSectionDB";
import BusinessSectionDB from "../Sections/BusinessSection/BusinessSectionDB";
import TechnologySectionDB from "../Sections/TechnologySection/TechnologySectionDB";
import LivetvSection from "../Sections/LivetvSection/LivetvSection";
import AdvtHereImg from "../../imgaes/AdvtHereImg.png";
import adv1 from "../../imgaes/Advs/adv1.svg";
import adv2 from "../../imgaes/Advs/adv2.svg";
import adv3 from "../../imgaes/Advs/adv3.svg";
import adv4 from "../../imgaes/Advs/adv4.svg";
import adv5 from "../../imgaes/Advs/adv5.svg";
import adv6 from "../../imgaes/Advs/adv6.svg";
import adv7 from "../../imgaes/Advs/adv7.svg";
import adv8 from "../../imgaes/Advs/adv8.svg";
import adv9 from "../../imgaes/Advs/adv9.svg";
import AdComponent from "../Adsens/AdComponent";
import LivetvSectionDB from "../Sections/LivetvSection/LivetvSectionDB";
import VideosSectionDB from "../Sections/VideosSection/VideosSectionDB";
import WebstoriesSectionDB from "../Sections/WebstoriesSection/WebstoriesSectionDB";
import ChannelsSectionDB from "../Sections/ChannelsSection/ChannelsSectionDB";
import LensSectionDB from "../Sections/LensSection/LensSectionDB";
const LazyTrending = lazy(() => import("../Trending"));

const { useBreakpoint } = Grid;
const CategoriesSectionDB = () => {
  const screens = useBreakpoint();
  const { data: interfaceLanguagesData, selectedInterfaceLanguage } = useSelector(
    (state) => state.interfaceLanguageList
  );
  const { politicsSelectedLang } = useSelector((state) => state.politicsList);
  const { entertainmentSelectedLang } = useSelector((state) => state.entertainmentList);
  const { sportsSelectedLang } = useSelector((state) => state.sportsList);
  const { businessSelectedLang } = useSelector((state) => state.businessList);
  const { technologySelectedLang } = useSelector((state) => state.technologyList);
  const { livetvSelectedLang } = useSelector((state) => state.livetvList);
  const { videosSelectedLang } = useSelector((state) => state.videosList);
  const { channelsSelectedLang } = useSelector((state) => state.channelsList);
  const { webstoriesSelectedLang } = useSelector((state) => state.webstoriesList);
  const { lensSelectedLang } = useSelector((state) => state.lensList);
  return (
    <>
      <div className="container">
        <Trending />
        <BreakingNewsMain />
      </div>

      <div className="container">
        <hr></hr>
      </div>
      <div className="container">
        <Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0 }}>
          <Col
            xs={24}
            sm={24}
            md={18}
            lg={18}
            xl={18}
            xxl={18}
            style={{
              margin: screens.xs ? "20px 10px" : "none",
              borderRight: !screens.xs ? "2px solid lightgray" : "none",
              borderBottom: "2px solid lightgray",
              paddingLeft: "0px",
            }}>
            <PoliticsSectionDB
              sectionType={
                <TranslateWord key={"POLITICS"} text={"POLITICS"} headingStyle={true} lang={politicsSelectedLang} />
              }
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6} className="responsive-border">
            <div className="sidebar-box">
              <AdComponent adClient="ca-pub-7003709647525511" adSlot="6745023357" />
            </div>
          </Col>
        </Row>
      </div>

      <div className="container">
        <Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0 }}>
          <Col
            xs={24}
            sm={24}
            md={18}
            lg={18}
            xl={18}
            xxl={18}
            style={{
              margin: screens.xs ? "20px 10px" : "none",
              borderRight: !screens.xs ? "2px solid lightgray" : "none",
              borderBottom: "2px solid lightgray",
              paddingLeft: "0px",
              paddingTop: "10px",
            }}>
            <EntertainmentSectionDB
              sectionType={
                <TranslateWord
                  key={"ENTERTAINMENT"}
                  text={"ENTERTAINMENT"}
                  headingStyle={true}
                  lang={entertainmentSelectedLang}
                />
              }
            />
          </Col>

          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6} className="responsive-border">
            <div className="sidebar-box">
              <AdComponent adClient="ca-pub-7003709647525511" adSlot="6745023357" />
            </div>
          </Col>
        </Row>
      </div>

      <div className="container">
        <Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0 }}>
          <Col
            xs={24}
            sm={24}
            md={18}
            lg={18}
            xl={18}
            xxl={18}
            style={{
              margin: screens.xs ? "20px 10px" : "none",
              borderRight: !screens.xs ? "2px solid lightgray" : "none",
              borderBottom: "2px solid lightgray",
              paddingLeft: "0px",
              paddingTop: "10px",
            }}>
            <SportsSectionDB
              sectionType={
                <TranslateWord key={"SPORTS"} text={"SPORTS"} headingStyle={true} lang={sportsSelectedLang} />
              }
            />
          </Col>

          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6} className="responsive-border">
            <div className="sidebar-box">
              <AdComponent adClient="ca-pub-7003709647525511" adSlot="6745023357" />
            </div>
          </Col>
        </Row>
      </div>

      <div className="container">
        <Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0 }}>
          <Col
            xs={24}
            sm={24}
            md={18}
            lg={18}
            xl={18}
            xxl={18}
            style={{
              margin: screens.xs ? "20px 10px" : "none",
              borderRight: !screens.xs ? "2px solid lightgray" : "none",
              borderBottom: "2px solid lightgray",
              paddingLeft: "0px",
              paddingTop: "10px",
            }}>
            <BusinessSectionDB
              sectionType={
                <TranslateWord key={"BUSINESS"} text={"BUSINESS"} headingStyle={true} lang={businessSelectedLang} />
              }
            />
          </Col>

          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6} className="responsive-border">
            <div className="sidebar-box">
              <AdComponent adClient="ca-pub-7003709647525511" adSlot="6745023357" />
            </div>
          </Col>
        </Row>
      </div>

      <div className="container">
        <Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0 }}>
          <Col
            xs={24}
            sm={24}
            md={18}
            lg={18}
            xl={18}
            xxl={18}
            style={{
              margin: screens.xs ? "20px 10px" : "none",
              borderRight: !screens.xs ? "2px solid lightgray" : "none",
              paddingLeft: "0px",
              paddingTop: "10px",
            }}>
            <TechnologySectionDB
              sectionType={
                <TranslateWord
                  key={"TECHNOLOGY"}
                  text={"TECHNOLOGY"}
                  headingStyle={true}
                  lang={technologySelectedLang}
                />
              }
            />
          </Col>

          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6} className="responsive-border">
            <div className="sidebar-box">
              <AdComponent adClient="ca-pub-7003709647525511" adSlot="6745023357" />
            </div>
          </Col>
        </Row>
      </div>

      <div className="container" style={{ borderTop: "2px solid lightgray", padding: "10px 0px",marginBottom: screens?.xs ?'-35px' :'50px' }}>
        <Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ paddingLeft: "0px" }}>
            <LivetvSectionDB
              sectionType={
                <TranslateWord key={"LIVETV"} text={"LIVETV"} headingStyle={true} lang={livetvSelectedLang} />
              }
            />
          </Col>
        </Row>
      </div>

      <div
        className="container"
        style={{
          borderBottom: "2px solid lightgray",
          borderTop: "2px solid lightgray",
          padding: "10px 0px",
          marginTop: "20px",
        }}>
        <Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ paddingLeft: "0px" }}>
            <ChannelsSectionDB
              sectionType={
                <TranslateWord key={"CHANNELS"} text={"CHANNELS"} headingStyle={true} lang={channelsSelectedLang} />
              }
            />
          </Col>
        </Row>
      </div>
      <div className="container" style={{ borderBottom: "2px solid lightgray", padding: "10px 0px" }}>
        <Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ paddingLeft: "0px" }}>
            <VideosSectionDB
              sectionType={
                <TranslateWord key={"VIDEOS"} text={"VIDEOS"} headingStyle={true} lang={videosSelectedLang} />
              }
            />
          </Col>
        </Row>
      </div>
      <div className="container" style={{ borderBottom: "2px solid lightgray", padding: "10px 0px", top: "-30px" }}>
        <Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0, paddingTop: "50px" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <WebstoriesSectionDB
              sectionType={
                <TranslateWord
                  key={"WEB STORIES"}
                  text={"WEB STORIES"}
                  headingStyle={true}
                  lang={webstoriesSelectedLang}
                />
              }
            />
          </Col>
        </Row>
      </div>
      <div className="container" style={{ padding: "10px 0px", top: "-30px" }}>
        <Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0, paddingTop: "0px" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <LensSectionDB
              sectionType={<TranslateWord key={"LENS"} text={"LENS"} headingStyle={true} lang={lensSelectedLang} />}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CategoriesSectionDB;
