import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Space, Tabs, Upload, message, Grid } from "antd";
import React, { useEffect, useRef, useState } from "react";
import userProfilePic from "../../imgaes/userProfilePic.svg";
import uploadProfileImg from "../../imgaes/uploadProfileImg.svg";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { PlusOutlined, MinusCircleOutlined, CheckCircleTwoTone } from "@ant-design/icons";
import OTP from "../Login/OTP/OTP";
import { getOTPData, sendOTPtoMail } from "../../Store/LoginSlice";
import {
  getUserProfileData,
  updateProfileUserPicture,
  updateProfileUserSettings,
} from "../../Store/ProfileSettingsSlice";
import BaseUrl from "../../utils/config";
import deleteIcon from "../../imgaes/deleteIcon.png";
import axios from "axios";
import TranslateWord from "../sharedComponents/TranslateWord";
import { HiOutlineCamera } from "react-icons/hi2";
import map from "../../imgaes/map.png";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const { useBreakpoint } = Grid;
const ProfileAboutYourSelf = () => {
  const { TextArea } = Input;
  const { TabPane } = Tabs;
  const { Option } = Select;
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const [profileAboutYourSelf] = Form.useForm();
  const [addressFrom] = Form.useForm();
  const dateFormat = "DD/MM/YYYY";
  const [fileList, setFileList] = useState([]);
  const [fModalVisible, setFModalVisible] = useState(false);
  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [emptyFieldIndexArr, setEmptyFieldIndexArr] = useState([]);
  const inputRefs = useRef([]);
  const mypancardRef = useRef(null);
  const [disableAddBtn, setDisableAddBtn] = useState(false);
  const [currentFormsList, setCurrentFormsList] = useState(null);
  const [removingItem, setRemovingItem] = useState(null);
  const [removedList, setRemovedList] = useState([]);
  const [subLevelOldData, setSubLevelOldData] = useState(null);
  const [dupIndex, setDupIndex] = useState([]);
  const [fileListForAddhar, setFileListForAddhar] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [dobDate, setDobDate] = useState(null);
  const [fileListForPANcard, setFileListForPANcard] = useState([]);
  //   const [isModalVisible, setIsModalVisible] = useState(false);
  let curentListValues = [];
  const { data: genderData } = useSelector((state) => state.genderList);
  const { data: maritalStatusData } = useSelector((state) => state.maritalStatusList);
  const { selectedInterfaceLanguage } = useSelector((state) => state.interfaceLanguageList);
  const { userProfileData, workExperienceData, educationQualificationData } = useSelector(
    (state) => state.profileSettingsList
  );
  useEffect(() => {
    var dob = new Date(moment(userProfileData?.user_profile?.dateOfBirth));
    //calculate month difference from current date in time
    var month_diff = Date.now() - dob.getTime();

    //convert the calculated difference in date format
    var age_dt = new Date(month_diff);

    //extract year from date
    var year = age_dt.getUTCFullYear();

    //now calculate the age of the user
    var ageDiff = Math.abs(year - 1970);
    if (userProfileData?.profile_url) {
      fileList.splice(0, fileList.length);
      // to view image file
      const profImageObj = {
        uid: "-1",
        name: `Profile picture`,
        status: "done",
        url: userProfileData?.profile_url ? userProfileData?.profile_url : null,
        response: '{"status": "success"}', // response from server
        linkProps: '{"download": "image"}', // additional html props of file link
        xhr: "{}",
      };
      fileList.push(profImageObj);
    } else {
      setFileList([]);
    }
    if (userProfileData?.user_profile?.aadhar_image?.image_url) {
      fileListForAddhar.splice(0, fileListForAddhar.length);
      // to view image file
      const addharImageObj = {
        uid: "-1",
        // name: record?.empName,
        name: `Aadhar card`,
        status: "done",
        url: userProfileData?.user_profile?.aadhar_image?.image_url
          ? userProfileData?.user_profile?.aadhar_image?.image_url
          : null,
      };
      fileListForAddhar.push(addharImageObj);
    } else {
      setFileListForAddhar([]);
    }
    if (userProfileData?.user_profile?.pan_image?.image_url) {
      fileListForPANcard.splice(0, fileListForPANcard.length);
      // to view image file
      const panImageObj = {
        uid: "-1",
        // name: record?.empName,
        name: `Pan card`,
        status: "done",
        url: userProfileData?.user_profile?.pan_image?.image_url
          ? userProfileData?.user_profile?.pan_image?.image_url
          : null,
      };
      fileListForPANcard.push(panImageObj);
    } else {
      setFileListForPANcard([]);
    }
    setDobDate(moment(userProfileData?.user_profile?.dateOfBirth));
    profileAboutYourSelf.setFieldsValue({
      firstname: userProfileData?.user_profile?.firstname,
      lastname: userProfileData?.user_profile?.lastname,
      display_name: userProfileData?.user_profile?.display_name,
      mobile_number: userProfileData?.user_profile?.mobile_number,
      gender: userProfileData?.user_profile?.gender,
      marital_status: userProfileData?.user_profile?.marital_status,
      dateOfBirth: moment(userProfileData?.user_profile?.dateOfBirth),
      gmail_id: userProfileData?.gmail_id,
      age: ageDiff,
      location: userProfileData?.user_profile?.location,
      state: userProfileData?.user_profile?.state,
      mandal: userProfileData?.user_profile?.mandal,
      district: userProfileData?.user_profile?.district,
      village: userProfileData?.user_profile?.village,
      breif_about_self: userProfileData?.user_profile?.breif_about_self,
      educational_qualification_id: userProfileData?.user_profile?.educational_qualification_id,
      work_experience_id: userProfileData?.user_profile?.work_experience_id,
      aadhar: userProfileData?.user_profile?.aadhar?.replace(/-/g, ""),

      pancard: userProfileData?.user_profile?.pancard,

      bank_account_number: userProfileData?.user_profile?.bank_account_number,
      bank_address: userProfileData?.user_profile?.bank_address,
      bank_ifsc_code: userProfileData?.user_profile?.bank_ifsc_code,
      bank_name: userProfileData?.user_profile?.bank_name,
      swift_code: userProfileData?.user_profile?.swift_code,
    });
  }, [userProfileData]);

  const APICall = (payload) => {
    dispatch(updateProfileUserSettings(payload)).then((action) => {
      if (updateProfileUserSettings.fulfilled.match(action)) {
        if (action?.payload?.is_success) {
          dispatch(getUserProfileData(userProfileData?.user_id))
            .then((action) => {
              if (getUserProfileData.fulfilled.match(action)) {
                message.success({
                  content: "Updated successfully",
                  duration: 5,
                  style: {
                    marginTop: "20px",
                    marginBottom: "20px",
                  },
                });
              }
            })
            .catch((err) => {
              message.error({
                content: "Some thing went wrong",
                duration: 5,
                style: {
                  marginTop: "20px",
                  marginBottom: "20px",
                },
              });
            });
        } else {
          message.error({
            content: action?.payload?.error,
            duration: 5,
            style: {
              marginTop: "20px",
              marginBottom: "20px",
            },
          });
        }
      }
    });
  };
  const submitProfileAboutYourSelfForm = (values) => {
    let user_id = userProfileData?.user_id;
    const payload = {
      user_id: userProfileData?.user_id,
      firstname: values?.firstname,
      lastname: values?.lastname,
      display_name: values?.display_name,
      mobile_number: values?.mobile_number,
      gender: values?.gender,
      marital_status: values?.marital_status,
      date_of_birth: dobDate?.format(),
      gmail_id: values?.gmail_id,
      location: userProfileData?.user_profile?.location,
      state: userProfileData?.user_profile?.state,
      mandal: userProfileData?.user_profile?.mandal,
      district: userProfileData?.user_profile?.district,
      village: userProfileData?.user_profile?.village,
      breif_about_self: userProfileData?.user_profile?.breif_about_self,
      educational_qualification_id: userProfileData?.user_profile?.educational_qualification_id,
      work_experience_id: userProfileData?.user_profile?.work_experience_id,
      aadhar_number: userProfileData?.user_profile?.aadhar?.replace(/-/g, ""),
      pancard_number: userProfileData?.user_profile?.pancard,
      bank_account_number: userProfileData?.user_profile?.bank_account_number,
      bank_address: userProfileData?.user_profile?.bank_address,
      bank_ifsc_code: userProfileData?.user_profile?.bank_ifsc_code,
      bank_name: userProfileData?.user_profile?.bank_name,
      swift_code: userProfileData?.user_profile?.swift_code,
    };
    APICall(payload);
  };
  const submitAddressSelfForm = (values) => {
    const payload = {
      user_id: userProfileData?.user_id,
      firstname: userProfileData?.user_profile?.firstname,
      lastname: userProfileData?.user_profile?.lastname,
      display_name: userProfileData?.user_profile?.display_name,
      mobile_number: userProfileData?.user_profile?.mobile_number,
      gender: userProfileData?.user_profile?.gender,
      marital_status: userProfileData?.user_profile?.marital_status,
      dateOfBirth: moment(userProfileData?.user_profile?.dateOfBirth),
      gmail_id: userProfileData?.gmail_id,
      location: values?.location,
      state: values?.state,
      mandal: values?.mandal,
      district: values?.district,
      village: values?.village,
      breif_about_self: userProfileData?.user_profile?.breif_about_self,
      educational_qualification_id: userProfileData?.user_profile?.educational_qualification_id,
      work_experience_id: userProfileData?.user_profile?.work_experience_id,
      aadhar_number: userProfileData?.user_profile?.aadhar?.replace(/-/g, ""),

      pancard_number: userProfileData?.user_profile?.pancard,

      bank_account_number: userProfileData?.user_profile?.bank_account_number,
      bank_address: userProfileData?.user_profile?.bank_address,
      bank_ifsc_code: userProfileData?.user_profile?.bank_ifsc_code,
      bank_name: userProfileData?.user_profile?.bank_name,
      swift_code: userProfileData?.user_profile?.swift_code,
    };
    APICall(payload);
  };
  const submitProfileAdditionalInfoForm = (values) => {
    const payload = {
      user_id: userProfileData?.user_id,
      firstname: userProfileData?.user_profile?.firstname,
      lastname: userProfileData?.user_profile?.lastname,
      display_name: userProfileData?.user_profile?.display_name,
      mobile_number: userProfileData?.user_profile?.mobile_number,
      gender: userProfileData?.user_profile?.gender,
      marital_status: userProfileData?.user_profile?.marital_status,
      dateOfBirth: moment(userProfileData?.user_profile?.dateOfBirth),
      gmail_id: userProfileData?.gmail_id,
      location: userProfileData?.user_profile?.location,
      state: userProfileData?.user_profile?.state,
      mandal: userProfileData?.user_profile?.mandal,
      district: userProfileData?.user_profile?.district,
      village: userProfileData?.user_profile?.village,
      breif_about_self: values?.breif_about_self,
      educational_qualification_id: values?.educational_qualification_id,
      work_experience_id: values?.work_experience_id,
      aadhar_number: userProfileData?.user_profile?.aadhar?.replace(/-/g, ""),

      pancard_number: userProfileData?.user_profile?.pancard,

      bank_account_number: userProfileData?.user_profile?.bank_account_number,
      bank_address: userProfileData?.user_profile?.bank_address,
      bank_ifsc_code: userProfileData?.user_profile?.bank_ifsc_code,
      bank_name: userProfileData?.user_profile?.bank_name,
      swift_code: userProfileData?.user_profile?.swift_code,
    };
    APICall(payload);
  };

  const submitProfileKYCForm = (values) => {
    const payload = {
      user_id: userProfileData?.user_id,
      firstname: userProfileData?.user_profile?.firstname,
      lastname: userProfileData?.user_profile?.lastname,
      display_name: userProfileData?.user_profile?.display_name,
      mobile_number: userProfileData?.user_profile?.mobile_number,
      gender: userProfileData?.user_profile?.gender,
      marital_status: userProfileData?.user_profile?.marital_status,
      dateOfBirth: moment(userProfileData?.user_profile?.dateOfBirth),
      gmail_id: userProfileData?.gmail_id,
      location: userProfileData?.user_profile?.location,
      state: userProfileData?.user_profile?.state,
      mandal: userProfileData?.user_profile?.mandal,
      district: userProfileData?.user_profile?.district,
      village: userProfileData?.user_profile?.village,
      breif_about_self: values?.breif_about_self,
      educational_qualification_id: values?.educational_qualification_id,
      work_experience_id: values?.work_experience_id,
      aadhar_number: values?.aadhar?.replace(/-/g, ""),
      pancard_number: values?.pancard,

      bank_account_number: userProfileData?.user_profile?.bank_account_number,
      bank_address: userProfileData?.user_profile?.bank_address,
      bank_ifsc_code: userProfileData?.user_profile?.bank_ifsc_code,
      bank_name: userProfileData?.user_profile?.bank_name,
      swift_code: userProfileData?.user_profile?.swift_code,
    };
    APICall(payload);
  };

  const submitProfileBankInfoForm = (values) => {
    let user_id = userProfileData?.user_id;
    const payload = {
      user_id: user_id,
      firstname: values?.firstname,
      lastname: values?.lastname,
      display_name: values?.display_name,
      mobile_number: values?.mobile_number,
      gender: values?.gender,
      marital_status: values?.marital_status,
      date_of_birth: dobDate?.format(),
      gmail_id: values?.gmail_id,
      location: userProfileData?.user_profile?.location,
      state: userProfileData?.user_profile?.state,
      mandal: userProfileData?.user_profile?.mandal,
      district: userProfileData?.user_profile?.district,
      village: userProfileData?.user_profile?.village,
      breif_about_self: userProfileData?.user_profile?.breif_about_self,
      educational_qualification_id: userProfileData?.user_profile?.educational_qualification_id,
      work_experience_id: userProfileData?.user_profile?.work_experience_id,
      aadhar_number: userProfileData?.user_profile?.aadhar?.replace(/-/g, ""),
      pancard_number: userProfileData?.user_profile?.pancard,
      bank_account_number: values?.bank_account_number,
      bank_address: values?.bank_address,
      bank_ifsc_code: values?.bank_ifsc_code,
      bank_name: values?.bank_name,
      swift_code: values?.swift_code,
    };
    APICall(payload);
  };
  const handleDOB = (date, dateString) => {
    console.log("value", date, dateString);
    setDobDate(moment(dateString, "DD/MM/YYYY"));
    if (dateString === null) {
      profileAboutYourSelf.setFieldsValue({
        age: "",
      });
      return;
    }
    const [day, month, year] = dateString.split("/").map(Number);
    const birthDate = new Date(year, month - 1, day); // Month is 0-indexed in JavaScript

    // Get today's date
    const today = new Date();

    // Calculate the age
    let age = today.getFullYear() - birthDate.getFullYear();

    // Adjust for cases where the birthday hasn't occurred yet this year
    const isBirthdayPassed =
      today.getMonth() > birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() && today.getDate() >= birthDate.getDate());

    if (!isBirthdayPassed) {
      age--;
    }
    // var dob = new Date(moment(dateString));
    // //calculate month difference from current date in time
    // var month_diff = Date.now() - dob.getTime();

    // //convert the calculated difference in date format
    // var age_dt = new Date(month_diff);

    // //extract year from date
    // var year = age_dt.getUTCFullYear();

    // //now calculate the age of the user
    // var ageDiff = Math.abs(year - 1970);
    profileAboutYourSelf.setFieldsValue({
      age: age,
    });
  };
  const handleuChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    let user_id = userProfileData?.user_id;
    var payload = new FormData();

    // if (fileList?.[0]?.originFileObj) {
    // }
    // else {
    //   toast.error('Please Upload image');
    // }

    // if (newFileList?.length === 1 && newFileList?.[0]?.originFileObj) {

    //   if (newFileList?.[0]?.originFileObj) {
    //     const reader = new FileReader();
    //     reader.onload = function (e) {
    //       const binaryData = e.target.result;
    //       console.log('Binary data:', binaryData);
    //       // You can now use binaryData as needed, such as sending it to a server or processing it further
    //     };
    //     reader.readAsBinaryString(fileList?.[0]?.originFileObj);
    //   }

    //   payload.append("profileimage", fileList?.[0]?.originFileObj);
    //   dispatch(updateProfileUserPicture(payload))
    // }

    // if (newFileList?.length === 1 && newFileList?.[0]?.originFileObj) {
    //   const reader = new FileReader();
    //   reader.onload = function (e) {
    //     const binaryData = e.target.result;
    //     console.log('Binary data:', binaryData);

    //     const payload = new FormData();
    //    / Append the binary data to the FormData

    //     // Dispatch the action with the FormData containing the binary data
    //     dispatch(updateProfileUserPicture(payload));
    //   };
    //   reader.readAsBinaryString(newFileList?.[0]?.originFileObj);
    // }

    var data1 = new FormData();
    if (newFileList?.[0]?.originFileObj) {
      const file = newFileList[0]?.originFileObj;
      if (file) {
        const formData = new FormData();
        formData.append("profileimage", file);
        console.log("FormData:", formData); // Che

        dispatch(updateProfileUserPicture(formData));
      }
    }
  };
  const uploadButton = (
    <div style={{ position: "relative", top: "12vh" }}>
      <Button>
        <img src={uploadProfileImg} style={{ cursor: "pointer" }} />
        <input
          hidden
          accept="image/*"
          id="contained-button-file"
          multiple
          type="file"
          onChange={(e) => fileChangedHandler(e)}
        />
      </Button>
    </div>
  );
  const uploadButtonAadhar = <div style={{ position: "relative", top: "12vh" }}></div>;
  const uploadButtonPanCard = <div style={{ position: "relative", top: "12vh" }}></div>;

  const handlePreview = async (file) => {
    console.log("entered preview", file);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    setFModalVisible(true);
    setImagePreview(src);
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleImageModelCancel = () => {
    // setIsModalVisible(false);
    setFModalVisible(false);
  };

  const handleOpenMobileOTPModel = () => {
    let first_name = userProfileData?.user_profile?.firstname;
    let mobile_number = userProfileData?.user_profile?.mobile_number;
    dispatch(getOTPData({ first_name, mobile_number }))
      .then((action) => {
        if (sendOTPtoMail.fulfilled.match(action)) {
          message.success({
            content: "OTP sent successfully",
            duration: 5, // Specify the duration in seconds
            style: {
              marginTop: "20px", // Adjust the margin if needed
              marginBottom: "20px", // Add margin at the bottom
            },
          });
        }
      })
      .catch((err) => {
        message.error({
          content: "Some thing went wrong",
          duration: 5,
          style: {
            marginTop: "20px",
            marginBottom: "20px",
          },
        });
      });
    setIsOTPModalOpen(true);
  };
  const handleOpenOTPModel = () => {
    const payload = {
      first_name: userProfileData?.user_profile?.firstname,
      mobile_number: userProfileData?.gmail_id,
    };

    // localStorage.setItem('userDetails', JSON.stringify(payload))
    // handleCancel();
    //  openingOTPlsModel(true);
    let gmail = userProfileData?.gmail_id;
    let user_id = userProfileData?.user_id;
    dispatch(sendOTPtoMail({ gmail, user_id }))
      .then((action) => {
        if (sendOTPtoMail.fulfilled.match(action)) {
          message.success({
            content: "OTP sent successfully",
            duration: 5, // Specify the duration in seconds
            style: {
              marginTop: "20px", // Adjust the margin if needed
              marginBottom: "20px", // Add margin at the bottom
            },
          });
        }
      })
      .catch((err) => {
        message.error({
          content: "Some thing went wrong",
          duration: 5,
          style: {
            marginTop: "20px",
            marginBottom: "20px",
          },
        });
      });
    setIsOTPModalOpen(true);
  };
  const handleCloseLoginModel = (data) => {
    setIsOTPModalOpen(false);
  };

  const handleRemove = (index) => {
    const newValues = [...profileAboutYourSelf.getFieldValue("socialMediaAccounts")];
    setEmptyFieldIndexArr([]);
    // setDupIndex([])
    // setLengthError([])
    const fieldValueToRemove = newValues[index];
    if (!newValues?.includes("")) {
      setDisableAddBtn(false);
    }

    if (fieldValueToRemove === undefined) {
      const myupdated = newValues.filter((value) => value !== undefined);
      setSubLevelOldData(myupdated);
      profileAboutYourSelf.setFieldsValue({
        socialMediaAccounts: myupdated,
      });
      return;
    }
    setCurrentFormsList(newValues);
    setRemovingItem(fieldValueToRemove);
    if (fieldValueToRemove) {
      updateRemoveValuesFromCurrentList(newValues, fieldValueToRemove);
    }
  };
  const updateRemoveValuesFromCurrentList = (newValues, fieldValueToRemove) => {
    const data = newValues?.filter((value) => value !== fieldValueToRemove);
    const copyRemovedData = [...removedList];
    copyRemovedData.push(fieldValueToRemove);
    setRemovedList(copyRemovedData);
    const afterRemoveoldData = subLevelOldData?.filter((value) => value !== fieldValueToRemove);
    setSubLevelOldData(afterRemoveoldData);
    // setSubLevelBackup(afterRemoveoldData);
    profileAboutYourSelf.setFieldsValue({
      socialMediaAccounts: data,
    });
    setCurrentFormsList(data);
    setRemovingItem(null);
  };
  const validateAadharUpload = (rule, value, callback) => {
    if (fileListForAddhar?.length === 0) {
      callback("Upload Aadhar Card");
    } else {
      callback();
    }
  };

  const dummyRequestForAAdhar = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const dummyRequestForPAN = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handlePreviewForAAdhar = async (file) => {
    console.log("entered preview", file);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    // setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    // setPreviewTitle(
    //   file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    // );

    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }

    setFModalVisible(true);
    setImagePreview(src);
  };
  const handlePreviewForPANcard = async (file) => {
    // console.log("entered preview", file);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    // setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    // setPreviewTitle(
    //   file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    // );

    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }

    setFModalVisible(true);
    setImagePreview(src);
  };
  const handleuChangeForAddhar = ({ fileList: newFileList }) => {
    setFileListForAddhar(newFileList);
    // if (currentAction === "update" && buData.aadhaar_url === null) {
    //   deleteform.resetFields();
    //   setDeletedAadharComments("aadharcard");
    //   setModalTitle("Uploading Image");
    //   setIsModalVisible(true);
    // }
    // if (newFileList?.length == 0) {
    //   // showAuditModal("is_primary_tag", true ? "true" : "false", "yest", '')fileListForAddhar fileListForPANcard
    //   if (currentAction === "update") {
    //     deleteform.resetFields();
    //     setDeletedAadharComments("aadharcard");
    //     setModalTitle("Current uploaded Aadhar");
    //     setIsModalVisible(true);
    //   }
    // }
  };

  const transformInput = (value) => {
    return value ? value.toUpperCase() : value;
  };

  const fileChangedHandler = async (event) => {
    const files = event.target.files;
    var formdata = new FormData();

    formdata.append("profileimage", files[0]);
    let user_id = userProfileData?.user_id;
    // dispatch(updateProfileUserPicture(formData));
    var requestOptions = {
      method: "POST",
      body: formdata,
    };

    fetch(`${BaseUrl}/user/profileimage?user_id=${user_id}`, requestOptions)
      .then((response) => response.json())
      .then(async (res) => {
        console.log(res);
        if (res?.profile_image_url) dispatch(getUserProfileData(user_id));
        else console.error("something went wrong while uploading user profile");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const kycfilechangehandler = async (event, type) => {
    let user_id = userProfileData?.user_id;
    const files = event.target.files;
    if (files) {
      var formdata = new FormData();

      formdata.append("kycimage", files[0]);
      var requestOptions = {
        method: "POST",
        body: formdata,
      };
      fetch(
        `${BaseUrl}/user/kycimage?user_id=${user_id}&kyc_type=${type}`,

        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          if (res) dispatch(getUserProfileData(user_id));
          else console.error("something went wrong while uploading user profile");
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const disabledDate = (current) => {
    // Disable dates after today or one year ago
    return (
      current &&
      (current > moment() ||
        current > moment().subtract(14, "years") ||
        current > moment() ||
        current < moment().subtract(100, "years"))
    );
  };

  const onImageRemove = async (value) => {
    let user_id = userProfileData?.user_id;
    const result = await axios.delete(`${BaseUrl}/user/kycimage?image_id=${value}`);
    if (result.data?.is_success) dispatch(getUserProfileData(user_id));
    else console.error("something went wrong while uploading user profile");
  };

  const [activeKey, setActiveKey] = useState("1");
  const onKeyBack = () => {
    const prevTab = (parseInt(activeKey) - 1).toString();
    setActiveKey(prevTab);
  };
  const onKeyNext = () => {
    const nextTab = (parseInt(activeKey) + 1).toString();
    setActiveKey(nextTab);
  };

  const [showEducationBrief, setShowEducationBrief] = useState(false);
  const [showOccupationBrief, setShowOccupationBrief] = useState(false);

  const handleEducationChange = (value) => {
    setShowEducationBrief(!!value);
  };

  const handleOccupationChange = (value) => {
    setShowOccupationBrief(!!value);
  };

  const [selectedGender, setSelectedGender] = useState("male");
  const genderValues = [
    { gender: "male", genderInTelugu: "పురుషుడు" },
    { gender: "female", genderInTelugu: "స్త్రీ" },
    { gender: "Other", genderInTelugu: "ఇతరులు" },
  ];

  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState("single");
  const maritalStatusDataa = [
    { marital_status: "single", statusInTelugu: "ఒంటరి" },
    { marital_status: "married", statusInTelugu: "వివాహితుడు" },
    { marital_status: "divorced", statusInTelugu: "తప్పించుకున్నవాడు" },
    { marital_status: "widowed", statusInTelugu: "వితంతువు" },
  ];

  return (
    <>
      <Tabs
        tabPosition="left"
        className="custom-tabs-width custom-tabs-inkbar"
        activeKey={activeKey}
        onChange={setActiveKey}>
        <TabPane
          tab={
            selectedInterfaceLanguage && (
              <TranslateWord key={"ABOUTYOURSELF"} text={"ABOUTYOURSELF"} lang={selectedInterfaceLanguage} />
            )
          }
          // key="About your self"
          key="1">
          <div style={{ marginBottom: "3vh" }}>
            <h1>
              {selectedInterfaceLanguage && (
                <TranslateWord
                  key={"ABOUTYOURSELF"}
                  text={"ABOUTYOURSELF"}
                  lang={selectedInterfaceLanguage}
                  headingStyle={true}
                />
              )}
            </h1>
          </div>
          <div>
            <Form
              form={profileAboutYourSelf}
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={submitProfileAboutYourSelfForm}>
              <Row gutter={[32, 16]}>
                <Col
                  style={{ borderRight: "1px solid lightgray" }}
                  xs={{ span: 24, order: 2 }}
                  sm={{ span: 24, order: 2 }}
                  md={{ span: 14, order: 1 }}>
                  {/* <Col xs={24} sm={24} md={12}> */}
                  <Form.Item
                    name="firstname"
                    label={
                      selectedInterfaceLanguage && (
                        <TranslateWord key={"FIRSTNAME"} text={"FIRSTNAME"} lang={selectedInterfaceLanguage} />
                      )
                    }>
                    <Input name="firstname" />
                  </Form.Item>
                  <Form.Item
                    name="lastname"
                    label={
                      selectedInterfaceLanguage && (
                        <TranslateWord key={"LASTNAME"} text={"LASTNAME"} lang={selectedInterfaceLanguage} />
                      )
                    }>
                    <Input name="lastname" />
                  </Form.Item>
                  <Form.Item
                    name="display_name"
                    rules={[
                      {
                        required: true,
                        message: "Enter Display name",
                      },
                    ]}
                    label={
                      selectedInterfaceLanguage && (
                        <TranslateWord key={"DISPLAYNAME"} text={"DISPLAYNAME"} lang={selectedInterfaceLanguage} />
                      )
                    }>
                    <Input name="display_name" />
                  </Form.Item>

                  <Row>
                    <Col xs={17} sm={17} md={18}>
                      <Form.Item
                        name="mobile_number"
                        label={
                          selectedInterfaceLanguage && (
                            <TranslateWord
                              key={"MOBILENUMBER"}
                              text={"MOBILENUMBER"}
                              lang={selectedInterfaceLanguage}
                            />
                          )
                        }>
                        <Input name="mobile_number" disabled={userProfileData?.is_mobile_verified} />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      style={{
                        display: "flex",
                        alignItems: "center", // Vertical alignment
                        justifyContent: "center", // Horizontal alignment
                      }}>
                      {userProfileData?.is_mobile_verified ? (
                        <CheckCircleTwoTone style={{ fontSize: "18px" }} twoToneColor="#52c41a" />
                      ) : (
                        <>
                          <Button
                            style={{ position: "relative", top: "5vh" }}
                            onClick={handleOpenMobileOTPModel}
                            type="link">
                            {selectedInterfaceLanguage && (
                              <TranslateWord key={"SEND_OTP"} text={"SEND_OTP"} lang={selectedInterfaceLanguage} />
                            )}
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={17} sm={17} md={18}>
                      <Form.Item
                        name="gmail_id"
                        label={
                          selectedInterfaceLanguage && (
                            <TranslateWord key={"EMAIL_ID"} text={"EMAIL_ID"} lang={selectedInterfaceLanguage} />
                          )
                        }>
                        <Input name="gmail_id" disabled={userProfileData?.is_gmail_verified} />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                      {userProfileData?.is_gmail_verified ? (
                        <CheckCircleTwoTone style={{ fontSize: "18px" }} twoToneColor="#52c41a" />
                      ) : (
                        <>
                          <Button style={{ position: "relative", top: "5vh" }} onClick={handleOpenOTPModel} type="link">
                            {selectedInterfaceLanguage && (
                              <TranslateWord key={"SEND_OTP"} text={"SEND_OTP"} lang={selectedInterfaceLanguage} />
                            )}
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="dateOfBirth"
                        label={
                          selectedInterfaceLanguage && (
                            <TranslateWord key={"DOB"} text={"DOB"} lang={selectedInterfaceLanguage} />
                          )
                        }>
                        <Space direction="vertical">
                          <DatePicker
                            picker="date"
                            // disabledDate={disabledDate}
                            placeholder="Enter DOB"
                            format={"DD/MM/YYYY"}
                            // ref={myDOBRef}

                            allowClear={false}
                            value={dobDate}
                            style={{
                              width: "100%",
                              // height: "32px",
                              // left: "8px",
                              // top: '312.2px',
                              background: "#FFFFFF",
                              border: "1px solid #DADADA",
                              borderradius: "4px",
                            }}
                            onChange={handleDOB}
                          />
                        </Space>

                        {/* <Space direction="vertical"> */}

                        {/* <DatePicker
                          picker="date"
                          placeholder="Enter DOB"
                          format={dateFormat}
                          style={{ width: "24vw" }}
                          onChange={handleDOB}
                        
                        /> */}
                        {/* </Space> */}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="age"
                        label={
                          selectedInterfaceLanguage && (
                            <TranslateWord key={"AGE"} text={"AGE"} lang={selectedInterfaceLanguage} />
                          )
                        }>
                        <Input name="age" readOnly />
                      </Form.Item>
                    </Col>
                    <span
                      style={{
                        fontSize: "11px",
                        position: "relative",
                        top: "-3vh",
                      }}>
                      {/* Minimum age of users to post content (user generated content) to be 13 years. */}
                      {selectedInterfaceLanguage && (
                        <TranslateWord key={"MIN_AGE"} text={"MIN_AGE"} lang={selectedInterfaceLanguage} />
                      )}
                    </span>
                  </Row>
                  <Row gutter={5}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="gender"
                        label={
                          selectedInterfaceLanguage && (
                            <TranslateWord key={"GENDER"} text={"GENDER"} lang={selectedInterfaceLanguage} />
                          )
                        }>
                        <Select
                          //  placeholder="Select Gender"
                          placeholder={
                            selectedInterfaceLanguage?.name === "తెలుగు" ? "లింగాన్ని ఎంచుకోండి" : "Select Gender"
                          }
                          value={
                            selectedInterfaceLanguage?.name === "తెలుగు"
                              ? genderValues.find((g) => g.gender === selectedGender)?.genderInTelugu
                              : selectedGender
                          }
                          onChange={(value) => setSelectedGender(value)}
                          style={{ border: "1px solid #DADADA" }}>
                          {genderValues?.map((gender) => (
                            <Option key={gender.gender} value={gender.gender}>
                              {/* {gender.gender} */}
                              {selectedInterfaceLanguage?.name === "తెలుగు" ? gender.genderInTelugu : gender.gender}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="marital_status"
                        label={
                          selectedInterfaceLanguage && (
                            <TranslateWord
                              key={"MARITAL_STATUS"}
                              text={"MARITAL_STATUS"}
                              lang={selectedInterfaceLanguage}
                            />
                          )
                        }>
                        <Select
                          // placeholder="Select Marital Status"
                          placeholder={
                            selectedInterfaceLanguage?.name === "తెలుగు"
                              ? "వివాహ స్థితి ఎంచుకోండి"
                              : "Select Marital Status"
                          }
                          value={
                            selectedInterfaceLanguage?.name === "తెలుగు"
                              ? maritalStatusData.find((status) => status.marital_status === selectedMaritalStatus)
                                  ?.statusInTelugu
                              : selectedMaritalStatus
                          }
                          onChange={(value) => setSelectedMaritalStatus(value)}
                          style={{ border: "1px solid #DADADA" }}>
                          {maritalStatusDataa?.map((status) => (
                            <Option key={status.marital_status} value={status.marital_status}>
                              {/* {status.marital_status} */}
                              {selectedInterfaceLanguage?.name === "తెలుగు"
                                ? status.statusInTelugu
                                : status.marital_status}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ float: "right" }}>
                      {selectedInterfaceLanguage && (
                        <TranslateWord key={"UPDATE"} text={"UPDATE"} lang={selectedInterfaceLanguage} />
                      )}
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ float: "right" }}
                      className={screens.xs ? "nextbutton-mob" : "nextbutton"}
                      onClick={onKeyNext}
                      disabled={activeKey === "2"}>
                      {selectedInterfaceLanguage && (
                        <TranslateWord key={"NEXT"} text={"NEXT"} lang={selectedInterfaceLanguage} />
                      )}
                    </Button>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 10, order: 2 }}>
                  <div style={{ marginLeft: "4vw", marginBottom: "5vh" }}>
                    {" "}
                    <TranslateWord
                      key={"UPLOAD_PROFILE"}
                      text={"UPLOAD_PROFILE"}
                      lang={selectedInterfaceLanguage}
                      headingStyle={false}
                    />
                  </div>

                  {/* <div style={{textAlign:'center'}}> <img src={userProfilePic} /></div> */}
                  {/* <div style={{textAlign:'center',marginTop:'2vh'}}> <img src={uploadProfileImg}  style={{cursor:'pointer'}}/></div> */}
                  {/* <div style={{ textAlign: "center" }}>
                    <Upload
                      action="http://localhost:3000/"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleuChange}
                      customRequest={dummyRequest}
                    >
                      {fileList.length === 1 ? null : uploadButton}
                    </Upload>
                  </div> */}

                  <div style={{ textAlign: "center" }}>
                    <div>
                      <img
                        src={userProfileData?.profile_url}
                        width={100}
                        height={100}
                        style={{ borderRadius: "50%" }}
                      />
                    </div>

                    <div style={{ marginTop: "5px" }}>
                      <HiOutlineCamera
                        style={{ fontSize: "20px", marginRight: "5px", marginTop: "-2px", color: "#01579B" }}
                      />
                      <label for="file-upload" style={{ color: "#01579B" }}>
                        {/* <label for="file-upload" class="custom-file-upload"> */}
                        <TranslateWord
                          key={"UPLOAD_PROFILE_IMAGE"}
                          text={"UPLOAD_PROFILE_IMAGE"}
                          lang={selectedInterfaceLanguage}
                          headingStyle={false}
                        />
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        accept="image/*"
                        onChange={(e) => fileChangedHandler(e)}
                        multiple
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>

                  <div className="fileViewModel">
                    <Modal
                      title="Image view"
                      visible={fModalVisible}
                      onCancel={handleImageModelCancel}
                      footer={null}
                      width={500}>
                      <div className="modelIcon "></div>
                      {/* <iframe src={imagePreview} width="100%" height="400px" ></iframe> */}
                      <img src={imagePreview} alt="Girl in a jacket" width="450" height="410" />
                    </Modal>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </TabPane>
        <TabPane
          tab={
            selectedInterfaceLanguage && (
              <TranslateWord key={"ADDRESS"} text={"ADDRESS"} lang={selectedInterfaceLanguage} />
            )
          }
          // key="Address"
          key="2">
          <div>
            <h1>
              {selectedInterfaceLanguage && (
                <TranslateWord key={"ADDRESS"} text={"ADDRESS"} lang={selectedInterfaceLanguage} headingStyle={true} />
              )}
            </h1>
          </div>
          <div>
            <Form
              form={profileAboutYourSelf}
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={submitAddressSelfForm}>
              <Row>
                <Col xs={24} sm={24} md={22} lg={22} xl={22} xxl={22}>
                  <Row gutter={5}>
                    <Col xs={20} sm={20} md={22} lg={22} xl={22} xxl={22}>
                      <Form.Item
                        name="location"
                        label={
                          selectedInterfaceLanguage && (
                            <TranslateWord key={"LOCATION"} text={"LOCATION"} lang={selectedInterfaceLanguage} />
                          )
                        }>
                        <Input name="location" />
                      </Form.Item>
                    </Col>
                    <Col xs={4} sm={4} md={2} lg={2} xl={2} xxl={2}>
                      <img
                        src={map}
                        alt="Google Icon"
                        style={{
                          width: "25px",
                          height: "25px",
                          cursor: "pointer",
                          marginLeft: "8px",
                          marginTop: "30px",
                        }}
                      />
                    </Col>
                  </Row>

                  <Row gutter={5}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="state"
                        label={
                          selectedInterfaceLanguage && (
                            <TranslateWord key={"STATE"} text={"STATE"} lang={selectedInterfaceLanguage} />
                          )
                        }>
                        <Input name="state" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="district"
                        label={
                          selectedInterfaceLanguage && (
                            <TranslateWord key={"DISTRICT"} text={"DISTRICT"} lang={selectedInterfaceLanguage} />
                          )
                        }>
                        <Input name="district" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={5}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="mandal"
                        label={
                          selectedInterfaceLanguage && (
                            <TranslateWord key={"MANDAL"} text={"MANDAL"} lang={selectedInterfaceLanguage} />
                          )
                        }>
                        <Input name="mandal" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="village"
                        label={
                          selectedInterfaceLanguage && (
                            <TranslateWord
                              key={"VILLAGE_AREA_LOCALITY"}
                              text={"VILLAGE_AREA_LOCALITY"}
                              lang={selectedInterfaceLanguage}
                            />
                          )
                        }>
                        <Input name="village" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ float: "right" }}>
                      {selectedInterfaceLanguage && (
                        <TranslateWord key={"UPDATE"} text={"UPDATE"} lang={selectedInterfaceLanguage} />
                      )}
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ float: "right" }}
                      className={screens.xs ? "nextbutton-mob" : "nextbutton"}
                      onClick={onKeyNext}
                      disabled={activeKey === "3"}>
                      {selectedInterfaceLanguage && (
                        <TranslateWord key={"NEXT"} text={"NEXT"} lang={selectedInterfaceLanguage} />
                      )}
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ float: "right" }}
                      className={screens.xs ? "previousbutton-mob" : "previousbutton"}
                      onClick={onKeyBack}
                      disabled={activeKey === "1"}>
                      {selectedInterfaceLanguage && (
                        <TranslateWord key={"PREVIOUS"} text={"PREVIOUS"} lang={selectedInterfaceLanguage} />
                      )}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </TabPane>
        <TabPane
          tab={
            selectedInterfaceLanguage && (
              <TranslateWord
                key={"ADDITIONAL_INFORMATION"}
                text={"ADDITIONAL_INFORMATION"}
                lang={selectedInterfaceLanguage}
              />
            )
          }
          // key="Additional Information"
          key="3">
          <div style={{ marginBottom: "3vh" }}>
            <h1>
              {selectedInterfaceLanguage && (
                <TranslateWord
                  key={"ADDITIONAL_INFORMATION"}
                  text={"ADDITIONAL_INFORMATION"}
                  lang={selectedInterfaceLanguage}
                  headingStyle={true}
                />
              )}
            </h1>
          </div>
          <Form
            form={profileAboutYourSelf}
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            onFinish={submitProfileAdditionalInfoForm}>
            <Row>
              <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}>
                <Form.Item
                  name="breif_about_self"
                  label={
                    selectedInterfaceLanguage && (
                      <TranslateWord key={"ABOUTYOURSELF"} text={"ABOUTYOURSELF"} lang={selectedInterfaceLanguage} />
                    )
                  }>
                  <TextArea rows={4} />
                </Form.Item>

                <Row gutter={5}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                      name="educational_qualification_id"
                      label={
                        selectedInterfaceLanguage && (
                          <TranslateWord
                            key={"EDUCATIONAL_QUALIFICATIONS"}
                            text={"EDUCATIONAL_QUALIFICATIONS"}
                            lang={selectedInterfaceLanguage}
                          />
                        )
                      }>
                      <Select
                        placeholder="Select"
                        style={{ border: "1px solid #DADADA" }}
                        onChange={handleEducationChange}>
                        {educationQualificationData?.map((qualification) => (
                          <Option key={qualification.eq_id} value={qualification.eq_id}>
                            {qualification.eq_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                      name="work_experience_id"
                      label={
                        selectedInterfaceLanguage && (
                          <TranslateWord key={"OCCUPATION"} text={"OCCUPATION"} lang={selectedInterfaceLanguage} />
                        )
                      }>
                      <Select
                        placeholder="Select"
                        style={{ border: "1px solid #DADADA" }}
                        onChange={handleOccupationChange}>
                        {workExperienceData?.map((occ) => (
                          <Option key={occ.we_id} value={occ.we_id}>
                            {occ.we_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={5}>
                  {showEducationBrief && (
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item name="Brief of Educational Qualifications" label="Brief of Educational Qualifications">
                        <TextArea rows={4} />
                      </Form.Item>
                    </Col>
                  )}
                  {showOccupationBrief && (
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item name="Brief of Occupation" label="Brief of Occupation">
                        <TextArea rows={4} />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                <Row gutter={5}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.List name="socialMediaAccounts">
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map((field, index) => (
                            <Form.Item
                              label={index === 0 ? "Link your social media accounts" : ""}
                              required={true}
                              key={field.key}

                              // validateStatus={
                              //   emptyFieldIndexArr.includes(index) ? "error" : dupIndex.includes(index) ? "error" : lengthError.includes(index) ? "error" : ""
                              // }
                              // help={
                              //   emptyFieldIndexArr.includes(index)
                              //     ? "Please Enter Link your social media accounts or delete this field."
                              //     : dupIndex.includes(index)
                              //       ? "Duplicate Data Error" : lengthError.includes(index) ? "Maximum 10 charecters allowed"
                              //         : ""
                              // }
                            >
                              <Form.Item
                                {...field}
                                validateTrigger={["onChange"]}
                                // rules={[
                                // {
                                //   required: true,
                                //   whitespace: true,
                                //   message:
                                //     "Please Enter Link your social media accounts or delete this field.",
                                // },
                                // {
                                //   max: 10,
                                //   message: "Link your social media accounts must be lessthan 10 characters",
                                // },
                                // ]}
                                noStyle
                                shouldUpdate={(prevValues, curValues) => {
                                  curentListValues = curValues?.socialMediaAccounts;
                                }}>
                                <Input
                                  placeholder="Enter Link your social media accounts"
                                  style={{
                                    width: "80%",
                                  }}
                                  ref={(input) => (inputRefs.current[index] = input)}
                                />
                              </Form.Item>
                              <MinusCircleOutlined
                                // onClick={() => {
                                //   remove(field.name);
                                //   getRemovedItem(field);
                                // }}
                                style={{
                                  fontSize: "18px",
                                  color: "#f34b4b",
                                  margin: "4px 0px 0px 5px",
                                }}
                                onClick={() => handleRemove(index)}
                              />
                            </Form.Item>
                          ))}
                          <Form.Item>
                            <Button
                              disabled={dupIndex.length != 0 || emptyFieldIndexArr.length != 0}
                              onClick={() => {
                                add();
                                // focusFunction();
                              }}
                              icon={<PlusOutlined />}
                              style={{
                                border: "1px solid lightgray",
                                pointerEvents: disableAddBtn ? "none" : null,
                              }}
                              // disabled={disableAddBtn}
                            >
                              Add Link your social media accounts
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Col>
                </Row>
                <Form.Item>
                  <Button type="primary" htmlType="submit" style={{ float: "right" }}>
                    {selectedInterfaceLanguage && (
                      <TranslateWord key={"UPDATE"} text={"UPDATE"} lang={selectedInterfaceLanguage} />
                    )}
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ float: "right" }}
                    className={screens.xs ? "nextbutton-mob" : "nextbutton"}
                    onClick={onKeyNext}
                    disabled={activeKey === "4"}>
                    {selectedInterfaceLanguage && (
                      <TranslateWord key={"NEXT"} text={"NEXT"} lang={selectedInterfaceLanguage} />
                    )}
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ float: "right" }}
                    className={screens.xs ? "previousbutton-mob" : "previousbutton"}
                    onClick={onKeyBack}
                    disabled={activeKey === "2"}>
                    {selectedInterfaceLanguage && (
                      <TranslateWord key={"PREVIOUS"} text={"PREVIOUS"} lang={selectedInterfaceLanguage} />
                    )}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </TabPane>
        <TabPane
          tab={selectedInterfaceLanguage && <TranslateWord key={"KYC"} text={"KYC"} lang={selectedInterfaceLanguage} />}
          // key="KYC"
          key="4">
          <div>
            <h1>
              {selectedInterfaceLanguage && (
                <TranslateWord key={"KYC"} text={"KYC"} lang={selectedInterfaceLanguage} headingStyle={true} />
              )}
            </h1>
          </div>
          <div>
            <Form
              form={profileAboutYourSelf}
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={submitProfileKYCForm}>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
                  <Form.Item
                    name="aadhar"
                    label={
                      selectedInterfaceLanguage && (
                        <TranslateWord key={"AADHAR_NUMBER"} text={"AADHAR_NUMBER"} lang={selectedInterfaceLanguage} />
                      )
                    }
                    rules={[
                      {
                        required: true,
                        message: "Enter Aadhar Number",
                      },
                      {
                        whitespace: true,
                        message: "Aadhar Number can not be empty",
                      },
                      { max: 12, message: "Aadhar Number should be 12 digits" },
                      { min: 12, message: "Aadhar Number should be 12 digits" },
                      // {
                      //   pattern: new RegExp(/^(\d{4}-){3}\d{4}$/),
                      //   message: "Numbers only allowed",
                      // },
                      // {
                      //   pattern: new RegExp(/^\S+$/),
                      //   message: "Spaces not allowed",
                      // },
                      // {
                      //   validator: validateAadhaarNumber,
                      // },
                    ]}>
                    <Input
                      name="aadhar"
                      autoComplete="off"
                      placeholder="1111222233334444"
                      // onChange={chandleChange}

                      // ref={myaadharRef}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <div style={{ textAlign: "center" }}>
                    <div>
                      {userProfileData?.user_profile?.aadhar_image?.image_url && (
                        <img src={userProfileData?.user_profile?.aadhar_image?.image_url} width={100} height={100} />
                      )}
                    </div>

                    <div style={{ marginTop: "19px" }}>
                      <label for="file-upload-aadhar" class="custom-file-upload">
                        {selectedInterfaceLanguage && (
                          <TranslateWord
                            key={"UPLOAD_AADHAR"}
                            text={"UPLOAD_AADHAR"}
                            lang={selectedInterfaceLanguage}
                          />
                        )}
                      </label>
                      {userProfileData?.user_profile?.aadhar_image?.image_url && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            onImageRemove(userProfileData?.user_profile?.aadhar_image?.image_id);
                          }}>
                          <img src={deleteIcon} width={20} height={20} />
                        </span>
                      )}

                      <input
                        id="file-upload-aadhar"
                        type="file"
                        accept="image/*"
                        onChange={(e) => kycfilechangehandler(e, "Aadhar")}
                        multiple
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
                  <Form.Item
                    name="pancard"
                    label={
                      selectedInterfaceLanguage && (
                        <TranslateWord key={"PANCARD"} text={"PANCARD"} lang={selectedInterfaceLanguage} />
                      )
                    }
                    getValueFromEvent={(e) => transformInput(e.target.value)}
                    rules={[
                      // {
                      //   required: true,
                      //   message: "Enter PAN Number",
                      // },
                      // { whitespace: true, message: "PAN Number can not be empty" },
                      {
                        pattern: new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]$/),
                        message: "PAN Number format should be AAAAA1234D ",
                      },

                      // {
                      //   max: 10,
                      //   message: "PAN Number must be less than 10 characters",
                      // },
                      //  { validator: validateSubCategoryNameExists } ,
                    ]}>
                    <Input
                      name="pancard"
                      autoComplete="off"
                      placeholder="Enter PAN Number"
                      maxLength={10}
                      // onChange={chandleChange}
                      // onKeyUp={convertToUpperCase}

                      ref={mypancardRef}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <div style={{ textAlign: "center" }}>
                    <div>
                      {userProfileData?.user_profile?.pan_image?.image_url && (
                        <img src={userProfileData?.user_profile?.pan_image?.image_url} width={100} height={100} />
                      )}
                    </div>

                    <div style={{ marginTop: "19px" }}>
                      <label for="file-upload-pan" class="custom-file-upload">
                        {selectedInterfaceLanguage && (
                          <TranslateWord
                            key={"UPLOAD_PANCARD"}
                            text={"UPLOAD_PANCARD"}
                            lang={selectedInterfaceLanguage}
                          />
                        )}
                      </label>
                      {userProfileData?.user_profile?.pan_image?.image_url && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            onImageRemove(userProfileData?.user_profile?.pan_image?.image_id);
                          }}>
                          <img src={deleteIcon} width={20} height={20} />
                        </span>
                      )}

                      <input
                        id="file-upload-pan"
                        type="file"
                        accept="image/*"
                        onChange={(e) => kycfilechangehandler(e, "Pan")}
                        multiple
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={8} lg={16} xl={16} xxl={16}>
                  {selectedInterfaceLanguage && (
                    <TranslateWord key={"WALLET_KYC"} text={"WALLET_KYC"} lang={selectedInterfaceLanguage} />
                  )}
                </Col>
              </Row>
              <Form.Item>
                <div style={{ marginTop: "20px" }}>
                  <Button type="primary" htmlType="submit" style={{ float: "right" }}>
                    {selectedInterfaceLanguage && (
                      <TranslateWord key={"UPDATE"} text={"UPDATE"} lang={selectedInterfaceLanguage} />
                    )}
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ float: "right" }}
                    className={screens.xs ? "nextbutton-mob" : "nextbutton"}
                    onClick={onKeyNext}
                    disabled={activeKey === "5"}>
                    {selectedInterfaceLanguage && (
                      <TranslateWord key={"NEXT"} text={"NEXT"} lang={selectedInterfaceLanguage} />
                    )}
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ float: "right" }}
                    className={screens.xs ? "previousbutton-mob" : "previousbutton"}
                    onClick={onKeyBack}
                    disabled={activeKey === "3"}>
                    {selectedInterfaceLanguage && (
                      <TranslateWord key={"PREVIOUS"} text={"PREVIOUS"} lang={selectedInterfaceLanguage} />
                    )}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </TabPane>
        <TabPane
          tab={
            selectedInterfaceLanguage && (
              <TranslateWord key={"BANK_DETAILS"} text={"BANK_DETAILS"} lang={selectedInterfaceLanguage} />
            )
          }
          // key="Bank Details"
          key="5">
          <div>
            <h1>
              {selectedInterfaceLanguage && (
                <TranslateWord
                  key={"BANK_DETAILS"}
                  text={"BANK_DETAILS"}
                  lang={selectedInterfaceLanguage}
                  headingStyle={true}
                />
              )}
            </h1>
          </div>
          <div>
            <Form
              form={profileAboutYourSelf}
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={submitProfileBankInfoForm}>
              <Row>
                <Col span={22}>
                  <Row gutter={5}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="bank_account_number"
                        label={
                          selectedInterfaceLanguage && (
                            <TranslateWord
                              key={"ACCOUNT_NUMBER"}
                              text={"ACCOUNT_NUMBER"}
                              lang={selectedInterfaceLanguage}
                            />
                          )
                        }
                        rules={[
                          {
                            required: true,
                            message: "Bank account number is required.",
                          },
                          {
                            pattern: /^\d{25}$/,
                            message: "Account number upto  25 digits.",
                          },
                        ]}>
                        <Input
                          name="bank_account_number"
                          maxLength={25}
                          onBeforeInput={(e) => {
                            if (!/^\d$/.test(e.data)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="bank_ifsc_code"
                        label={
                          selectedInterfaceLanguage && (
                            <TranslateWord key={"IFSC_CODE"} text={"IFSC_CODE"} lang={selectedInterfaceLanguage} />
                          )
                        }
                        rules={[
                          {
                            required: true,
                            message: "IFSC code is required.",
                          },
                          {
                            pattern: /^[A-Z]{4}0[A-Z0-9]{6}$/,
                            message:
                              // "Invalid IFSC code. It must be 11 characters long, starting with 4 letters, followed by '0', and ending with 6 alphanumeric characters.",
                              "Exact length should be 11",
                          },
                        ]}>
                        <Input
                          name="bank_ifsc_code"
                          maxLength={11}
                          onBeforeInput={(e) => {
                            e.target.value = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, "");
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={5}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="bank_name"
                        label={
                          selectedInterfaceLanguage && (
                            <TranslateWord key={"BANKNAME"} text={"BANKNAME"} lang={selectedInterfaceLanguage} />
                          )
                        }
                        rules={[
                          {
                            required: true,
                            message: "Bank name is required",
                          },
                          {
                            pattern: /^[A-Za-z\s\-.,']+$/,
                            message: "Bank name can only include alphabets and special characters",
                          },
                        ]}>
                        <Input
                          name="bank_name"
                          maxLength={50}
                          onKeyPress={(event) => {
                            // Prevent input of numbers
                            if (/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="bank_address"
                        label={
                          selectedInterfaceLanguage && (
                            <TranslateWord
                              key={"BANK_ADDRESS"}
                              text={"BANK_ADDRESS"}
                              lang={selectedInterfaceLanguage}
                            />
                          )
                        }
                        rules={[
                          {
                            required: true,
                            message: "Bank address is required",
                          },
                          {
                            pattern: /^[A-Za-z0-9\s\-.,'#/]+$/,
                            message: "Bank address can only include alpha, numeric, & spl chars",
                          },
                          {
                            max: 250,
                            message: "Bank address cannot exceed 250 characters.",
                          },
                        ]}>
                        <Input name="bank_address" maxLength={250} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={5}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="swift_code"
                        label={
                          selectedInterfaceLanguage && (
                            <TranslateWord key={"SWIFT_CODE"} text={"SWIFT_CODE"} lang={selectedInterfaceLanguage} />
                          )
                        }
                        rules={[
                          {
                            required: true,
                            message: "Swift code is required.",
                          },
                          {
                            pattern: /^[A-Z]{4}0[A-Z0-9]{6}$/,
                            message:
                              // "Invalid IFSC code. It must be 11 characters long, starting with 4 letters, followed by '0', and ending with 6 alphanumeric characters.",
                              "Exact length should be 11",
                          },
                        ]}
                        validateTrigger={["onChange", "onBlur"]}>
                        <Input
                          name="swift_code"
                          maxLength={11}
                          onBeforeInput={(e) => {
                            e.target.value = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, "");
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ float: "right" }}>
                      {selectedInterfaceLanguage && (
                        <TranslateWord key={"UPDATE"} text={"UPDATE"} lang={selectedInterfaceLanguage} />
                      )}
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ float: "right" }}
                      className={screens.xs ? "previousbutton-mob" : "previousbutton"}
                      onClick={onKeyBack}
                      disabled={activeKey === "4"}>
                      {selectedInterfaceLanguage && (
                        <TranslateWord key={"PREVIOUS"} text={"PREVIOUS"} lang={selectedInterfaceLanguage} />
                      )}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </TabPane>
      </Tabs>
      {isOTPModalOpen && (
        <OTP
          openFlag={isOTPModalOpen}
          closeLoginModel={handleCloseLoginModel}
          sendingFrom="profileSettings"
          optType="gmail"
        />
      )}
    </>
  );
};

export default ProfileAboutYourSelf;
