import React, { useEffect, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip, Empty, Badge, Row, Col, Carousel, Grid } from "antd";
import noImgJPG from "../../../imgaes/noImgJPG.jpg";
import videoPlayIcon from "../../../imgaes/videoPlayIcon.svg";
import ReactPlayer from "react-player/lazy";
import CommentsModal from "../../sharedComponents/CommentsModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllBusinessData } from "../../../Store/BusinessSlice";
import LoadingSpinner from "../../sharedComponents/LoadingSpinner";

const { useBreakpoint } = Grid;
const BusinessSection = ({ categoryName, subCategoryName, sectionInterfaceLang }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const [splideAutoScroll, setSplideAutoScroll] = useState(true);
  const [showCommentsDrawer, setShowCommentsDrawer] = useState(false);
  const [storyRecord, setStoryRecord] = useState(null);
  let loggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserDetails"));
  const { userSelectedNewsFeedData } = useSelector((state) => state.profileSettingsList);
  const { data: allBusinessData, status, error, businessSelectedLang } = useSelector((state) => state.businessList);
  const { data: feedLanguageData, selectedfeedLanguage } = useSelector((state) => state.feedLanguageList);
  const { autoPlayStatus } = useSelector((state) => state.autoPlayList);
  const imageStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40px",
    height: "40px",
    zIndex: 666,
  };

  const gettingData = () => {
    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === businessSelectedLang?.name;
    });

    let feedLangPayload = [];
    if (loggedInUserData?.user_id) {
      feedLangPayload = userSelectedNewsFeedData?.filter((item) => item.selected)?.map((item) => item.newsfeed_lang_id);
    } else {
      feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
        return feedL?.newsfeed_lang_id;
      });
    }
    const payload = {
      category_name: businessSelectedLang?.name === "తెలుగు" ? "వాణిజ్యం" : "Business",
      news_feed_lang_ids: feedLangPayload,
      page: 0,
      pageSize: 9,
      sub_category_name: subCategoryName,
      login_user_id: loggedInUserData?.user_id,
    };
    if (!loggedInUserData?.user_id) {
      delete payload.login_user_id;
    }
    if (subCategoryName === "All" || subCategoryName === "అన్ని") {
      delete payload.sub_category_name;
    }
    if (payload?.news_feed_lang_ids?.length) dispatch(getAllBusinessData(payload));
  };
  useEffect(() => {
    gettingData();
  }, [dispatch, businessSelectedLang]);

  useEffect(() => {
    gettingData();
  }, [subCategoryName, selectedfeedLanguage]);

  if (status === "loading") {
    return (
      <div>
        {" "}
        <LoadingSpinner />
      </div>
    );
  }

  const goToSourceWithId = (id) => {
    navigate(`/about?param1=${id}&category=${categoryName}&subcategory=${subCategoryName}`);
    window.scrollTo(0, 0);
  };

  const goToSourceWitAuthorId = (input_user_id) => {
    navigate(`/GotoSource?param1=${input_user_id}&activeTab=${"Latest"}`);
    window.scrollTo(0, 0);
  };
  const navigateTo = (rec) => {
    navigate(`/about?param1=${rec.id}&category=${categoryName}&subcategory=${subCategoryName}`);
  };
  const handleCloseDrawer = () => {
    setShowCommentsDrawer(false);
    setStoryRecord(null);
  };
  return (
    <>
      {status === "idle" && allBusinessData?.data?.length === 0 ? (
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            marginTop: "100px",
            marginBottom: "70px",
          }}>
          <Empty />
        </div>
      ) : (
        <>
          {status === "idle" && (
            <>
              <Row gutter={screens.xs ? [30, 30] : [16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Carousel arrows infinite={false} dots={true} autoplay={autoPlayStatus} className="custom-carousel">
                    {allBusinessData?.data?.slice(1, 6)?.map((record) => {
                      const originalDate = new Date(record?.pubDate);
                      const currentDate = new Date();
                      const timeDifference = currentDate - originalDate;
                      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
                      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

                      let displayTime;
                      if (timeDifference < 24 * 60 * 60 * 1000) {
                        displayTime = hours > 0 ? `${hours} hrs ago` : minutes > 0 ? `${minutes} min ago` : "Just now";
                      } else {
                        displayTime = originalDate.toLocaleDateString("en-GB");
                      }

                      return (
                        <>
                          <div className="item">
                            <div className="img-overlay-69 img-scale-animate main_slider_tabs  main_slider_all_politics">
                              <Link
                                to={`/about?param1=${record?.id}&category=${categoryName}&subcategory=${subCategoryName}`}
                                onClick={() => {
                                  window.scrollTo(0, 0);
                                }}>
                                {record?.image_urls?.[0] ? (
                                  <img
                                    src={record?.image_urls?.[0]}
                                    alt="news"
                                    style={{
                                      display: "block",
                                      margin: "0 auto",
                                      maxWidth: "100%",
                                      maxHeight: "auto",
                                      objectFit: "contain",
                                      backgroundColor: "#000000",
                                    }}
                                  />
                                ) : record?.video_url && record?.video_thumbnail_url ? (
                                  <div style={{ position: "relative", display: "inline-block" }}>
                                    <img
                                      src={record?.video_thumbnail_url}
                                      alt="video_Thumbnail missed"
                                      style={{
                                        display: "block",
                                        margin: "0 auto",
                                        maxWidth: "100%",
                                        maxHeight: "auto",
                                        objectFit: "contain",
                                        backgroundColor: "#000000",
                                      }}
                                    />
                                    <span
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        zIndex: 2,
                                      }}>
                                      <img
                                        src={videoPlayIcon}
                                        alt="Play Icon"
                                        style={{ width: "50px", height: "50px" }}
                                      />
                                    </span>
                                  </div>
                                ) : (
                                  <>
                                    <img src={noImgJPG} alt="No-Image" />
                                  </>
                                )}
                              </Link>
                            </div>
                            <div
                              className="carousel_image_title"
                              onClick={() => {
                                window.scrollTo(0, 0);
                              }}>
                              <h2 className="title-medium-light size-lg">
                                <Tooltip
                                  title={record?.title
                                    .replace(/<[^>]*>/g, "")
                                    .replace(/&nbsp;|&emsp;/g, "")
                                    .trim()}
                                  color="gray">
                                  <strong
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      navigateTo(record);
                                    }}
                                    className={`hoverTitle ${
                                      record?.language === "తెలుగు" ? "telugu-text-title" : "english-text-title"
                                    }`}>
                                    {record?.title
                                      .replace(/<[^>]*>/g, "")
                                      .replace(/&nbsp;|&emsp;/g, "")
                                      .trim()?.length > 60
                                      ? record?.title
                                          .replace(/<[^>]*>/g, "")
                                          .replace(/&nbsp;|&emsp;/g, "")
                                          .trim()
                                          ?.substring(0, 60) + "..."
                                      : record?.title
                                          .replace(/<[^>]*>/g, "")
                                          .replace(/&nbsp;|&emsp;/g, "")
                                          .trim()}
                                  </strong>
                                </Tooltip>
                              </h2>
                              <div className="post-date-light" style={{ marignTop: "10px" }}>
                                <ul style={{ position: "relative", top: "1vh" }}>
                                  <li>
                                    <span>
                                      <i className="fa fa-user" aria-hidden="true"></i>
                                    </span>
                                    <Link to={`/GotoSource?param1=${record?.user_id}&activeTab=${"Latest"}`}>
                                      {record?.author}
                                      <strong className="duration_slider_main">{displayTime}</strong>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </Carousel>
                  {showCommentsDrawer && (
                    <CommentsModal
                      showDrawer={showCommentsDrawer}
                      closeDrawer={handleCloseDrawer}
                      story={storyRecord}
                    />
                  )}
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Splide
                    options={{
                      rewind: true,
                      autoplay: autoPlayStatus,
                      gap: "1rem",
                      direction: "ttb",
                      height: "48rem",
                      type: allBusinessData?.data?.length <= 3 ? "slide" : "slide",
                      drag: "free",
                      perPage: screens.xs ? 4 : 5,
                      autoScroll: {
                        pauseOnHover: true,
                        pauseOnFocus: true,
                        rewind: false,
                        speed: 1,
                        enabled: splideAutoScroll,
                      },
                    }}>
                    <>
                      {allBusinessData?.data?.map((record) => {
                        const originalDate = new Date(record?.pubDate);
                        const currentDate = new Date();
                        const timeDifference = currentDate - originalDate;
                        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
                        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

                        let displayTime;
                        if (timeDifference < 24 * 60 * 60 * 1000) {
                          displayTime =
                            hours > 0 ? `${hours} hrs ago` : minutes > 0 ? `${minutes} min ago` : "Just now";
                        } else {
                          displayTime = originalDate.toLocaleDateString("en-GB");
                        }

                        return (
                          <SplideSlide>
                            <div className="item tiles_slider">
                              <div className="media" style={{ cursor: "pointer", marginBottom: "-32px" }}>
                                <div
                                  className="width38-lg width40-md img-opacity-hover"
                                  onClick={() => {
                                    goToSourceWithId(record?.id);
                                  }}>
                                  {record?.image_urls?.[0] ? (
                                    <img
                                      src={record?.image_urls?.[0]}
                                      alt="news"
                                      style={{
                                        display: "block",
                                        margin: "0 auto",
                                        width: "150px",
                                        height: "85px",
                                        objectFit: "contain",
                                        borderRadius: "8%",
                                      }}
                                    />
                                  ) : record?.video_url && record?.video_thumbnail_url ? (
                                    <>
                                      <span>
                                        <img style={imageStyle} src={videoPlayIcon} />
                                      </span>{" "}
                                      <img
                                        src={record?.video_thumbnail_url}
                                        alt="news"
                                        style={{
                                          display: "block",
                                          margin: "0 auto",
                                          width: "150px",
                                          height: "85px",
                                          objectFit: "cover",
                                          borderRadius: "8%",
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src={noImgJPG}
                                        alt="No-Image"
                                        style={{
                                          display: "block",
                                          margin: "0 auto",
                                          width: "150px",
                                          height: "85px",
                                          objectFit: "contain",
                                          borderRadius: "8%",
                                        }}
                                      />
                                    </>
                                  )}
                                </div>
                                <div className="media-body">
                                  <h3
                                    className={`title-medium-dark size-md mb-none ${
                                      record?.language === "తెలుగు" ? "telugu-text" : "english-text"
                                    }`}
                                    onClick={() => {
                                      goToSourceWithId(record?.id);
                                    }}>
                                    {record?.title
                                      .replace(/<[^>]*>/g, "")
                                      .replace(/&nbsp;|&emsp;/g, "")
                                      .trim()}
                                  </h3>
                                  <Row>
                                    <Col span={screens.xs ? 12 : 16}>
                                      <li
                                        key={record?.user_id}
                                        onClick={() => {
                                          goToSourceWitAuthorId(record?.user_id);
                                        }}>
                                        <span>
                                          <i className="fa fa-user" aria-hidden="true"></i>
                                        </span>
                                        &nbsp;
                                        <Tooltip title={record?.author} color="gray">
                                          {record?.author?.length > (screens.xs ? 5 : 15)
                                            ? record?.author?.substring(0, screens.xs ? 5 : 15) + "..."
                                            : record?.author}
                                        </Tooltip>
                                      </li>
                                    </Col>
                                    <Col span={screens.xs ? 12 : 8}>
                                      <span style={{ fontSize: "10px", fontStyle: "normal" }}>{displayTime}</span>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </SplideSlide>
                        );
                      })}
                    </>
                  </Splide>
                  {showCommentsDrawer && (
                    <CommentsModal
                      showDrawer={showCommentsDrawer}
                      closeDrawer={handleCloseDrawer}
                      story={storyRecord}
                    />
                  )}
                </Col>
              </Row>
              {screens.xs && (
                <>
                  <Row gutter={[16, 16]} style={{ height: "45px" }}>
                    <Col span={20}></Col>
                  </Row>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default BusinessSection;
