import React, { useEffect, useState } from "react";
import { Tabs, Menu, Dropdown, Button, Select, Skeleton, Grid, Row, Col } from "antd";

// import { useGetAllActiveCategoryQuery } from "../../Services/CategoryAPI";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import TranslateWord from "../../sharedComponents/TranslateWord";
// import TechnologySection from "./TechnologySection";
import LivetvSection from "./LivetvSection";
// import { getAllTechnologyData, selectedTechnologyInterfaceAction } from "../../../Store/TechnologySlice";
import { getAllLivetvData, selectedLivetvInterfaceAction } from "../../../Store/LivetvSlice";
import { Color } from "antd/es/color-picker";

const { useBreakpoint } = Grid;
const LivetvSectionDB = ({ sectionType }) => {
  const dispatch = useDispatch();
  const { data: allCategoriesList } = useSelector((state) => state.categoriesList);
  const { data: interfaceLanguagesData, selectedInterfaceLanguage } = useSelector(
    (state) => state.interfaceLanguageList
  );
  const { data: feedLanguageData } = useSelector((state) => state.feedLanguageList);
  const screens = useBreakpoint();
  // const {
  //   data: allTechnologyData,
  //   status,
  //   error,
  //   technologySelectedLang,
  // } = useSelector((state) => state.technologyList);
  // console.log("technologySelectedLang", technologySelectedLang);
  const { data: allLivetvData, status, error, livetvSelectedLang } = useSelector((state) => state.livetvList);
  const { politicsSelectedLang } = useSelector((state) => state.politicsList);
  const LivetvTitle = livetvSelectedLang?.name === "తెలుగు" ? "లైవ్ టివి" : "Live TV";
  const ALLWORD = livetvSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
  const trendingLives = livetvSelectedLang?.name === "తెలుగు" ? "ట్రెండింగ్ లైవ్స్" : "Trending Lives";
  const politikosLives = livetvSelectedLang?.name === "తెలుగు" ? "పాలిటికోస్  లైవ్" : "Politikos Live";
  const { TabPane } = Tabs;
  const [activeKey, setActiveKey] = useState(ALLWORD);
  const [isShowMore, setisShowMore] = useState(false);
  const [allSubCategoriesList, setAllSubCategoriesList] = useState([]);
  const [selectedLang, setSelectedLang] = useState(livetvSelectedLang?.interface_lang_id);
  const [selectedCategory, setSelectedCategory] = useState(ALLWORD);
  const [rfsh, setRfsh] = useState(false);
  const liveTvCategories = allCategoriesList.filter(
    (allCat) => allCat?.interface_language?.name === livetvSelectedLang?.name
  );
  liveTvCategories.splice(
    0,
    0,
    { category_name: ALLWORD },
    { category_name: trendingLives },
    { category_name: politikosLives }
  );
  const { Option } = Select;
  useEffect(() => {
    // setActiveKey(ALLWORD, trendingLives, politikosLives);     //c
    const categoriesForLive = allCategoriesList;
    const selectedCategoriesList = allCategoriesList?.find((item) => item.category_name === selectedCategory);
    const subCategories = selectedCategoriesList?.str_sub_categories_names;
    if (subCategories?.length) setAllSubCategoriesList([ALLWORD, trendingLives, politikosLives, ...subCategories]);
  }, [allCategoriesList, selectedCategory]);
  //  if(politicsLoading === 'loading'){
  //   return <div> <Skeleton
  //   active
  //   title={false}
  //   paragraph={{ rows: 2, width: [1000, 1000] }}
  // /></div>
  // }
  const onChange = (key) => {
    setisShowMore(false);
    setActiveKey(key);
    setSelectedCategory(key);
  };
  //   const handleChange = (value) => {
  //     setisShowMore(true);
  //     setActiveKey(secondArray[value]);
  //   };
  //   let children = [];
  //   for (let i = 0; i < secondArray.length; i++) {
  //     children.push(<Select.Option key={i}>{secondArray[i]}</Select.Option>);
  //   }
  const handleChange = (value) => {
    console.log("value22", value);
    setSelectedLang(value);
    const selectedIngerfaceLang = interfaceLanguagesData?.filter((lang) => {
      if (lang?.interface_lang_id == value) {
        return lang;
      }
    });

    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === selectedIngerfaceLang?.[0]?.name;
    });
    console.log("feedLa", userSelectedFeedLanguages);
    const feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
      return feedL?.newsfeed_lang_id;
    });
    console.log("feedLangPayload", feedLangPayload);

    dispatch(selectedLivetvInterfaceAction(selectedIngerfaceLang?.[0]));
  };
  console.log("ALLWORD", ALLWORD);
  console.log("ALLWORD", activeKey);
  useEffect(() => {
    handleChange(selectedInterfaceLanguage?.interface_lang_id);
  }, [selectedInterfaceLanguage]);

  useEffect(() => {
    setActiveKey(ALLWORD);
    setSelectedCategory(ALLWORD);
  }, [livetvSelectedLang]);

  return (
    <>
      <div
        style={{ position: "relative", top: "10px" }}
        className={
          livetvSelectedLang?.name === "తెలుగు" ? "custom-politics-tabs-telugu" : "custom-politics-tabs-english"
        }>
        <Row justify={"space-between"}>
          <Col span={2}>
            <div className="heading headig_technology_livetv politics_heading">
              <h1> {<TranslateWord key={"LIVETV"} text={"LIVETV"} headingStyle={true} lang={livetvSelectedLang} />}</h1>
            </div>
          </Col>
          <Col span={screens.xs ? 10 :8}>
          <div style={{ textAlign:'end',marginTop: livetvSelectedLang?.name === "తెలుగు" ? "-2%" : "0px"}}>
            <span  className="viewall_style">
                <Link to={`Livetv`}>
                  {<TranslateWord key={"VIEWALL"} text={"VIEWALL"} headingStyle={false} lang={livetvSelectedLang} />}
                </Link>
              </span>
           
              &emsp; &emsp;
              {livetvSelectedLang && (
              <Select size="small" className="hide-on-small feedDropdown" value={selectedLang} onChange={handleChange}>
                  {interfaceLanguagesData?.map((lang) => {
                    return (
                      <>
                        <Select.Option value={lang?.interface_lang_id}>{lang?.name}</Select.Option>
                      </>
                    );
                  })}
                </Select>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Tabs
              activeKey={activeKey}
              onChange={onChange}
              className={screens.xs ? "custom-tabs-container" : ""}
              moreIcon={
                <span style={{ display: "flex" }}>
                  <TranslateWord key={"MORE"} text={"MORE"} headingStyle={false} lang={livetvSelectedLang} />
                  &#11206;
                </span>
              }
              // className={screens.xs ? "custom-tabs-container" : "custom-tabs-container-livetv"}
            >
              {liveTvCategories?.map((tabrecord, index) => {
                const tabKey = tabrecord.category_name;
                const isActive = activeKey === tabKey;
                return (
                  <>
                    <TabPane
                      tab={
                        <span
                          style={{
                            borderBottom: isActive ? "2px solid #10BAC8" : "none",
                            fontWeight: isActive ? "700" : "400",
                          }}
                          className={livetvSelectedLang?.name === "తెలుగు" ? "telugu-text" : "english-text"}>
                          {tabKey}
                        </span>
                      }
                      key={tabKey}
                      style={{ backgroundColor: "black" }}>
                      <LivetvSection
                        key={index + tabKey}
                        subCategoryName={activeKey}
                        categoryName={selectedCategory}
                        sectionInterfaceLang={livetvSelectedLang}
                      />
                    </TabPane>
                  </>
                );
              })}
            </Tabs>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LivetvSectionDB;
