import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { Avatar, Button, Col, Empty, Row, Tooltip, Grid, Tag, Divider, message, Modal } from "antd";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "@splidejs/react-splide/css";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import defaultChannel from "../imgaes/defaultChannel.svg";
import noImgJPG from "../imgaes/noImgJPG.jpg";
import videoPlayIcon from "../imgaes/videoPlayIcon.svg";
import CommentsModal from "./sharedComponents/CommentsModal";
import BaseUrl from "../utils/config";
import axios from "axios";
import { isEmpty } from "lodash";

import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  TelegramShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TumblrShareButton,
  TwitterShareButton,
  LinkedinIcon,
  PinterestIcon,
  XIcon,
  TumblrIcon,
  FacebookIcon,
  TelegramIcon,
  FacebookMessengerIcon,
  WhatsappShareButton,
} from "react-share";
import { shareStoryUrl } from "../utils/config";
import { addBookmarkLivetv, removeBookmarkLivetv } from "../Store/BookmarkedLivetvSlice";
import {
  gotoSourceChannelData,
  gotoChannelMoreData,
  getSuggestedData,
  getAllChannelsData,
} from "../Store/ChannelsSlice";
import ChannelsSectionDB from "./Sections/ChannelsSection/ChannelsSectionDB";
import TranslateWord from "./sharedComponents/TranslateWord";

const SourceChannel = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const queryParams = new URLSearchParams(location.search);
  const {
    sourceData: sourceChannelData,
    channelMoreData: channelTabData,
    suggestedAllData: suggestedSectionData,
    status,
    error,
    channelsSelectedLang,
  } = useSelector((state) => state.channelsList);
  const channelData = sourceChannelData?.data?.source_list;
  console.log("channelData", channelData);
  const { selectedInterfaceLanguage } = useSelector((state) => state.interfaceLanguageList);
  const { autoPlayStatus } = useSelector((state) => state.autoPlayList);
  const { userSelectedNewsFeedData } = useSelector((state) => state.profileSettingsList);
  const [showCommentsDrawer, setShowCommentsDrawer] = useState(false);
  const [showSuggestionList, setShowSuggestionList] = useState(false);
  const [storyRecord, setStoryRecord] = useState(null);
  const channel_id = queryParams.get("channel_id");
  const [selectedRecord, setSelectedRecord] = useState(sourceChannelData?.data?.latest_video_info);
  // const [selectedTab, setselectedTab] = useState("Latest");
  const [selectedTab, setSelectedTab] = useState("Now Playing");
  const [selectedChannelId, setSelectedChannelId] = useState(channel_id);
  const [isBookmark, setIsBookmark] = useState(false);

  // console.log("selectedChannel", selectedChannel);
  const [followsChannel, setFollowsChannel] = useState(sourceChannelData?.is_login_user_follows_channel);
  const [selectedShareInstance, setSelectedShareInstance] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  let loggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserDetails"));
  const handleCloseDrawer = () => {
    setShowCommentsDrawer(false);
    setStoryRecord(null);
  };
  useEffect(() => {
    const payload = {
      page: 0,
      pageSize: 10,
      source_id: channel_id,
      // login_user_id: loggedInUserData.user_id ? loggedInUserData.user_id : null,
      login_user_id: loggedInUserData?.user_id,
      // login_user_id: 236,
    };
    dispatch(gotoSourceChannelData(payload));
  }, [channel_id, followsChannel, isBookmark]);
  useEffect(() => {
    const payload = {
      page: 0,
      pageSize: 10,
      source_id: channel_id,
      login_user_id: loggedInUserData?.user_id,
      tab_name: selectedTab,
    };
    dispatch(gotoChannelMoreData(payload));
  }, [selectedTab, channel_id]);
  useEffect(() => {
    setSelectedRecord(sourceChannelData?.data?.latest_video_info);
  }, [sourceChannelData]);
  useEffect(() => {
    if (showSuggestionList) {
      const payload = {
        page: 0,
        pageSize: 10,
        login_user_id: loggedInUserData?.user_id,
        livetv_management_id: selectedRecord?.livetv_management_id,
      };
      dispatch(getSuggestedData(payload));
    }
  }, [showSuggestionList]);
  const bookmark = channelsSelectedLang?.name === "తెలుగు" ? "బుక్ మార్క్" : "Bookmark";
  const share = channelsSelectedLang?.name === "తెలుగు" ? "షేర్" : "Share";
  const block = channelsSelectedLang?.name === "తెలుగు" ? "బ్లాక్" : "Block";
  const comments = channelsSelectedLang?.name === "తెలుగు" ? "వ్యాఖ్యలు" : "Comments";
  const follow = channelsSelectedLang?.name === "తెలుగు" ? "అనుసరించండి" : "Follow";
  const unfollow = channelsSelectedLang?.name === "తెలుగు" ? "అనుసరించవద్దు" : "Unfollow";
  const suggestedlives = channelsSelectedLang?.name === "తెలుగు" ? "సూచించిన లైవ్‌లు" : "Suggested Lives";
  const imageStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40px",
    height: "40px",
    zIndex: 666,
  };
  const clickedRecord = (record) => {
    setSelectedRecord(record);
  };
  console.log("selectedRecord---", selectedRecord);

  const gotoChannelsource = (id) => {
    navigate(`/Channel?channel_id=${id}`);
    window.scrollTo(0, 0);
    setSelectedChannelId(id);
  };
  const handleButtonClick = (tabName) => {
    setSelectedTab(tabName);
  };

  const { data: feedLanguageData, selectedfeedLanguage } = useSelector((state) => state.feedLanguageList);

  // const gettingData = () => {
  //   const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
  //     console.log("feed", feed);
  //     return feed?.name === channelsSelectedLang?.name;
  //   });
  //   console.log("feedLa", userSelectedFeedLanguages);

  //   let feedLangPayload = [];
  //   if (loggedInUserData?.user_id) {
  //     feedLangPayload = userSelectedNewsFeedData?.filter((item) => item.selected)?.map((item) => item.newsfeed_lang_id);
  //   } else {
  //     feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
  //       return feedL?.newsfeed_lang_id;
  //     });
  //   }

  //   const payload = {
  //     page: 0,
  //     pageSize: 10,
  //     source_id: channel_id,
  //     login_user_id: loggedInUserData?.user_id,
  //     tab_name: selectedTab,
  //   };
  //   if (!loggedInUserData?.user_id) {
  //     delete payload.login_user_id;
  //   }
  //   // if (subCategoryName === "All" || subCategoryName === "అన్ని") {
  //   //   delete payload.sub_category_name;
  //   // }
  //   // if (payload?.news_feed_lang_ids?.length)
  //   dispatch(getAllChannelsData(payload));
  // };

  // useEffect(() => {
  //   gettingData();
  // }, [dispatch, channelsSelectedLang, selectedfeedLanguage]);

  const followChannel = (selectedRecord) => {
    if (!loggedInUserData?.user_id) {
      message.warning({
        content: "Please Login",
        duration: 5,
        style: {
          marginTop: "20px",
          marginBottom: "20px",
        },
      });
      return;
    }
    const payload = {
      livetv_id: selectedRecord?.source_id,
      login_user_id: loggedInUserData?.user_id,
      // login_user_id: 236,
    };

    axios
      .post(`${BaseUrl}/livetv/follow`, payload)
      .then((response) => {
        if (response.status === 200) {
          // window.location.reload();
          // gettingData();
          setFollowsChannel(true);
          return response.data;
        }
      })
      .catch((error) => console.log(error));
    // gettingData();
    // setFollow(true);
  };

  const unFollowChannel = (selectedRecord) => {
    const payload = {
      livetv_id: selectedRecord?.source_id,
      login_user_id: loggedInUserData?.user_id,
      // login_user_id: 236,
    };

    axios
      .post(`${BaseUrl}/livetv/unfollow`, payload)
      .then((response) => {
        if (response.status === 200) {
          // window.location.reload();
          // gettingData();
          setFollowsChannel(false);
          // return response.data;
        }
      })
      .catch((error) => console.log(error));
    // gettingData();
  };
  const handleShowHideSuggestionList = () => {
    setShowSuggestionList(!showSuggestionList);
  };

  const handleBookmarkActions = (bookMarkLivetvId, isUpdate) => {
    // console.log("1234", bookMarkLivetvId, isUpdate);
    if (isUpdate) {
      removeBookmarkToLiveTV(bookMarkLivetvId);
    } else {
      addBookmarkToLivetv(bookMarkLivetvId);
    }
  };

  const addBookmarkToLivetv = async (bookMarkLivetvId) => {
    if (!loggedInUserData?.user_id) {
      message.warning({
        content: "Please Login",
        duration: 5,
        style: {
          marginTop: "20px",
          marginBottom: "20px",
        },
      });
      return;
    }
    const payload = {
      login_user_id: loggedInUserData?.user_id,
      // login_user_id: 236,
      livetv_management_id: bookMarkLivetvId ?? 0,
    };
    dispatch(addBookmarkLivetv(payload)).then((action) => {
      if (addBookmarkLivetv.fulfilled.match(action)) {
        message.success({
          content: "Bookmarked successfully",
          duration: 5,
          style: {
            marginTop: "20px",
            marginBottom: "20px",
          },
        });
        setIsBookmark(!isBookmark);
        // sendLatestData();
        // gettingData();
      }
    });
  };

  const removeBookmarkToLiveTV = async (bookMarkLivetvId) => {
    // const userId = loggedInUserData?.user_id;
    // const storyId = bookMarkStoryId ?? 0;
    const login_user_id = loggedInUserData?.user_id;
    // const login_user_id = 236;
    const livetv_management_id = bookMarkLivetvId ?? 0;
    dispatch(removeBookmarkLivetv({ userId: login_user_id, liveTvManagmentId: livetv_management_id })).then(
      (action) => {
        if (removeBookmarkLivetv.fulfilled.match(action)) {
          message.success({
            content: "Removed Bookmark successfully",
            duration: 5,
            style: {
              marginTop: "20px",
              marginBottom: "20px",
            },
          });
          setIsBookmark(!isBookmark);
          // sendLatestData();
          // gettingData();
        }
      }
    );
  };
  const handleShare = () => {
    setIsModalVisible(!isModalVisible);
  };
  const updateShareInstance = (record) => {
    setSelectedShareInstance(record);
    handleShare();
  };

  return (
    <>
      <div className="breadcrumbs-area" style={{ marginTop: "16px", marginBottom: "16px" }}>
        <div className="breadcrumbs-content">
          <ul>
            <li onClick={() => navigate(`/`)} style={{ cursor: "pointer" }}>
              {/* Home /{" "} */}
              {selectedInterfaceLanguage && (
                <TranslateWord key={"home"} text={"HOME"} lang={selectedInterfaceLanguage} headingStyle={false}/>
              )}
              /{" "}
            </li>
            {showSuggestionList && (
              <li onClick={handleShowHideSuggestionList} style={{ cursor: "pointer" }}>
                Back{" "}
              </li>
            )}
          </ul>
          {/* <ChannelsSectionDB /> */}
        </div>
      </div>
      <div style={{ marginLeft: "8px", marginBottom: "20px" }}>
        {status === "idle" && isEmpty(selectedRecord) ? (
          <div className="item" style={{ paddingTop: "20px", paddingBottom: "10px" }}>
            <div className="img-overlay-69 img-scale-animate main_slider_tabs  main_slider_all_politics">
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "center",
                  marginTop: "100px",
                  marginBottom: "70px",
                }}>
                <Empty />
                <h4 style={{ color: "white" }}>No data </h4>
              </div>
            </div>
          </div>
        ) : (
          <>
            {status === "idle" && (
              <>
                <div
                  className="source-page-channels"
                  style={{
                    display: "flex",
                    backgroundColor: "black",
                    marginLeft: "-8px",
                    marginRight: "-8px",
                    flexDirection: "row",
                    overflowX: "auto",
                    borderBottom: "2px solid #444",
                  }}>
                  {channelData?.map((channel, index) => (
                    <div key={index} style={{ textAlign: "center" }}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Avatar
                          onClick={() => {
                            gotoChannelsource(channel?.source_id);
                          }}
                          src={channel?.source_name_image_url}
                          alt={`${channel?.source_name} logo`}
                          className="w-full h-full object-contain"
                          style={{
                            height: "75px",
                            width: "75px",
                            marginLeft: "30px",
                            marginRight: "30px",
                            marginTop: "20px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      <span
                        className="text-xs font-medium text-center whitespace-nowrap"
                        style={{
                          color: "white",
                          textDecoration: parseInt(selectedChannelId) === channel.source_id ? "underline" : "none",
                          textDecorationColor:
                            parseInt(selectedChannelId) === channel.source_id ? "red" : "transparent",
                          textDecorationThickness: parseInt(selectedChannelId) === channel.source_id ? "3px" : "auto",
                          cursor: "pointer",
                          // borderBottomWidth: "2px",
                          // borderBottomWidth: "red",
                          // borderBottomWidth: "solid",
                        }}>
                        {channel.source_display_name}
                      </span>
                    </div>
                  ))}
                </div>
                <Row
                  gutter={[16, 16]}
                  style={{ backgroundColor: "black", paddingTop: "35px", height: !screens.xs ? "670px" : "auto" }}>
                  <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
                    <div
                      style={{
                        height: !screens.xs ? "75%" : "auto",
                      }}>
                      <ReactPlayer
                        width={"100%"}
                        height={"100%"}
                        muted={true}
                        controls={true}
                        url={selectedRecord?.video_link}
                      />

                      <div
                        className="carousel_image_title"
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                        style={{
                          marginLeft: "15px",
                          marginTop: "25px",
                        }}>
                        <h2 className="title-medium-light size-lg">
                          <Tooltip title={selectedRecord?.video_title} color="gray">
                            <strong
                              style={{
                                cursor: "pointer",
                                color: "white",
                              }}
                              onClick={() => {
                                //   navigateTo(selectedRecord?.id);
                              }}
                              className={`hoverTitle ${
                                selectedRecord?.language === "తెలుగు" ? "telugu-text-title" : "english-text-title"
                              }`}>
                              {selectedRecord?.video_title?.length > 50
                                ? selectedRecord?.video_title?.substring(0, 50) + "..."
                                : selectedRecord?.video_title}
                            </strong>
                          </Tooltip>
                          {/* <Link to={`/about?param1=${record?.id}`}>{record?.title}</Link> */}
                        </h2>
                        <div className="livetv-action-buttons">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              position: "relative",
                              marginTop: "10px",
                              marginBottom: "15px",
                            }}>
                            <div>
                              <span>
                                {selectedRecord?.source_name_image_url ? (
                                  <Avatar src={selectedRecord?.source_name_image_url} size={30} alt="img" />
                                ) : (
                                  <Avatar src={defaultChannel} size={30} alt="default Channel" />
                                )}
                              </span>
                              <Link
                                // to={`/GotoSource?param1=${selectedRecord?.source_id}&activeTab=${"Latest"}`}
                                style={{
                                  color: "white",
                                  position: "relative",
                                  top: "3px",
                                  left: "3px",
                                  marginLeft: "8px",
                                  cursor: "pointer",
                                }}>
                                {selectedRecord?.source_name}
                              </Link>
                            </div>
                            <div style={{ marginRight: "15px" }}>
                              {selectedRecord?.isfollow ? (
                                <Button
                                  style={{
                                    backgroundColor: "white",
                                    color: "black",
                                    borderRadius: "15px",
                                    marginLeft: "20px",
                                  }}
                                  onClick={() => {
                                    unFollowChannel(selectedRecord);
                                  }}>
                                  {unfollow}
                                </Button>
                              ) : (
                                <Button
                                  style={{
                                    backgroundColor: "white",
                                    color: "black",
                                    borderRadius: "15px",
                                    marginLeft: "20px",
                                  }}
                                  onClick={() => {
                                    followChannel(selectedRecord);
                                  }}>
                                  {follow}
                                </Button>
                              )}
                            </div>
                          </div>

                          <div
                            className="channel-bookmark-container"
                            style={{
                              position: "relative",
                              overflowX: "auto",
                              scrollBehavior: "smooth",
                              marginTop: "13px",
                            }}>
                            <Button
                              style={{
                                backgroundColor: "transparent",
                                borderColor: "#FFFFFF",
                                borderRadius: "15px",
                                color: "#FFFFFF",
                                marginRight: "10px",
                                fontFamily: "mallanna",
                                // top: "6px",
                              }}
                              onClick={() => handleBookmarkActions(selectedRecord?.id, selectedRecord?.isbookmark)}>
                              {selectedRecord?.isbookmark === true ? (
                                <i
                                  class="fa fa-bookmark"
                                  aria-hidden="true"
                                  style={{
                                    fontSize: "20px",
                                    color: "red",
                                  }}></i>
                              ) : (
                                <i
                                  class="fa fa-bookmark-o"
                                  aria-hidden="true"
                                  style={{
                                    fontSize: "20px",
                                    color: "#FFFFFF",
                                  }}></i>
                              )}
                              {bookmark}
                            </Button>
                            <Button
                              style={{
                                backgroundColor: "transparent",
                                borderColor: "#FFFFFF",
                                color: "#FFFFFF",
                                borderRadius: "15px",
                                fontWeight: "400",
                                marginRight: "10px",
                                fontFamily: "mallanna",
                              }}
                              onClick={() => updateShareInstance(selectedRecord)}>
                              {" "}
                              <i
                                className="fa fa-share-alt"
                                aria-hidden="true"
                                style={{
                                  fontSize: "20px",
                                  color: "#FFFFFF",
                                }}></i>
                              {share}
                            </Button>
                            <Tooltip title="Comming Soon">
                              <Button
                                style={{
                                  backgroundColor: "transparent",
                                  borderColor: "#FFFFFF",
                                  color: "#FFFFFF",
                                  borderRadius: "15px",
                                  marginRight: "10px",
                                  fontFamily: "mallanna",
                                }}>
                                {" "}
                                <i
                                  className="fa fa-ban"
                                  aria-hidden="true"
                                  style={{
                                    fontSize: "20px",
                                    color: "#FFFFFF",
                                  }}></i>{" "}
                                {block}
                              </Button>
                            </Tooltip>
                            <Tooltip title="Comming Soon">
                              <Button
                                style={{
                                  backgroundColor: "transparent",
                                  borderColor: "#FFFFFF",
                                  color: "#FFFFFF",
                                  borderRadius: "15px",
                                  marginRight: "10px",
                                  padding: "-10px",
                                  fontFamily: "mallanna",
                                }}>
                                {" "}
                                <i
                                  className="fa fa-comments-o"
                                  aria-hidden="true"
                                  style={{
                                    fontSize: "20px",
                                    color: "#FFFFFF",
                                  }}></i>
                                {comments}
                              </Button>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Modal className="share_more_modal" title="Share More" open={isModalVisible} onCancel={handleShare}>
                      <div>
                        <Row gutter={16}>
                          <Col span={12} style={{ marginBottom: "10px" }}>
                            <FacebookShareButton url={`${shareStoryUrl}/about?param1=${selectedShareInstance?.id}`}>
                              <FacebookIcon size={25} round /> Facebook
                            </FacebookShareButton>
                          </Col>
                          <Col span={12} style={{ marginBottom: "10px" }}>
                            <TwitterShareButton
                              url={`${shareStoryUrl}/about?param1=${selectedShareInstance?.id}`}
                              title={selectedShareInstance.video_title}>
                              <XIcon size={25} round />
                              &nbsp;Twitter
                            </TwitterShareButton>
                          </Col>
                          <Col span={12} style={{ marginBottom: "10px" }}>
                            <TelegramShareButton
                              url={`${shareStoryUrl}/about?param1=${selectedShareInstance?.id}`}
                              title={selectedShareInstance.video_title}>
                              <TelegramIcon size={25} round />
                              &nbsp;Telegram
                            </TelegramShareButton>
                          </Col>
                          <Col span={12} style={{ marginBottom: "10px" }}>
                            <LinkedinShareButton url={`${shareStoryUrl}/about?param1=${selectedShareInstance?.id}`}>
                              <LinkedinIcon size={25} round /> Linkedin
                            </LinkedinShareButton>
                          </Col>
                          <Col span={12}>
                            <TumblrShareButton url={`${shareStoryUrl}/about?param1=${selectedShareInstance?.id}`}>
                              <TumblrIcon size={25} round /> Tumblr
                            </TumblrShareButton>
                          </Col>
                        </Row>
                      </div>
                    </Modal>

                    {showCommentsDrawer && (
                      <CommentsModal
                        showDrawer={showCommentsDrawer}
                        closeDrawer={handleCloseDrawer}
                        story={storyRecord}
                      />
                    )}
                  </Col>

                  {!showSuggestionList ? (
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                      <div className="live-uparr-dwnarr">
                        <Splide
                          options={{
                            rewind: true,
                            autoplay: autoPlayStatus,
                            gap: "1rem",
                            // gap: "-85px",          // for small divices gap:-15px
                            direction: "ttb",
                            height: "59rem",
                            // height: "48rem",

                            type: channelTabData?.data?.length <= 3 ? "slide" : "slide",
                            drag: "free",
                            perPage: screens.xs ? 5 : 6,
                            autoScroll: {
                              pauseOnHover: true,
                              pauseOnFocus: true,
                              rewind: false,
                              speed: 1,
                              enabled: true, 
                            },
                          }}>
                          <div
                            className="tab-names"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              color: "white",
                              overflowX: "auto",
                              scrollBehavior: "smooth",
                              paddingTop: "16px",
                              paddingBottom: "10px",
                            }}>
                            <Button
                              onClick={() => handleButtonClick("Now Playing")}
                              style={{
                                backgroundColor: "transparent",
                                borderRadius: "16px",
                                borderColor: "transparent",
                                textDecoration: selectedTab === "Now Playing" ? "underline" : "none",
                                textDecorationColor: selectedTab === "Now Playing" ? "red" : "transparent",
                                color: "white",
                                fontSize: "20px",
                                fontFamily: "Faustina",
                              }}>
                              Now Playing
                            </Button>
                            <Button
                              onClick={() => handleButtonClick("Latest")}
                              style={{
                                backgroundColor: "transparent",
                                borderRadius: "16px",
                                borderColor: "transparent",
                                color: "white",
                                fontSize: "20px",
                                marginLeft: "20px",
                                fontFamily: "Faustina",
                                textDecoration: selectedTab === "Latest" ? "underline" : "none", 
                                textDecorationColor: selectedTab === "Latest" ? "red" : "transparent",
                              }}>
                              Latest
                            </Button>
                            <Button
                              onClick={() => handleButtonClick("Trending")}
                              style={{
                                backgroundColor: "transparent",
                                borderRadius: "16px",
                                borderColor: "transparent",
                                color: "white",
                                fontSize: "20px",
                                marginLeft: "20px",
                                fontFamily: "Faustina",
                                textDecoration: selectedTab === "Trending" ? "underline" : "none", 
                                textDecorationColor: selectedTab === "Trending" ? "red" : "transparent",
                              }}>
                              Trending
                            </Button>
                          </div>
                          {channelTabData.data?.length ? (
                            channelTabData?.data?.map((record) => {
                              return (
                                <SplideSlide style={{ marginLeft: "8px" }}>
                                  <div className="item tiles_slider">
                                    <div
                                      className="media"
                                      style={{ cursor: "pointer" }}
                                      onClick={handleShowHideSuggestionList}>
                                      <div
                                        className="width38-lg width40-md img-opacity-hover"
                                        onClick={() => {
                                          clickedRecord(record);
                                        }}>
                                        {record?.video_link && record?.image_thumb_name_url ? (
                                          <>
                                            <span>
                                              <img style={imageStyle} src={videoPlayIcon} />
                                            </span>{" "}
                                            <img
                                              src={record?.image_thumb_name_url}
                                              onClick={() => {
                                                clickedRecord(record);
                                              }}
                                              alt="news"
                                              style={{
                                                display: "block",
                                                margin: "0 auto",
                                                width: "150px",
                                                height: "85px",
                                                borderRadius: "8%",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              src={noImgJPG}
                                              alt="No-Image"
                                              style={{
                                                display: "block",
                                                margin: "0 auto",
                                                width: "150px",
                                                height: "85px",
                                                borderRadius: "8%",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </>
                                        )}
                                      </div>
                                      <div className="media-body">
                                        <h3
                                          className={`title-medium-dark size-md mb-none ${
                                            record?.language === "తెలుగు" ? "telugu-text" : "english-text"
                                          }`}
                                          onClick={() => {
                                            clickedRecord(record);
                                          }}
                                          style={{ color: "white" }}>
                                          {record?.video_title?.length > 60
                                            ? record?.video_title?.substring(0, 60) + "..."
                                            : record?.video_title}
                                        </h3>
                                        <Row style={{ marginTop: "10px" }}>
                                          <Col span={15}>
                                            <li
                                              key={record?.source_id}
                                              onClick={() => {
                                                clickedRecord(record);
                                              }}>
                                              <span>
                                                <Avatar
                                                  src={record?.source_name_image_url}
                                                  size={30}
                                                  alt="img"
                                                  style={{ verticalAlign: "bottom", height: "30px", width: "30px" }}
                                                />
                                              </span>
                                              &nbsp;
                                            </li>
                                          </Col>
                                          <Col span={7}>
                                            <Button className="live-button">Live</Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                  </div>
                                </SplideSlide>
                              );
                            })
                          ) : (
                            <div
                              style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                textAlign: "center",
                                marginTop: "100px",
                                marginBottom: "70px",
                              }}>
                              <Empty />
                              <h4 style={{ color: "white" }}>No data </h4>
                            </div>
                          )}
                        </Splide>
                      </div>
                      {showCommentsDrawer && (
                        <CommentsModal
                          showDrawer={showCommentsDrawer}
                          closeDrawer={handleCloseDrawer}
                          story={storyRecord}
                        />
                      )}
                    </Col>
                  ) : (
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                      <div className="live-uparr-dwnarr">
                        <Splide
                          options={{
                            rewind: true,
                            autoplay: autoPlayStatus,
                            gap: "1rem",
                            direction: "ttb",
                            height: "59rem",
                            type: channelTabData?.data?.length <= 3 ? "slide" : "slide",
                            drag: "free",
                            perPage: screens.xs ? 5 : 6, //5:6
                            autoScroll: {
                              pauseOnHover: true,
                              pauseOnFocus: true,
                              rewind: false,
                              speed: 1,
                              enabled: true, 
                            },
                          }}>
                          <div
                            className="tab-names"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              color: "white",
                              overflowX: "auto",
                              scrollBehavior: "smooth",
                              paddingTop: "16px",
                              paddingBottom: "10px",
                            }}>
                            <h3
                              style={{
                                color: "white",
                                fontFamily: "Faustina",
                                fontSize: "20px",
                                paddingBottom: "10px",
                                paddingTop: "10px",
                                marginLeft: "8px",
                              }}>
                              {suggestedlives}
                            </h3>
                          </div>
                          {suggestedSectionData.data && suggestedSectionData?.data?.length ? (
                            suggestedSectionData?.data?.map((record) => {
                              return (
                                <SplideSlide style={{ marginLeft: "8px" }}>
                                  <div className="item tiles_slider">
                                    <div
                                      className="media"
                                      style={{ cursor: "pointer" }}
                                      onClick={handleShowHideSuggestionList}>
                                      <div
                                        className="width38-lg width40-md img-opacity-hover"
                                        onClick={() => {
                                          clickedRecord(record);
                                        }}>
                                        {record?.video_link && record?.image_thumb_name_url ? (
                                          <>
                                            <span>
                                              <img style={imageStyle} src={videoPlayIcon} />
                                            </span>{" "}
                                            <img
                                              src={record?.image_thumb_name_url}
                                              onClick={() => {
                                                clickedRecord(record);
                                              }}
                                              alt="news"
                                              style={{
                                                display: "block",
                                                margin: "0 auto",
                                                width: "150px",
                                                height: "85px",
                                                borderRadius: "8%",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              src={noImgJPG}
                                              alt="No-Image"
                                              style={{
                                                display: "block",
                                                margin: "0 auto",
                                                // maxWidth: "100%",
                                                //  maxHeight: "32vh",
                                                width: "150px",
                                                height: "85px",
                                                borderRadius: "8%",
                                                objectFit: "cover",
                                                // backgroundColor:'#000000'
                                              }}
                                            />
                                          </>
                                        )}
                                      </div>
                                      <div className="media-body">
                                        <h3
                                          className={`title-medium-dark size-md mb-none ${
                                            record?.language === "తెలుగు" ? "telugu-text" : "english-text"
                                          }`}
                                          onClick={() => {
                                            clickedRecord(record);
                                          }}
                                          style={{ color: "white" }}>
                                          {record?.video_title?.length > 60
                                            ? record?.video_title?.substring(0, 60) + "..."
                                            : record?.video_title}
                                        </h3>
                                        {/* <p className='duration_slider'> {`${days} days ${hours} hours ago`}</p> */}
                                        <Row>
                                          <Col span={15}>
                                            <li
                                              key={record?.source_id}
                                              onClick={() => {
                                                clickedRecord(record);
                                              }}>
                                              <span>
                                                <Avatar
                                                  src={record?.source_name_image_url}
                                                  style={{ objectFit: "contain" }}
                                                  alt="img"
                                                />
                                                {/* Reason for empty avatar is record?.source_name_image_url is null */}
                                              </span>
                                              &nbsp;
                                              {/* {record?.author.length > 10 ? record?.author.substring(0, 10) + "..." : record?.author}                                          */}
                                            </li>
                                          </Col>
                                          <Col span={7}>
                                            <Button className="live-button">Live</Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                  </div>
                                </SplideSlide>
                              );
                            })
                          ) : (
                            <div
                              style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                textAlign: "center",
                                marginTop: "100px",
                                marginBottom: "70px",
                              }}>
                              <Empty />
                              <h4 style={{ color: "white" }}>
                                {selectedInterfaceLanguage && (
                                  <TranslateWord key={"NODATA"} text={"NODATA"} lang={selectedInterfaceLanguage} />
                                )}{" "}
                              </h4>
                            </div>
                          )}
                        </Splide>
                      </div>
                      {showCommentsDrawer && (
                        <CommentsModal
                          showDrawer={showCommentsDrawer}
                          closeDrawer={handleCloseDrawer}
                          story={storyRecord}
                        />
                      )}
                    </Col>
                  )}
                </Row>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SourceChannel;
