import React, { useEffect, useState } from "react";
import BreakingNewSub from "./BreakingNewSub";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import noImg from "../../../imgaes/noImg.svg";
import noImgJPG from "../../../imgaes/noImgJPG.jpg";

import { Carousel, Col, Row, Skeleton } from "antd";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { teluguFontStyleHeading } from "../../../Translation/Words";
import TranslateWord from "../../sharedComponents/TranslateWord";
import LoadingSpinner from "../../sharedComponents/LoadingSpinner";
import videoPlayIcon from "../../../imgaes/videoPlayIcon.svg";
import { getAllBreakingNewsData } from "../../../Store/breakingNewsSlice";

function BreakingNewsMain() {
   const dispatch = useDispatch();
    let loggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserDetails"));
  const { data: breakingNewsData, status: breakingLoading, error } = useSelector((state) => state.breakingList);
  const { selectedInterfaceLanguage } = useSelector((state) => state.interfaceLanguageList);
  const { autoPlayStatus } = useSelector((state) => state.autoPlayList);
   const { userSelectedNewsFeedData } = useSelector((state) => state.profileSettingsList);
       const { data: feedLanguageData } = useSelector((state) => state.feedLanguageList);
       useEffect(()=>{
      let lang = selectedInterfaceLanguage;
      const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
        return feed?.name === lang?.name;
      });
      let feedLangPayload = [];
      if (loggedInUserData?.user_id) {
        feedLangPayload = userSelectedNewsFeedData?.filter((item) => item.selected)?.map((item) => item.newsfeed_lang_id);
      } else {
        feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
          return feedL?.newsfeed_lang_id;
        });
      }
        const payload = {
              news_feed_lang_ids: feedLangPayload,
              page: 0,
              pageSize: 50,
              login_user_id: loggedInUserData?.user_id,
            };
            dispatch(getAllBreakingNewsData(payload));
    },[])
  if (breakingLoading === "loading") {
    return (
      <div>
        {" "}
        <LoadingSpinner />
      </div>
    );
  }
  const options = {
    items: 1,
    loop: true,
    autoplay: autoPlayStatus,
    autoplayTimeout: 4000,
    animatout: "slidOutUp",
    nav: true,
    dots: false,
    margin: 0,
    autoplayHoverPause: true,
    responsive: {
      1100: {
        items: 1,
      },
      724: {
        items: 1,
      },
      500: {
        items: 1,
      },
      370: {
        items: 1,
        innerWidth: "100%",
        outerWidth: "100%",
      },
    },
  };

    
  const imageStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40px",
    height: "40px",
    zIndex: 666,
  };
  // const breakingNewsArray = ['Breaking', 'Breaking News']
  // const newsData = breakingNewsData?.data?.filter(record =>
  //     breakingNewsArray.some(trend => record.tags.includes(trend))
  // ).slice(0, 6);

  return (
    <>
      {breakingNewsData?.data?.length > 0 && (
        <>
          <section className="section-space-bottom breaking_new">
            <div className="heading headig_red" style={{ marginBottom: "10px" }}>
              <h1 style={selectedInterfaceLanguage?.name === "తెలుగు" ? teluguFontStyleHeading : {}}>
                {selectedInterfaceLanguage && (
                  <TranslateWord
                    key={"BREAKINGNEWS"}
                    text={"BREAKINGNEWS"}
                    headingStyle={true}
                    lang={selectedInterfaceLanguage}
                  />
                )}
              </h1>
            </div>
            <Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0 }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Carousel arrows infinite={false} dots={false} autoplay={autoPlayStatus} className="arrow-position">
                  {breakingNewsData?.data?.slice(0, 6)?.map((record) => {
                    return (
                      <>
                        <div className="item breaking_news_slider" style={{ cursor: "pointer", height: "450px" }}>
                          <div className="img-overlay-70 img-scale-animate">
                            <Link to={`/about?param1=${record?.id}&category=${"Breaking News"}`}>
                              {record?.image_urls?.[0] ? (
                                <img
                                  src={record?.image_urls?.[0]}
                                  alt="news"
                                  style={{
                                    display: "block",
                                    margin: "0 auto",
                                    maxWidth: "100%",
                                    maxHeight: "auto",
                                    objectFit: "contain",
                                    backgroundColor: "#000000",
                                  }}
                                />
                              ) : record?.video_url && record?.video_thumbnail_url ? (
                                <>
                                  <span>
                                    <img style={imageStyle} src={videoPlayIcon} />
                                  </span>{" "}
                                  <img
                                    src={record?.video_thumbnail_url}
                                    alt="news"
                                    style={{
                                      display: "block",
                                      margin: "0 auto",
                                      maxWidth: "100%",
                                      maxHeight: "auto",
                                      objectFit: "contain",
                                      backgroundColor: "#000000",
                                    }}
                                  />
                                  {/* <ReactPlayer
                                      // width="100vw"
                                      //  height="66vh"
                                      // style={{
                                      //   position: "relative",
                                      //   zIndex: 9000,
                                      //   width: "350px",
                                      //   height: "401px !important",
                                      //   backgroundColor: "black",
                                      // }}
                                      muted={true}
                                      controls={true}
                                      url={record?.video_url}
                                    /> */}
                                </>
                              ) : (
                                <>
                                  <img
                                    src={noImgJPG}
                                    alt="No-Image"
                                    style={{
                                      display: "block",
                                      margin: "0 auto",
                                      maxWidth: "100%",
                                      maxHeight: "50%",
                                      objectFit: "contain",
                                      backgroundColor: "#000000",
                                    }}
                                  />
                                </>
                              )}
                            </Link>
                          </div>
                          <div className="mask-content-lg">
                            <h3 className="title-medium-light">
                              <strong
                                className={`hoverTitle ${
                                  record?.language === "తెలుగు" ? "telugu-text-title" : "english-text-title"
                                }`}
                                style={{ padding: "0px 10px" }}>
                                <Link to={`/about?param1=${record?.id}&category=${"Breaking News"}`}>
                                  {record?.title
                                    .replace(/<[^>]*>/g, "")
                                    .replace(/&nbsp;|&emsp;/g, "")
                                    .trim()?.length > 60
                                    ? record?.title
                                        .replace(/<[^>]*>/g, "")
                                        .replace(/&nbsp;|&emsp;/g, "")
                                        .trim()
                                        ?.substring(0, 60) + "..."
                                    : record?.title
                                        .replace(/<[^>]*>/g, "")
                                        .replace(/&nbsp;|&emsp;/g, "")
                                        .trim()}
                                </Link>
                              </strong>
                            </h3>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </Carousel>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <BreakingNewSub breakingNewsData={breakingNewsData?.data} />
              </Col>
            </Row>
          </section>
        </>
      )}
    </>
  );
}

export default BreakingNewsMain;
