import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate } from "react-router-dom";
import { Skeleton } from "antd";
import noImg from "../imgaes/noImg.svg";
import noImgJPG from "../imgaes/noImgJPG.jpg";
import videoPlayIcon from "../imgaes/videoPlayIcon.svg";
import TranslateWord from "./sharedComponents/TranslateWord";
import { teluguFontStyleHeading } from "../Translation/Words";
import { useDispatch, useSelector } from "react-redux";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import ReactDOMServer from "react-dom/server";
import { getAllTrendingStories } from "../Store/trendingSlice";

// let feedLangData = [];
// let selectedIngerface = [];
const Trending = () => {
   const dispatch = useDispatch();
  let loggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserDetails"));
  const { data: trendingData, status: trendingLoading } = useSelector((state) => state.trendingList);
  const { data: interfaceLanguagesData, selectedInterfaceLanguage } = useSelector(
    (state) => state.interfaceLanguageList
  );
   const { userSelectedNewsFeedData } = useSelector((state) => state.profileSettingsList);
     const { data: feedLanguageData } = useSelector((state) => state.feedLanguageList);
  const { autoPlayStatus } = useSelector((state) => state.autoPlayList);

  useEffect(()=>{
    let lang = selectedInterfaceLanguage;
    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === lang?.name;
    });
    let feedLangPayload = [];
    if (loggedInUserData?.user_id) {
      feedLangPayload = userSelectedNewsFeedData?.filter((item) => item.selected)?.map((item) => item.newsfeed_lang_id);
    } else {
      feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
        return feedL?.newsfeed_lang_id;
      });
    }
      const trendingpayload = {
            news_feed_lang_ids: feedLangPayload,
            page: 0,
            pageSize: 50,
            login_user_id: loggedInUserData?.user_id,
          };
          dispatch(getAllTrendingStories(trendingpayload));
  },[])

  const navigate = useNavigate();
  const navIcons = [
    ReactDOMServer.renderToStaticMarkup(
      <LeftOutlined style={{ fontSize: "16.8px", marginLeft: "-10px", fontWeight: 900 }} />
    ),
    ReactDOMServer.renderToStaticMarkup(<RightOutlined style={{ fontSize: "16.8px", marginLeft: "-10px" }} />),
  ];
  const options = {
    items: 1,
    // loop: true,
    loop: trendingData?.data?.length > 4 ? true : false,
    autoplay: autoPlayStatus,
    autoplayTimeout: 4000,
    animatout: "slidOutUp",
    nav: true,
    dots: false,
    margin: 0,
    responsive: {
      1100: {
        items: 4,
      },
      724: {
        items: 3,
      },
      500: {
        items: 1,
      },
      370: {
        items: 1,
        innerWidth: "100%",
        outerWidth: "100%",
      },
    },
    navText: navIcons, // Pass the custom Antd icons
  };

  if (trendingLoading === "loading") {
    return (
      <div>
        {" "}
        <Skeleton active title={false} paragraph={{ rows: 2, width: [1000, 1000] }} />
      </div>
    );
  }
  const imageStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40px",
    height: "40px",
  };

  const navigateToStoryDetails = (record) => {
    const url = `/about?param1=${record?.id}&category=${"Trending"}`;
    navigate(`${url}`);
  };

  console.log("trendingData", trendingData);
  return (
    <>
      {trendingData?.data?.length > 0 && (
        <>
          <div className="trending-section-space-bottom trending_section container">
            <div style={{ marginTop: "10px" }}>
              <div className="heading headig_blue">
                <h1 style={selectedInterfaceLanguage?.name === "తెలుగు" ? teluguFontStyleHeading : {}}>
                  <TranslateWord
                    key={"trending"}
                    text={"TRENDING"}
                    headingStyle={true}
                    lang={selectedInterfaceLanguage}
                  />
                </h1>
              </div>
              <div className="row">
                <OwlCarousel className="owl-theme trending" {...options}>
                  {trendingData?.data?.map((record) => {
                    const originalDate = new Date(record?.pubDate);
                    const currentDate = new Date();
                    const timeDifference = currentDate - originalDate;
                    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
                    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

                    let displayTime;
                    if (timeDifference < 24 * 60 * 60 * 1000) {
                      // Less than 24 hours
                      displayTime = hours > 0 ? `${hours} hrs ago` : minutes > 0 ? `${minutes} min ago` : "Just now";
                    } else {
                      // More than 24 hours
                      displayTime = originalDate.toLocaleDateString("en-GB"); // Format the date as DD-MM-YYYY
                    }

                    return (
                      <>
                        <div className="item">
                          <div className="hover-show-play-btn item-shadow-gray trending_box">
                            <div
                              className="img-overlay-70 trending_img"
                              style={{ cursor: "pointer" }}
                              onClick={() => navigateToStoryDetails(record)}>
                              {record?.image_urls?.[0] ? (
                                <img
                                  src={record?.image_urls?.[0]}
                                  alt="news"
                                  style={{
                                    display: "block",
                                    margin: "0 auto",
                                    // maxWidth: "100%",
                                    // maxHeight: "32vh",
                                    height: "62px",
                                    width: "110px",
                                    objectFit: "contain",
                                    backgroundColor: "#000000",
                                  }}
                                />
                              ) : record?.video_url ? (
                                <>
                                  <span>
                                    <img style={imageStyle} src={videoPlayIcon} />
                                  </span>{" "}
                                  <img
                                    style={{
                                      display: "block",
                                      margin: "0 auto",
                                      height: "62px",
                                      width: "110px",
                                      objectFit: "contain",
                                      backgroundColor: "#000000",
                                    }}
                                    muted
                                    preload="metadata"
                                    src={`${record?.video_url}#t=0.1`}
                                    onClick={() => navigateToStoryDetails(record)}
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    src={noImgJPG}
                                    alt="No-Image"
                                    style={{
                                      display: "block",
                                      margin: "0 auto",
                                      height: "62px",
                                      width: "110px",
                                      objectFit: "contain",
                                      backgroundColor: "#000000",
                                    }}
                                  />
                                </>
                              )}
                            </div>
                            {/* <p><Link to={`/GotoSource?param1=${record?.user_id}`}>{record?.author} </Link></p> */}
                            <div className="trending_text">
                              <h3
                                className={`${
                                  record?.language === "తెలుగు" ? "title-medium-dark-telugu" : "title-medium-dark"
                                }`}>
                                <strong
                                  className={`${
                                    record?.language === "తెలుగు" ? "telugu-text-title" : "english-text-title"
                                  }`}>
                                  <Link to={`/about?param1=${record?.id}&category=${"Trending"}`}>
                                    {" "}
                                    {record?.title
                                      .replace(/<[^>]*>/g, "")
                                      .replace(/&nbsp;|&emsp;/g, "")
                                      .trim()}
                                  </Link>
                                </strong>

                                <p>{displayTime}</p>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </OwlCarousel>
              </div>
            </div>           
          </div>
          <hr></hr>
        </>
      )}
    </>
  );
};

export default Trending;
