import React, { useEffect, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { Link,useNavigate } from 'react-router-dom';
// import { Grid } from '@splidejs/splide-extension-grid';
import noImg from '../../../imgaes/noImg.svg'
import noImgJPG from '../../../imgaes/noImgJPG.jpg'
import videoPlayIcon from '../../../imgaes/videoPlayIcon.svg'
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;
function BreakingNewSub({breakingNewsData}) {
    const [trendingData, setTrendingData] = useState();
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const { selectedInterfaceLanguage } = useSelector((state) => state.interfaceLanguageList);
    const { autoPlayStatus } = useSelector((state) => state.autoPlayList);
    const imageStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40px',
        height: '40px',
        zIndex:10
      };
   
      const gotoAbout = (id) => {
        navigate(`/about?param1=${id}&category=${"Breaking News"}`);
        window.scrollTo(0, 0);
      };
   console.log('Braking',breakingNewsData)
    return (
        <>
            <div className="no-gutters slider_breakingnews">
                <div className="tile_slider">
                    <div className="row tab-space5">
                        <div className="slider_breaking_news">
                            <Splide
                                options={{
                                    type: 'loop',
                                    direction: 'ttb',
                                    autoplay: autoPlayStatus,
                                    height:  screens.xs ? '25rem' :'40rem',                                   
                                    perPage: screens.xs ? 1 :2,
                                    perMove: 1,                                   
                                    autoScroll: {
                                        pauseOnHover: true,
                                        pauseOnFocus: true,
                                        rewind: false,
                                        speed: 1,
                                    }
                                }}
                              //  extensions={{ AutoScroll, Grid }}
                            >
                                <>

                                    {breakingNewsData?.map((record) => {
                                        return (
                                          <SplideSlide className={screens.xs ? 'breaking_news_sub_mobile' :'breaking_news_sub'}>
                                            <div className="img-overlay-70 img-scale-animate br_10 breking_radiues" onClick={() => {gotoAbout(record?.id)}} style={{cursor:"pointer"}}>
                                              <div className="mask-content-sm br_10 ">
                                                <h3 className="title-medium-light">
                                                  <strong className={`${record?.language === "తెలుగు" ? 'telugu-text-title' : 'english-text-title'}`}>
                                                  <Link
                                                    to={`/about?param1=${
                                                      record?.id
                                                    }&category=${"Breaking News"}`}
                                                  >
                                                  {record?.title.replace(/<[^>]*>/g, "").replace(/&nbsp;|&emsp;/g, "").trim()}
                                                  </Link>

                                                  </strong>
                                                 
                                                </h3>
                                              </div>
                                              {/* <img src={record?.image_urls?.[0]} alt="news"
                                                        className="img-fluid width-100 br_10 width-107" /> */}
                                              {
                                                record?.image_urls?.[0] ? (
                                                  <img
                                                    src={
                                                      record?.image_urls?.[0]
                                                    }
                                                    className='breaking-subimage-smallscreen'       // For breaking news sub images
                                                    alt="news"
                                                    style={{
                                                      display: "block", 
                                                      margin: "0 auto", 
                                                      maxWidth: "100%", 
                                                      maxHeight: "32vh", 
                                                      objectFit: "contain", 
                                                      backgroundColor:'#000000'
                                                    }}
                                                  />
                                                ) : (record?.video_url && record?.video_thumbnail_url) ? (
                                                  <>
                                                    <Link
                                                      to={`/about?param1=${
                                                        record?.id
                                                      }&category=${"Breaking News"}`}
                                                    >
                                                      {" "}
                                                      <span>
                                                        <img
                                                          style={imageStyle}
                                                          src={videoPlayIcon}
                                                        />
                                                      </span>{" "}
                                                      <img
                                                        src={record?.video_thumbnail_url}
                                                        alt="news"
                                                        className='breaking-subimage-smallscreen'
                                                        // className='responsive-video'
                                                        style={{
                                                          // width: "310px",                                                                 
                                                          // position: "relative",                                                          
                                                          // top: "-29px",
                                                          // zIndex: "2",
                                                          // backgroundColor:"black",
                                                          display: "block", 
                                                      margin: "0 auto", 
                                                      width: "353.8px", 
                                                      height: "24vh", 
                                                      objectFit: "contain", 
                                                      backgroundColor:'#000000'
                                                        }}
                                                        
                                                      />
                                                      {/* <video                  //   commented
                                                      className='responsive-video'
                                                        style={{
                                                          width: "310px",                                                          
                                                          position: "relative",                                                          
                                                          top: "-29px",
                                                          zIndex: "2",
                                                          backgroundColor:
                                                            "black",
                                                        }}
                                                        preload="metadata"
                                                        src={`${record?.video_url}#t=0.1`}
                                                        muted
                                                      ></video> */}
                                                    </Link>
                                                  </>
                                                ) : (
                                                  <>
                                                    <img
                                                      src={noImgJPG}
                                                      alt="No-Image"
                                                    />
                                                  </>
                                                )
                                                // <img src={noImgJPG} alt="No-Image" className="img-fluid width-100 breking_radiues" />
                                              }
                                            </div>
                                          </SplideSlide>
                                        );
                                    })}

                                </>


                            </Splide>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BreakingNewSub
