import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import noImg from "../imgaes/noImg.svg";
import noImgJPG from "../imgaes/noImgJPG.jpg";
import videoPlayIcon from "../imgaes/videoPlayIcon.svg";
import ReactPlayer from "react-player/lazy";
import { shareStoryUrl } from "../utils/config.js";
import { Avatar, Card, Col, Dropdown, Row, Tag, Tooltip, Grid } from "antd";
import MorEellipsisIcon from "./Sections/Common/MorEellipsisIcon.js";
import whatsappIcon from "../imgaes/whatsappIcon.svg";
import shareIcon from "../imgaes/shareIcon.svg";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import { useDispatch, useSelector } from "react-redux";
import { getStoryDetailsPage } from "../Store/StoryDetailsSlice.js";
import { getAllCategoryData } from "../Store/categoryWiseSlice.js";
import { getAllTrendingStories } from "../Store/trendingSlice.js";
import axios from "axios";
import BaseUrl from "../utils/config.js";

import TranslateWord from "../Components/sharedComponents/TranslateWord.js";
import moment from "moment";
import instagram from "../imgaes/instagram.svg";

let rec = [];
const { useBreakpoint } = Grid;
function VideoDetails() {
  const location = useLocation();
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const queryParams = new URLSearchParams(location.search);
  let storyId;
  storyId = queryParams.get("param1");
  const sharingUrl = `${shareStoryUrl}/about?param1=${storyId}`;
  const categoryType = queryParams.get("category");
  const subcategory = queryParams.get("subcategory");
  const [latestfive, setLatestfive] = useState([]);
  let loggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserDetails"));
  const { userSelectedNewsFeedData } = useSelector((state) => state.profileSettingsList);
  const { data: trendingData, status: trendingLoading } = useSelector((state) => state.trendingList);
  const { data: feedLanguageData } = useSelector((state) => state.feedLanguageList);
  const { selectedInterfaceLanguage } = useSelector((state) => state.interfaceLanguageList);
  const [videoDetailsData, setVideoDetailsData] = useState({});
  const loginUserId = loggedInUserData?.user_id ? loggedInUserData?.user_id : 100;
  console.log("loggedInUserData", loginUserId);
  useEffect(() => {
    axios
      .get(`${BaseUrl}/livetvManagement/${storyId}`)
      .then((response) => {
        if (response.status === 200) {
          setVideoDetailsData(response.data?.data);
          console.log("1199", response.data);
        }
      })
      .catch((err) => console.error(err));
  }, []);
  const {
    data: allCategoryWiseData,
    status: categoryWiseStatus,
    error: categoryWiseError,
  } = useSelector((state) => state.categoryWiseList);
  const navigate = useNavigate();
  useEffect(() => {
    let lang = selectedInterfaceLanguage;
    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === lang?.name;
    });
    let feedLangPayload = [];
    if (loggedInUserData?.user_id) {
      feedLangPayload = userSelectedNewsFeedData?.filter((item) => item.selected)?.map((item) => item.newsfeed_lang_id);
    } else {
      feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
        return feedL?.newsfeed_lang_id;
      });
    }
    const payload = {
      category_name: categoryType,
      news_feed_lang_ids: feedLangPayload,
      page: 0,
      pageSize: 5,
      sub_category_name: subcategory,
      // login_user_id:loggedInUserData?.user_id
    };
    if (categoryType === "Trending Lives") {
      //call trending live post call
      //sample Url : https://api-prod.politikos.cloud/story/publishedcategorylisting
    }
    // dispatch(getLivetvdetailsPage(storyId)); //should call for story
    if (categoryType === "Trending") {
      const trendingpayload = {
        news_feed_lang_ids: feedLangPayload,
        page: 0,
        pageSize: 50,
        login_user_id: loggedInUserData?.user_id,
      };
      dispatch(getAllTrendingStories(trendingpayload));
    } else {
      if (payload?.news_feed_lang_ids?.length) dispatch(getAllCategoryData(payload));
    }
  }, [storyId, feedLanguageData, userSelectedNewsFeedData, selectedInterfaceLanguage]);
  useEffect(() => {
    if (categoryType === "Trending") setLatestfive(trendingData);
    else setLatestfive(allCategoryWiseData);
  }, [allCategoryWiseData, trendingData]);
  const { data: selectedStory, status, error } = useSelector((state) => state.storyDetailsPageList);
  const imageStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40px",
    height: "40px",
  };
  const tagsList = selectedStory?.data?.[0]?.tags;
  const categoryList = [];
  const subCategoryList = [];

  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const extractContent = (fullDescription) => {
    // Decode HTML entities
    let decodedDescription = decodeHtml(fullDescription);
    console.log("decodedDescription" + JSON.stringify(decodedDescription));

    // Replace Tweet URLs with embed code (without script tag)
    // Remove the ref_src query parameter from the anchor tags
    let modifiedDescription = decodedDescription.replace(/\?ref_src=twsrc%5Etfw">[^<]*<\/a>/g, "</a>");

    // Replace Twitter status URLs with the embedded tweet blockquote
    modifiedDescription = modifiedDescription.replace(
      /https:\/\/twitter\.com\/[^\/]+\/status\/(\d+)/g,
      (match, tweetId) =>
        `<blockquote class="twitter-tweet">
        <a href="https://twitter.com/twitter/statuses/${tweetId}"></a>
    </blockquote>
    <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`
    );

    // Replace LinkedIn iframes with their src URL
    modifiedDescription = modifiedDescription.replace(
      /<p>\s*<iframe[^>]*src="([^"]*linkedin\.com\/embed\/feed\/update\/[^"]*)"[^>]*><\/iframe>\s*<\/p>/g,
      (match, url) => `<p>${url}</p>`
    );

    //   modifiedDescription = modifiedDescription.replace(
    //     /https:\/\/www\.linkedin\.com\/embed\/feed\/update\/urn:li:(ugcPost):(\d+)(\?compact=1)?/g,
    //     (match) =>
    //     `<iframe src="${match}" height="550" width="${404}" frameborder="0" allowfullscreen="" scrolling="no"; title="Embedded post"></iframe>`
    // );

    // Replace LinkedIn UGC with specific height and width based on the compact parameter
    modifiedDescription = modifiedDescription.replace(
      /https:\/\/www\.linkedin\.com\/embed\/feed\/update\/urn:li:(ugcPost):(\d+)(\?compact=1)?/g,
      (match, postType, postId, compact) => {
        const height = compact ? 350 : 690;
        const width = compact ? 804 : 404;
        return `<iframe src="${match}" height="${height}" width="${width}" frameborder="0" allowfullscreen="" scrolling="no" title="Embedded post"></iframe>`;
      }
    );

    // Check if there are YouTube iframes and replace the width and height values for all occurrences
    if (modifiedDescription.includes("youtube")) {
      // Use a regex to target all YouTube iframes (including youtube.com and youtube-nocookie.com)
      modifiedDescription = modifiedDescription.replace(
        /<iframe[^>]+src="[^"]*(youtube\.com|youtube-nocookie\.com)[^"]*"[^>]*>/g,
        (match) => match.replace(/width="\d+"/, 'width="68%"').replace(/height="\d+"/, 'height="350"')
      );
    }

    modifiedDescription = modifiedDescription.replace(
      /https:\/\/www\.linkedin\.com\/embed\/feed\/update\/urn:li:share:(\d+)/g,
      (match) =>
        `<iframe src="${match}" height="834" width="404" frameborder="0" allowfullscreen="" scrolling="no"; title="Embedded post"></iframe>`
    );

    return modifiedDescription;
  };

  useEffect(() => {
    if (window.twttr) {
      window.twttr.widgets.load();
    } else {
      const script = document.createElement("script");
      script.src = "https://platform.twitter.com/widgets.js";
      script.async = true;
      script.onload = () => {
        if (window.twttr) {
          window.twttr.widgets.load();
        }
      };
      document.body.appendChild(script);
    }
  }, [extractContent]);
  selectedStory?.data?.[0]?.categories?.forEach((category) => {
    if (!categoryList.includes(category.name)) {
      categoryList.push(category.name);
    }

    category.sub_categories?.forEach((subCategory) => {
      if (!subCategoryList.includes(subCategory)) {
        subCategoryList.push(subCategory);
      }
    });
  });

  const storyData = selectedStory ?? [];
  console.log("storyData", storyData);
  localStorage.setItem("storyData", JSON.stringify(storyData));
  const selectedCategories = ["Politics", "Sports", "Technology", "Entertainment"];

  const eachData = trendingData?.data
    ?.filter((record) => record.categories.some((category) => selectedCategories.includes(category.name)))
    .slice(0, 4);
  console.log("eachData", eachData);
  const getLatestData = () => {
    dispatch(getStoryDetailsPage(storyId));
  };

  const getSelectedStories = (type, selectedType) => {
    navigate(`/SelectedTypeStories?type=${type}&selectedType=${selectedType}`);
  };

  const goToSourceWitAuthorId = (input_user_id) => {
    navigate(`/GotoSource?param1=${input_user_id}&activeTab=${"Latest"}`);
  };
  const gotoWhatsAppGroup = () => {
    window.open("https://whatsapp.com/channel/0029Va50Xpj5vKA5h28WpL0q", "_blank");
  };
  const gotoTelegramGroup = () => {
    window.open("https://t.me/+g0cU7G261WQ4MTRl", "_blank");
  };

  const items = [
    {
      key: "1",
      label: (
        // <TwitterShareButton url={sharingUrl} title={"title"} className="Demo__some-network__share-button">
        //   <XIcon size={25} round />
        //   &nbsp;Twitter
        // </TwitterShareButton>
        <Tooltip title="Coming soon" color="gray">
             <a className="instgram" target="_blank" >
                <img
                    style={{
                            width: "25px",
                            height: "25px",
                            cursor: "pointer",
                            }}
                    src={instagram}
                  />&nbsp;Instagram
              </a>
        </Tooltip>
      ),
    },
    {
      key: "2",
      label: (
        <TelegramShareButton url={sharingUrl} title={"title"} className="Demo__some-network__share-button">
          <TelegramIcon size={25} round />
          &nbsp;Telegram
        </TelegramShareButton>
      ),
    },
    {
      key: "3",
      label: (
        <LinkedinShareButton url={sharingUrl} className="Demo__some-network__share-button">
          <LinkedinIcon size={25} round /> Linkedin
        </LinkedinShareButton>
      ),
    },
    {
      key: "4",
      label: (
        <TumblrShareButton url={sharingUrl} className="Demo__some-network__share-button">
          <TumblrIcon size={25} round /> Tumblr
        </TumblrShareButton>
      ),
    },
  ];

  let convertedDate = moment(videoDetailsData?.created_date, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
  const originalDate = new Date(convertedDate);
  const currentDate = new Date();
  const timeDifference = currentDate - originalDate;
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  let displayTimeLiveTv;
  if (timeDifference < 24 * 60 * 60 * 1000) {
    // Less than 24 hours
    displayTimeLiveTv = hours > 0 ? `${hours} hrs ago` : minutes > 0 ? `${minutes} min ago` : "Just now";
  } else {
    // More than 24 hours
    displayTimeLiveTv = originalDate.toLocaleDateString("en-GB"); // Format the date as DD-MM-YYYY
  }
  return (
    <>
      <section className="bg-body section-space-less30">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12 mb-30">
              <div className="news-details-layout1">
                <div className="breadcrumbs-area">
                  <div className="breadcrumbs-content">
                    <ul>
                      <li onClick={() => navigate(`/`)} style={{ cursor: "pointer" }}>
                        {/* Home /{" "} */}
                        {selectedInterfaceLanguage && (
                          <TranslateWord
                            key={"home"}
                            text={"HOME"}
                            lang={selectedInterfaceLanguage}
                            headingStyle={false}
                          />
                        )}
                        /{" "}
                      </li>
                      <li onClick={() => navigate(`/${categoryType}`)} style={{ cursor: "pointer" }}>
                        {categoryType != undefined && categoryType}{" "}
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="position-relative mb-30 post_img_content">
                  {videoDetailsData?.video_link ? (
                    <>
                      {" "}
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <ReactPlayer
                          muted={true}
                          controls={true}
                          url={videoDetailsData?.video_link}
                          style={{ width: "650px", height: "366px", objectFit: "contain", backgroundColor: "" }}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <img
                        src={noImg}
                        alt="No-Image"
                        style={{ width: "650px", height: "366px", objectFit: "contain", backgroundColor: "" }}
                      />
                    </>
                  )}
                </div>
                <h2 className="title-semibold-dark size-c30" style={{ marginTop: screens.xs ? "-20px" : "-30px" }}>
                  <strong
                    className={`${
                      videoDetailsData && videoDetailsData?.news_feed_language === "తెలుగు"
                        ? "telugu-text-title_lg"
                        : "english-text-title_lg"
                    }`}>
                    {videoDetailsData && videoDetailsData?.video_title}
                  </strong>
                </h2>

                <div className="row responsive-row">
                  <div className="col-lg-8">
                    <ul className="post-info-dark mb-15" style={{ paddingBottom: "-15px" }}>
                      {/* className post-info-dark mb-30 */}
                      <li>
                        <a>
                          <Avatar src={videoDetailsData?.source_name_image_url} style={{ marginRight: "10px" }} />
                          <span
                            onClick={() => {
                              goToSourceWitAuthorId(videoDetailsData?.id);
                            }}>
                            {videoDetailsData?.source_name}
                          </span>
                        </a>
                      </li>
                      <li>
                        <a style={{ cursor: "none" }}>
                          <i className="fa fa-clock-o" aria-hidden="true"></i>
                          {displayTimeLiveTv}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-4" style={{ marginBottom: "13px" }}>
                    <div className="post-share-area item-shadow-1">
                      <ul className={screens.xs ? "social-default pull-left" : "social-default pull-right"}>
                        <li>
                          <WhatsappShareButton
                            url={`${shareStoryUrl}/about?param1=${videoDetailsData?.id}`}
                            className="Demo__some-network__share-button">
                            <img src={whatsappIcon} alt="icon" className="icons_img" width={24} height={24} />
                          </WhatsappShareButton>
                        </li>
                        <li>
                          <FacebookShareButton
                            url={`${shareStoryUrl}/about?param1=${videoDetailsData?.id}`}
                            className="Demo__some-network__share-button">
                            <FacebookIcon size={26} round />
                          </FacebookShareButton>
                        </li>
                        <li>
                          <TwitterShareButton url={sharingUrl} title={"title"} className="Demo__some-network__share-button">
                              <XIcon size={25} round />         
                          </TwitterShareButton>
                        </li>

                        <li>
                          <Dropdown
                            menu={{
                              items,
                            }}
                            placement="bottomRight"
                            // arrow
                          >
                            <img src={shareIcon} alt="icon" className="icons_img" width={25} height={25} />
                          </Dropdown>
                        </li>
                        <li className="more_about">
                          {" "}
                          <MorEellipsisIcon
                            story={videoDetailsData}
                            sendLatestData={getLatestData}
                            page="storyDetails"
                            bookMarkStoryId={videoDetailsData?.id}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div style={{ width: "120px" }}>
                    <TranslateWord
                      key={"CATEGORIES"}
                      text={"CATEGORIES"}
                      lang={selectedInterfaceLanguage}
                      headingStyle={false}
                    />
                    : &nbsp;
                  </div>
                  <div>
                    {videoDetailsData.category_name_list?.map((category) => {
                      return (
                        <Tag
                          color="blue"
                          style={{
                            padding: "5px 15px",
                            borderRadius: "8px",
                            cursor: "pointer",
                            marginBottom: "10px",
                          }}
                          className={`${
                            videoDetailsData?.news_feed_language === "తెలుగు" ? "telugu-text" : "english-text"
                          }`}>
                          {category}
                        </Tag>
                      );
                    })}
                  </div>
                </div>

                <div className="post-share-area politikos_share mt-20">
                  <ul className="social-default">
                    <li className="whatsapp">
                      <span onClick={gotoWhatsAppGroup} style={{ cursor: "pointer" }}>
                        <i className="fa fa-whatsapp" aria-hidden="true"></i>
                        <span>
                          <TranslateWord
                            key={"JOINNOW"}
                            text={"JOINNOW"}
                            lang={selectedInterfaceLanguage}
                            headingStyle={false}
                          />
                        </span>
                      </span>
                    </li>
                    <li className="telegram">
                      <span onClick={gotoTelegramGroup} style={{ cursor: "pointer" }}>
                        <img src="img/imw_img/telegram.png" alt="adv" className="img-fluid" />
                        <span>
                          <TranslateWord
                            key={"JOINNOW"}
                            text={"JOINNOW"}
                            lang={selectedInterfaceLanguage}
                            headingStyle={false}
                          />
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="ne-sidebar sidebar-break-md col-lg-3 col-md-12">
              <div className="sidebar-box">
                <div className="ne-banner-layout1 text-center"></div>
              </div>
              <div className="sidebar-box" style={{ marginTop: "-60px" }}>
                <div className="topic-border color-cod-gray mb-30">
                  <div className="topic-box-lg color-cod-gray">{`Latest from ${categoryType}`}</div>
                </div>
                {latestfive?.data?.slice(0, 5)?.map((record) => {
                  const originalDate = new Date(record?.pubDate);
                  const currentDate = new Date();
                  const timeDifference = currentDate - originalDate;
                  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
                  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

                  let displayTime;
                  if (timeDifference < 24 * 60 * 60 * 1000) {
                    // Less than 24 hours
                    displayTime = hours > 0 ? `${hours} hrs ago` : minutes > 0 ? `${minutes} min ago` : "Just now";
                  } else {
                    // More than 24 hours
                    displayTime = originalDate.toLocaleDateString("en-GB"); // Format the date as DD-MM-YYYY
                  }
                  return (
                    <>
                      <div
                        className="position-relative mb30-list bg-body subpages"
                        style={{ marginBottom: "40px", marginTop: "-20px" }}>
                        <div className="media">
                          <a className="img-opacity-hover">
                            {record?.image_urls?.[0] ? (
                              <img src={record?.image_urls?.[0]} alt="news" className="img-fluid" />
                            ) : record?.video_url ? (
                              <>
                                <Link to={`/about?param1=${record?.id}&category=${categoryType}`}>
                                  <span>
                                    <img style={imageStyle} src={videoPlayIcon} />
                                  </span>{" "}
                                  <video
                                    style={{
                                      width: "113px",
                                      height: "105px",
                                      backgroundColor: "gray",
                                    }}
                                    muted
                                    preload="metadata"
                                    src={`${record?.video_url}#t=0.1`}></video>
                                </Link>
                              </>
                            ) : (
                              <>
                                <img src={noImgJPG} alt="No-Image" />
                              </>
                            )}
                          </a>
                          <div className="media-body">
                            <h3
                              className={`${
                                record?.language === "తెలుగు" ? "title-medium-dark-telugu" : "title-medium-dark"
                              }`}>
                              <strong
                                className={`${
                                  record?.language === "తెలుగు" ? "telugu-text-title" : "english-text-title"
                                }`}>
                                <Link to={`/about?param1=${record?.id}&category=${categoryType}`}>
                                  {" "}
                                  {record?.title
                                    .replace(/<[^>]*>/g, "")
                                    .replace(/&nbsp;|&emsp;/g, "")
                                    .trim()}
                                </Link>
                              </strong>
                            </h3>
                            <div className="post-date-dark">
                              <ul style={{ marginBottom: "-1em" }}>
                                <li>{displayTime}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="sidebar-box">
                <div className="ne-banner-layout1 text-center"></div>
              </div>
            </div>
          </div>
          <hr></hr>
        </div>
      </section>

      <Row style={{ marginBottom: "20px" }}>
        <Col span={24}>
          {" "}
          <div className="heading subpage_bottom">
            <h1>More from the Politikos</h1>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: "5vh" }}>
        {eachData &&
          eachData?.map((record) => {
            return (
              <>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                  <Card
                    cover={
                      <Link
                        to={`/about?param1=${record?.id}`}
                        class="img-opacity-hover"
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}>
                        {record?.image_urls?.[0] ? (
                          <img
                            src={record?.image_urls?.[0]}
                            alt="news"
                            style={{
                              display: "block",
                              margin: "0 auto",
                              maxWidth: "100%",
                              maxHeight: "32vh",
                              objectFit: "contain",
                            }}
                          />
                        ) : record?.video_link ? (
                          <>
                            <img
                              src={record?.video_thumbnail_url}
                              alt="news"
                              style={{
                                display: "block",
                                margin: "0 auto",
                                maxWidth: "100%",
                                maxHeight: "30vh",
                                objectFit: "contain",
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={noImgJPG}
                              alt="No-Image"
                              style={{
                                display: "block",
                                margin: "0 auto",
                                maxWidth: "100%",
                                maxHeight: "30vh",
                                objectFit: "contain",
                              }}
                            />
                          </>
                        )}
                      </Link>
                    }>
                    <Link
                      to={`/about?param1=${record?.id}`}
                      class="title-medium-dark size-lg mb-none"
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}>
                      {record?.title
                        .replace(/<[^>]*>/g, "")
                        .replace(/&nbsp;|&emsp;/g, "")
                        .trim()?.length > 100
                        ? record?.title
                            .replace(/<[^>]*>/g, "")
                            .replace(/&nbsp;|&emsp;/g, "")
                            .trim()
                            ?.substring(0, 100) + "..."
                        : record?.title
                            .replace(/<[^>]*>/g, "")
                            .replace(/&nbsp;|&emsp;/g, "")
                            .trim()}
                    </Link>
                  </Card>
                </Col>
              </>
            );
          })}
      </Row>
    </>
  );
}

export default VideoDetails;
