import React, { useEffect, useState } from "react";
import { Tabs, Menu, Dropdown, Button, Select, Skeleton, Grid, Row, Col } from "antd";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import TranslateWord from "../../sharedComponents/TranslateWord";
import ChannelsSection from "./ChannelsSection";
import { getAllChannelsData, selectedChannelsInterfaceAction } from "../../../Store/ChannelsSlice";
import { Color } from "antd/es/color-picker";
const { useBreakpoint } = Grid;
const ChannelsSectionDB = ({ sectionType }) => {
  const dispatch = useDispatch();
  const { data: allCategoriesList } = useSelector((state) => state.categoriesList);
  const { data: interfaceLanguagesData, selectedInterfaceLanguage } = useSelector(
    (state) => state.interfaceLanguageList
  );
  const { data: feedLanguageData } = useSelector((state) => state.feedLanguageList);
  // const {
  //   data: allTechnologyData,
  //   status,
  //   error,
  //   technologySelectedLang,
  // } = useSelector((state) => state.technologyList);
  // console.log("technologySelectedLang", technologySelectedLang);
  const { data: allChannelsData, status, error, channelsSelectedLang } = useSelector((state) => state.channelsList);
  const { politicsSelectedLang } = useSelector((state) => state.politicsList);
  const videosTitle = channelsSelectedLang?.name === "తెలుగు" ? "ఛానెల్స్" : "Channels";
  const ALLWORD = channelsSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
  const trendingLives = channelsSelectedLang?.name === "తెలుగు" ? "పొలిటికోస్‌" : "Politikos";
  // const politikosLives = livetvSelectedLang?.name === "తెలుగు" ? "పాలిటికోస్  లైవ్" : "Politikos Live";
  const { TabPane } = Tabs;
  const screens = useBreakpoint();
  const [activeKey, setActiveKey] = useState(ALLWORD);
  const [isShowMore, setisShowMore] = useState(false);
  const [allSubCategoriesList, setAllSubCategoriesList] = useState([]);
  const [selectedLang, setSelectedLang] = useState(channelsSelectedLang?.interface_lang_id);
  const [selectedCategory, setSelectedCategory] = useState(ALLWORD);
  const [rfsh, setRfsh] = useState(false);
  // const liveTvCategories =
  //   allCategoriesList &&
  //   allCategoriesList.filter((allCat) => allCat?.interface_language?.name === channelsSelectedLang?.name);
  const liveTvCategories = [{ category_name: ALLWORD }, { category_name: trendingLives }];
  console.log("channelsSelectedLang", channelsSelectedLang);
  // liveTvCategories.splice(
  //   0,
  //   0,
  //   { category_name: ALLWORD },
  //   { category_name: trendingLives }
  //   // { category_name: politikosLives }
  // );
  const { Option } = Select;
  useEffect(() => {
    // setActiveKey(ALLWORD, trendingLives, politikosLives);     //c
    const categoriesForLive = allCategoriesList;
    const selectedCategoriesList = allCategoriesList?.find((item) => item.category_name === selectedCategory);
    const subCategories = selectedCategoriesList?.str_sub_categories_names;
    if (subCategories?.length) setAllSubCategoriesList([ALLWORD, trendingLives, ...subCategories]);
  }, [allCategoriesList, selectedCategory]);
  //  if(politicsLoading === 'loading'){
  //   return <div> <Skeleton
  //   active
  //   title={false}
  //   paragraph={{ rows: 2, width: [1000, 1000] }}
  // /></div>
  // }
  const onChange = (key) => {
    setisShowMore(false);
    setActiveKey(key);
    setSelectedCategory(key);
  };
  //   const handleChange = (value) => {
  //     setisShowMore(true);
  //     setActiveKey(secondArray[value]);
  //   };
  //   let children = [];
  //   for (let i = 0; i < secondArray.length; i++) {
  //     children.push(<Select.Option key={i}>{secondArray[i]}</Select.Option>);
  //   }
  const handleChange = (value) => {
    console.log("value22", value);
    setSelectedLang(value);
    const selectedIngerfaceLang = interfaceLanguagesData?.filter((lang) => {
      if (lang?.interface_lang_id == value) {
        return lang;
      }
    });

    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === selectedIngerfaceLang?.[0]?.name;
    });
    console.log("feedLa", userSelectedFeedLanguages);
    const feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
      return feedL?.newsfeed_lang_id;
    });
    console.log("feedLangPayload", feedLangPayload);

    dispatch(selectedChannelsInterfaceAction(selectedIngerfaceLang?.[0]));
  };
  console.log("ALLWORD", ALLWORD);
  console.log("ALLWORD", activeKey);
  useEffect(() => {
    handleChange(selectedInterfaceLanguage?.interface_lang_id);
  }, [selectedInterfaceLanguage]);

  useEffect(() => {
    setActiveKey(ALLWORD);
    setSelectedCategory(ALLWORD);
  }, [channelsSelectedLang]);

  return (
    <>
      <div
        style={{ marginTop: "10px" }}
        className={
          channelsSelectedLang?.name === "తెలుగు" ? "custom-politics-tabs-telugu" : "custom-politics-tabs-english"
        }>
        <Row justify={"space-between"}>
          <Col span={2}>
            <div className="heading headig_technology_channels politics_heading">
              <h1 style={{ color: "#FF725E" }}>
                {<TranslateWord key={"CHANNELS"} text={"CHANNELS"} headingStyle={true} lang={channelsSelectedLang} />}
              </h1>
            </div>
          </Col>
          <Col span={screens.xs ? 10 : 8}>
            <div style={{ textAlign: "end", marginTop: channelsSelectedLang?.name === "తెలుగు" ? "-2%" : "0px" }}>
              <span className="viewall_style">
                <Link to={`AllChannelList`}>
                  {<TranslateWord key={"VIEWALL"} text={"VIEWALL"} headingStyle={false} lang={channelsSelectedLang} />}
                </Link>
              </span>
              &emsp; &emsp;
              {channelsSelectedLang && (
                <Select
                  size="small"
                  className="hide-on-small feedDropdown"
                  value={selectedLang}
                  onChange={handleChange}>
                  {interfaceLanguagesData?.map((lang) => {
                    return (
                      <>
                        <Select.Option value={lang?.interface_lang_id}>{lang?.name}</Select.Option>
                      </>
                    );
                  })}
                </Select>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={screens.xs ? { height: "160px" } : {}}>
            <Tabs
              activeKey={activeKey}
              onChange={onChange}
              style={screens.xs ? { position: "relative", top: "-10%" } : {}}
              className={screens.xs ? "custom-tabs-container" : ""}
              moreIcon={
                <span style={{ display: "flex" }}>
                  <TranslateWord key={"MORE"} text={"MORE"} headingStyle={false} lang={channelsSelectedLang} />
                  &#11206;
                </span>
              }
              // className={screens.xs ? "custom-tabs-container" : "custom-tabs-container-channels"}
            >
              {liveTvCategories?.map((tabrecord, index) => {
                const tabKey = tabrecord.category_name;
                const isActive = activeKey === tabKey;
                return (
                  <>
                    <TabPane
                      tab={
                        <span
                          style={{
                            borderBottom: isActive ? "2px solid #FF725E" : "none",
                            fontWeight: isActive ? "700" : "400",
                          }}
                          className={channelsSelectedLang?.name === "తెలుగు" ? "telugu-text" : "english-text"}>
                          {tabKey}
                        </span>
                      }
                      key={tabKey}>
                      <ChannelsSection
                        key={index + tabKey}
                        subCategoryName={activeKey}
                        categoryName={selectedCategory}
                        sectionInterfaceLang={channelsSelectedLang}
                      />
                    </TabPane>
                  </>
                );
              })}
            </Tabs>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ChannelsSectionDB;
