import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Tabs,
  Tooltip,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import userProfilePic from "../../imgaes/userProfilePic.svg";
import uploadProfileImg from "../../imgaes/uploadProfileImg.svg";
import editIcon from "../../imgaes/editIcon.svg";
import sharingIcon from "../../imgaes/sharingIcon.svg";
import RelatedStoryIcon from "../../imgaes/RelatedStoryIcon.svg";
import ReasonStoryIcon from "../../imgaes/ReasonStoryIcon.svg";
import commentsIcon from "../../imgaes/commentsIcon.svg";
import account_group from "../../imgaes/account_group.svg";
import update from "../../imgaes/update.svg";
import groupUsersIcon from "../../imgaes/groupUsersIcon.svg";
import viewIcon from "../../imgaes/viewIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import OTP from "../Login/OTP/OTP";
import { getOTPData, sendOTPtoMail } from "../../Store/LoginSlice";
import {
  getUserProfileData,
  updateProfileUserPicture,
  updateProfileUserSettings,
} from "../../Store/ProfileSettingsSlice";
import BaseUrl from "../../utils/config";
import deleteIcon from "../../imgaes/deleteIcon.png";
import axios from "axios";
import TranslateWord from "../sharedComponents/TranslateWord";
import { getAllSubmissionTabStories } from "../../Store/SubmissionStoriesSlice";
import { getAllSubmissionTabPetitions } from "../../Store/SubmissionPetitionsSlice";
import { getAllSubmissionTabGrievance } from "../../Store/SubmissionGrievanceSlice";
import { size } from "lodash";
import ReasonModel from "./ReasonModel";
import { AiOutlineSearch, AiOutlineCloseCircle } from "react-icons/ai";
import { PlusCircleOutlined } from "@ant-design/icons";
import AddNewStory from "./AddNewStory";
import PetitionReason from "./PetitionReason";
import PetitionUpdates from "./PetitionUpdates";

const { Text } = Typography;
let filterText = "";
const SubmissionsTab = () => {
  const { TabPane } = Tabs;

  const dispatch = useDispatch();
  const [profileAboutYourSelf] = Form.useForm();
  const [currentPageForStories, setCurrentPageForStories] = useState(1);
  const [currentPageForPetitions, setCurrentPageForPetitions] = useState(1);
  const [currentPageForGrievances, setCurrentPageForGrievances] = useState(1);
  const [rejectReasonFlag, setRejectReasonFlag] = useState(false);
  const [petitionRejectReasonFlag, setPetitionRejectReasonFlag] = useState(false);
  const [grievanceRejectReasonFlag, setGrievanceRejectReasonFlag] = useState(false);
  const [selectedStory, setSelectedStory] = useState(null);
  const [selectedPetition, setSelectedPetition] = useState(null);
  const [selectedGrievance, setSelectedGrievance] = useState(null);
  const [reasonTitle, setReasonTitle] = useState(null);
  const [petitionReasonTitle, setPetitionReasonTitle] = useState(null);
  const [grievanceReasonTitle, setGrievanceReasonTitle] = useState(null);
  const [searchIconHide, setsearchIconHide] = useState(false);
  const [searchInputHide, setSearchInputHide] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [type, setType] = useState("Draft");
  const [petitionType, setPetitionType] = useState("Draft");
  const [grievanceType, setGrievanceType] = useState("Draft");
  const [addNewStoryFlag, setAddNewStoryFlag] = useState(false);
  const [petitionUpdateFlag, setPetitionUpdateFlag] = useState(false);
  const [storyData, setStoryData] = useState([]);

  const cardsPerPage = 10;

  const { selectedInterfaceLanguage } = useSelector((state) => state.interfaceLanguageList);

  const { data: submissionStoriesData } = useSelector((state) => state.submissionStoriesList);
  const { data: submissionPetitionsData } = useSelector((state) => state.submissionPetitionsList);
  const { data: submissionGrievancesData } = useSelector((state) => state.submissionGrievanceList);
  const { userProfileData, workExperienceData, educationQualificationData } = useSelector(
    (state) => state.profileSettingsList
  );

  let user_id = userProfileData?.user_id;
  const getSubmissionTabStoriesData = (page) => {
    const payload = {
      user_id: user_id,
      page: page - 1,
      pageSize: 10,
    };
    // dispatch(getAllSubmissionTabStories(payload));
  };
  const getSubmissionTabPetitionsData = (page) => {
    const LOGIN_USER_ID = localStorage.getItem("loginuserId");
    const payload = {
      login_user_id: +LOGIN_USER_ID,
      status: petitionType,
      page: page === 0 ? page : page - 1,
      pageSize: 12,
      filter_by_search_text: filterText,
    };
    dispatch(getAllSubmissionTabPetitions(payload));
  };
  const getSubmissionTabGrievanceData = (page) => {
    const payload = {
      user_id: user_id,
      page: page - 1,
      pageSize: 10,
    };
    dispatch(getAllSubmissionTabGrievance(payload));
  };
  useEffect(() => {
    getSubmissionTabStoriesData(1);
    getSubmissionTabPetitionsData(1);
    getSubmissionTabGrievanceData(1);
  }, []);

  const iconStyle = {
    marginTop: !searchIconHide ? "1px" : "0px",
    marginLeft: "5px",
  };

  const scrollCSS = {
    maxHeight: "80vh",
    overflowY: "auto",
    overflowX: "hidden",
    justifyContent: "center",
    width: "84vw",
    marginTop: "20px",
  };

  const handlePageChangeForStories = (page, pageSize) => {
    setCurrentPageForStories(page);
    getSubmissionTabStoriesData(page);
  };
  const handlePageChangeForPetitions = (page, pageSize) => {
    setCurrentPageForPetitions(page);
    getSubmissionTabPetitionsData(page);
  };
  const handlePageChangeForGrievances = (page, pageSize) => {
    setCurrentPageForGrievances(page);
    getSubmissionTabGrievanceData(page);
  };

  const hangleRejectReason = (story, title) => {
    setSelectedStory(story);
    setRejectReasonFlag(true);
    setReasonTitle(title);
  };

  const hanglePetitionRejectReason = (petition, title) => {
    setSelectedPetition(petition);
    setPetitionRejectReasonFlag(true);
    setPetitionReasonTitle(title);
  };
  const hangleGrievanceRejectReason = (grievance, title) => {
    setSelectedGrievance(grievance);
    setGrievanceRejectReasonFlag(true);
    setGrievanceReasonTitle(title);
  };
  const closeReasonModel = () => {
    setRejectReasonFlag(false);
    setPetitionRejectReasonFlag(false);
    setGrievanceRejectReasonFlag(false);
    setPetitionUpdateFlag(false)
    setSelectedPetition(null)
  };

  const openStoryModalPopup = async (story) => {
    // setIsLoading(true);
    setAddNewStoryFlag(true);
    setStoryData([]);
    const data = await fetch(`${BaseUrl}/story/storytabwisedetails/${story.story_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await data.json();
    // setIsLoading(false);
    setStoryData(result);
    return result;
  };

  const openPetitionUpdateModel = (petition) =>{
    setPetitionUpdateFlag(true);
    setSelectedPetition(petition)
  }
  const showInput = () => {
    setSearchInputHide(false);
    setsearchIconHide(true);
  };

  const onSearch = (event) => {
    setSearchText(event?.target?.value);
    filterText = event?.target?.value;
    if (event?.key === "Enter") getPreviousStoresListData(1);
  };

  const handleEnter = (e) => {
    if (e?.key === "Enter") {
      setCurrentPageForStories(1);
      if (currentPageForStories === 1) {
        getPreviousStoresListData(1);
      }
    }
  };
  useEffect(() => {
    getPreviousStoresListData(0);
  }, []);

  useEffect(() => {
    getPreviousStoresListData(currentPageForStories);
  }, [currentPageForStories]);
  useEffect(() => {
    getPreviousPetitionListData(currentPageForPetitions);
  }, [currentPageForPetitions]);
  useEffect(() => {
    getPreviousGrievanceListData(currentPageForGrievances);
  }, [currentPageForGrievances]);
  useEffect(() => {
    setCurrentPageForStories(1);
    getPreviousStoresListData(1);
  }, [type]);
  useEffect(() => {
    setCurrentPageForPetitions(1);
    getPreviousPetitionListData(1);
  }, [petitionType]);

  useEffect(() => {
    setCurrentPageForGrievances(1);
    getPreviousGrievanceListData(1);
  }, [grievanceType]);

  const getAllPreviousStoriesFunc = async (payload) => {
    // setIsLoading(true);
    // const {user_id,page,pageSize} = payload
    const data = await fetch(`${BaseUrl}/story/previousstorieslist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const result = await data.json();
    // setPreviousStoriesList(result);
    // setIsLoading(false);
    return result;
  };
  const handleDashboardData = (type) => {
    setType(type);
    setCurrentPageForStories(1);
  };
  const handlepetitionDashboardData = (petitionType) => {
    setPetitionType(petitionType);
    setCurrentPageForPetitions(1);
  };
  const handleGrievanceDashboardData = (grievanceType) => {
    setGrievanceType(grievanceType);
    setCurrentPageForGrievances(1);
  };

  const closeInputBox = () => {
    setSearchInputHide(true);
    setsearchIconHide(false);
    onSearch("");
    setSearchText("");
    filterText = "";
    getPreviousStoresListData(1);
  };
  const openNewStoryModel = () => {
    setAddNewStoryFlag(true);
    localStorage.removeItem("storyDetailsTabData");
    localStorage.removeItem("storyOthersTabData");
    localStorage.removeItem("storyMediaData");
    localStorage.removeItem("feedLang");
  };
  const getPreviousStoresListData = (page) => {
    const LOGIN_USER_ID = localStorage.getItem("loginuserId");
    const payload = {
      employee_id: +LOGIN_USER_ID,
      filter_by_search_text: filterText,
      status: type,
      page: page === 0 ? page : page - 1,
      pageSize: 12,
    };
    dispatch(getAllSubmissionTabStories(payload));
    // getAllPreviousStoriesFunc(payload);
  };

  const getPreviousPetitionListData = (page) => {
    const LOGIN_USER_ID = localStorage.getItem("loginuserId");
    const payload = {
      // login_user_id: +LOGIN_USER_ID,
      login_user_id: 66,
      filter_by_search_text: filterText,
      status: petitionType,
      page: page === 0 ? page : page - 1,
      pageSize: 12,
    };
    dispatch(getAllSubmissionTabPetitions(payload));
  };
  const getPreviousGrievanceListData = (page) => {
    const LOGIN_USER_ID = localStorage.getItem("loginuserId");
    const payload = {
      // login_user_id: +LOGIN_USER_ID,
      login_user_id: 66,
      filter_by_search_text: filterText,
      grievance_status: grievanceType,
      page: page === 0 ? page : page - 1,
      pageSize: 12,
    };
    dispatch(getAllSubmissionTabGrievance(payload));
  };
  const closeModal = () => {
    setAddNewStoryFlag(false);  
  };
  return (
    <>
      <Tabs tabPosition="left" className="custom-tabs-width custom-tabs-inkbar">
        <TabPane
          tab={
            selectedInterfaceLanguage && (
              <TranslateWord key={"STORIES"} text={"STORIES"} lang={selectedInterfaceLanguage} />
            )
          }
          key="STORIES">
          <div style={{ marginBottom: "3vh" }}>
            <Row style={{ margin: "0px 10px 10px 10px" }}>
              <Col
                style={{ marginTop: "20px" }}
                lg={{
                  span: 6,
                }}
                xs={{
                  span: 24,
                }}>
                <Row>
                  {/* <Col>
              {" "}
              <Text className="userName">{selectedInterfaceLanguage && (
                <TranslateWord
                key={"PREVIOUS_STORIES"}
                text={"PREVIOUS_STORIES"}
                lang={selectedInterfaceLanguage}

              />
               )}</Text>
            </Col> */}
                  <Col style={iconStyle}>
                    <AiOutlineSearch
                      hidden={searchIconHide}
                      onClick={showInput}
                      style={{ cursor: "pointer" }}
                      size={20}
                    />
                    <div>
                      <Input
                        //  prefix={<SearchOutlined />}
                        // allowClear
                        placeholder="search text then press enter"
                        hidden={searchInputHide}
                        onChange={onSearch}
                        value={searchText}
                        onKeyUp={handleEnter}
                        style={{
                          width: 215,
                          marginLeft: "4px",
                        }}
                      />
                      {searchIconHide ? (
                        <AiOutlineCloseCircle
                          title="Close search box"
                          onClick={closeInputBox}
                          style={{ color: "red", cursor: "pointer" }}
                          size={20}
                        />
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                lg={{
                  span: 18,
                }}
                xs={{
                  span: 24,
                }}>
                <span style={{ marginRight: "5px" }}>
                  <Button
                    onClick={() => handleDashboardData("Draft")}
                    style={{
                      position: "relative",
                      top: "20px",
                      cursor: "pointer",
                      color: type === "Draft" ? "white" : "#616161",
                      backgroundColor: type === "Draft" ? "#CC0001" : "white",
                      border: type === "Draft" ? "1px solid white" : "white",
                    }}
                    ghost>
                    {selectedInterfaceLanguage && (
                      <TranslateWord key={"DRAFT"} text={"DRAFT"} lang={selectedInterfaceLanguage} />
                    )}
                  </Button>
                </span>
                <span style={{ marginRight: "5px" }}>
                  <Button
                    onClick={() => handleDashboardData("Waiting for Approval")}
                    style={{
                      position: "relative",
                      top: "20px",
                      cursor: "pointer",
                      color: type === "Waiting for Approval" ? "white" : "#616161",
                      backgroundColor: type === "Waiting for Approval" ? "#CC0001" : "white",
                      border: type === "Waiting for Approval" ? "1px solid white" : "white",
                    }}
                    ghost>
                    {selectedInterfaceLanguage && (
                      <TranslateWord
                        key={"WAITING_FOR_APPROVAL"}
                        text={"WAITING_FOR_APPROVAL"}
                        lang={selectedInterfaceLanguage}
                      />
                    )}
                  </Button>
                </span>
                <span style={{ marginRight: "5px" }}>
                  <Button
                    onClick={() => handleDashboardData("Published")}
                    style={{
                      position: "relative",
                      top: "20px",
                      cursor: "pointer",
                      color: type === "Published" ? "white" : "#616161",
                      backgroundColor: type === "Published" ? "#CC0001" : "white",
                      border: type === "Published" ? "1px solid white" : "white",
                    }}
                    ghost>
                    {selectedInterfaceLanguage && (
                      <TranslateWord key={"PUBLISHED"} text={"PUBLISHED"} lang={selectedInterfaceLanguage} />
                    )}
                  </Button>
                </span>
                <span style={{ marginRight: "5px" }}>
                  <Button
                    onClick={() => handleDashboardData("Returned")}
                    style={{
                      position: "relative",
                      top: "20px",
                      cursor: "pointer",
                      color: type === "Returned" ? "white" : "#616161",
                      backgroundColor: type === "Returned" ? "#CC0001" : "white",
                      border: type === "Returned" ? "1px solid white" : "white",
                    }}
                    ghost>
                    {selectedInterfaceLanguage && (
                      <TranslateWord key={"RETURNED"} text={"RETURNED"} lang={selectedInterfaceLanguage} />
                    )}
                  </Button>
                </span>
                <span style={{ marginRight: "5px" }}>
                  <Button
                    onClick={() => handleDashboardData("Rejected")}
                    style={{
                      position: "relative",
                      top: "20px",
                      cursor: "pointer",
                      color: type === "Rejected" ? "white" : "#616161",
                      backgroundColor: type === "Rejected" ? "#CC0001" : "white",
                      border: type === "Rejected" ? "1px solid white" : "white",
                    }}
                    ghost>
                    {selectedInterfaceLanguage && (
                      <TranslateWord key={"REJECTED"} text={"REJECTED"} lang={selectedInterfaceLanguage} />
                    )}
                  </Button>
                </span>
                {/* <span style={{cursor:'pointer'}} onClick={openNewStoryModel}>       
             <PlusCircleOutlined style={{ fontSize: '30px', color: '#08c', position:'relative',top:'2vh',left:'3vw'}}/>

        </span> */}
              </Col>
            </Row>
            <Row gutter={[10, 10]} style={{ marginTop: "6vh" }}>
              {submissionStoriesData?.data?.map((story) => {
                return (
                  <>
                    <Col style={{ marginBottom: "5px" }} xs={20} sm={20} md={12} lg={8} xl={6} xxl={6}>
                      <Card
                        className="customCard"
                        hoverable
                        // style={{ width: "19vw" }}
                      >
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}>
                            <div>
                              {" "}
                              {selectedInterfaceLanguage && (
                                <TranslateWord key={"STORY_ID"} text={"STORY_ID"} lang={selectedInterfaceLanguage} />
                              )}{" "}
                              : {story.story_id}
                            </div>
                            <div>
                              {story?.story_status?.story_status_name === "Draft" && (
                                <Badge color="#616161" placement="start" />
                              )}
                              {story?.story_status?.story_status_name === "Edit and Resend" && (
                                <Badge color="#FAAC40" placement="start" />
                              )}
                              {story?.story_status?.story_status_name === "Rejected" && (
                                <Badge color="#CC0001" placement="start" />
                              )}
                              {story?.story_status?.story_status_name === "Waiting for Approval" && (
                                <Badge color="#f50" placement="start" />
                              )}
                              {story?.story_status?.story_status_name === "Published" && (
                                <Badge color="#00E676" placement="start" />
                              )}
                              {/* {story?.story_status?.story_status_name === 'Published' &&  <Badge color="#00E676" text={story?.story_status?.story_status_name} placement='start' />} */}
                            </div>
                          </div>
                          <div style={{ height: "9vh" }}>
                            <Text strong>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html:
                                    story?.title
                                      .replace(/<[^>]*>/g, "")
                                      .replace(/&nbsp;|&emsp;/g, "")
                                      .trim()?.length > 55
                                      ? story?.title
                                          .replace(/<[^>]*>/g, "")
                                          .replace(/&nbsp;|&emsp;/g, "")
                                          .trim()
                                          ?.substring(0, 55) + "..."
                                      : story?.title
                                          .replace(/<[^>]*>/g, "")
                                          .replace(/&nbsp;|&emsp;/g, "")
                                          .trim(),
                                }}></span>
                            </Text>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}>
                            <div>{moment(story.create_story_date_time).format("MMM DD")}</div>
                            {story?.story_status?.story_status_name === "Published" && (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}>
                                  <div
                                    style={{
                                      color: "blue",
                                      fontSize: "13px",
                                      marginRight: "5px",
                                    }}>
                                    <Tooltip title="Comming soon">
                                      <img src={RelatedStoryIcon} width={20} height={20} />
                                    </Tooltip>
                                  </div>
                                  <div style={{ marginRight: "5px" }}>
                                    <img src={sharingIcon} width={18} height={18} />
                                  </div>
                                  <div style={{ marginRight: "5px" }}>
                                    <img src={commentsIcon} width={18} height={18} />{" "}
                                    <span
                                      style={{
                                        position: "relative",
                                        top: "-0.6vh",
                                      }}>
                                      0
                                    </span>
                                  </div>
                                  <div style={{ marginRight: "5px" }}>
                                    <img src={viewIcon} width={18} height={18} />{" "}
                                    <span
                                      style={{
                                        position: "relative",
                                        top: "-0.6vh",
                                      }}>
                                      0
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                            {story?.story_status?.story_status_name === "Draft" && (
                              <>
                                <div>
                                  <img
                                    src={editIcon}
                                    onClick={() => openStoryModalPopup(story)}
                                    width={18}
                                    height={18}
                                  />
                                </div>
                              </>
                            )}

                            {story?.story_status?.story_status_name === "Edit and Resend" && (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}>
                                  <div
                                    style={{
                                      color: "blue",
                                      fontSize: "13px",
                                      marginRight: "5px",
                                    }}
                                    onClick={() => {
                                      hangleRejectReason(story, "Reason for Return");
                                    }}>
                                    <Tooltip
                                      title={
                                        selectedInterfaceLanguage && (
                                          <TranslateWord
                                            key={"REASON_FOR_RETURNED"}
                                            text={"REASON_FOR_RETURNED"}
                                            lang={selectedInterfaceLanguage}
                                          />
                                        )
                                      }>
                                      <img src={ReasonStoryIcon} width={20} height={20} />
                                    </Tooltip>
                                  </div>
                                  <div
                                    style={{
                                      color: "blue",
                                      fontSize: "13px",
                                      marginRight: "5px",
                                    }}></div>
                                  <div style={{ marginRight: "5px" }}>
                                    <img
                                      src={editIcon}
                                      width={18}
                                      height={18}
                                      onClick={() => openStoryModalPopup(story)}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                            {story?.story_status?.story_status_name === "Rejected" && (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}>
                                  <div
                                    style={{
                                      color: "blue",
                                      fontSize: "13px",
                                      marginRight: "5px",
                                    }}
                                    onClick={() => {
                                      hangleRejectReason(story, "Reason for Rejection");
                                    }}>
                                    <Tooltip
                                      title={
                                        selectedInterfaceLanguage && (
                                          <TranslateWord
                                            key={"REASON_FOR_REJECTED"}
                                            text={"REASON_FOR_REJECTED"}
                                            lang={selectedInterfaceLanguage}
                                          />
                                        )
                                      }>
                                      <img src={ReasonStoryIcon} width={20} height={20} />
                                    </Tooltip>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </>
                );
              })}
            </Row>
          </div>
          <div>
            <Pagination
              className="pull-right"
              current={currentPageForStories}
              pageSize={12}
              total={submissionStoriesData?.totalElements}
              showSizeChanger={false}
              onChange={handlePageChangeForStories}
            />
          </div>
        </TabPane>
        <TabPane
          tab={
            selectedInterfaceLanguage && (
              <TranslateWord key={"PETITIONS"} text={"PETITIONS"} lang={selectedInterfaceLanguage} />
            )
          }
          key="PETITIONS">
          <div style={{ marginBottom: "3vh" }}>
            <Row style={{ margin: "0px 10px 10px 10px" }}>
              <Col
                style={{ marginTop: "20px" }}
                lg={{
                  span: 6,
                }}
                xs={{
                  span: 24,
                }}>
                <Row>
                  <Col style={iconStyle}>
                    <AiOutlineSearch
                      hidden={searchIconHide}
                      onClick={showInput}
                      style={{ cursor: "pointer" }}
                      size={20}
                    />
                    <div>
                      <Input
                        //  prefix={<SearchOutlined />}
                        // allowClear
                        placeholder="search text then press enter"
                        hidden={searchInputHide}
                        onChange={onSearch}
                        value={searchText}
                        onKeyUp={handleEnter}
                        style={{
                          width: 215,
                          marginLeft: "4px",
                        }}
                      />
                      {searchIconHide ? (
                        <AiOutlineCloseCircle
                          title="Close search box"
                          onClick={closeInputBox}
                          style={{ color: "red", cursor: "pointer" }}
                          size={20}
                        />
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                lg={{
                  span: 18,
                }}
                xs={{
                  span: 24,
                }}>
                <span style={{ marginRight: "5px" }}>
                  <Button
                    onClick={() => handlepetitionDashboardData("Draft")}
                    style={{
                      position: "relative",
                      top: "20px",
                      cursor: "pointer",
                      color: petitionType === "Draft" ? "white" : "#616161",
                      backgroundColor: petitionType === "Draft" ? "#CC0001" : "white",
                      border: petitionType === "Draft" ? "1px solid white" : "white",
                    }}
                    ghost>
                    {selectedInterfaceLanguage && (
                      <TranslateWord key={"DRAFT"} text={"DRAFT"} lang={selectedInterfaceLanguage} />
                    )}
                  </Button>
                </span>
                <span style={{ marginRight: "5px" }}>
                  <Button
                    onClick={() => handlepetitionDashboardData("Waiting For Approval")}
                    style={{
                      position: "relative",
                      top: "20px",
                      cursor: "pointer",
                      color: petitionType === "Waiting For Approval" ? "white" : "#616161",
                      backgroundColor: petitionType === "Waiting For Approval" ? "#CC0001" : "white",
                      border: petitionType === "Waiting For Approval" ? "1px solid white" : "white",
                    }}
                    ghost>
                    {selectedInterfaceLanguage && (
                      <TranslateWord key={"UNDER_REVIEW"} text={"UNDER_REVIEW"} lang={selectedInterfaceLanguage} />
                    )}
                  </Button>
                </span>
                <span style={{ marginRight: "5px" }}>
                  <Button
                    onClick={() => handlepetitionDashboardData("Posted for Signatures")}
                    style={{
                      position: "relative",
                      top: "20px",
                      cursor: "pointer",
                      color: petitionType === "Posted for Signatures" ? "white" : "#616161",
                      backgroundColor: petitionType === "Posted for Signatures" ? "#CC0001" : "white",
                      border: petitionType === "Posted for Signatures" ? "1px solid white" : "white",
                    }}
                    ghost>
                    {selectedInterfaceLanguage && (
                      <TranslateWord
                        key={"POSTED_FOR_SINGNATURE"}
                        text={"POSTED_FOR_SINGNATURE"}
                        lang={selectedInterfaceLanguage}
                      />
                    )}
                  </Button>
                </span>
                <span style={{ marginRight: "5px" }}>
                  <Button
                    onClick={() => handlepetitionDashboardData("Sent to Authority Concerned")}
                    style={{
                      position: "relative",
                      top: "20px",
                      cursor: "pointer",
                      color: petitionType === "Sent to Authority Concerned" ? "white" : "#616161",
                      backgroundColor: petitionType === "Sent to Authority Concerned" ? "#CC0001" : "white",
                      border: petitionType === "Sent to Authority Concerned" ? "1px solid white" : "white",
                    }}
                    ghost>
                    {selectedInterfaceLanguage && (
                      <TranslateWord
                        key={"SENT_TO_AUTH_CONCERNED"}
                        text={"SENT_TO_AUTH_CONCERNED"}
                        lang={selectedInterfaceLanguage}
                      />
                    )}
                  </Button>
                </span>
                <span style={{ marginRight: "5px" }}>
                  <Button
                    onClick={() => handlepetitionDashboardData("Resolved")}
                    style={{
                      position: "relative",
                      top: "20px",
                      cursor: "pointer",
                      color: petitionType === "Resolved" ? "white" : "#616161",
                      backgroundColor: petitionType === "Resolved" ? "#CC0001" : "white",
                      border: petitionType === "Resolved" ? "1px solid white" : "white",
                    }}
                    ghost>
                    {selectedInterfaceLanguage && (
                      <TranslateWord key={"RESOLVED"} text={"RESOLVED"} lang={selectedInterfaceLanguage} />
                    )}
                  </Button>
                </span>

                <span style={{ marginRight: "5px" }}>
                  <Button
                    onClick={() => handlepetitionDashboardData("Rejected")}
                    style={{
                      position: "relative",
                      top: "20px",
                      cursor: "pointer",
                      color: petitionType === "Rejected" ? "white" : "#616161",
                      backgroundColor: petitionType === "Rejected" ? "#CC0001" : "white",
                      border: petitionType === "Rejected" ? "1px solid white" : "white",
                    }}
                    ghost>
                    {selectedInterfaceLanguage && (
                      <TranslateWord key={"REJECTED"} text={"REJECTED"} lang={selectedInterfaceLanguage} />
                    )}
                  </Button>
                </span>
                {/* <span style={{cursor:'pointer'}} onClick={openNewStoryModel}>       
             <PlusCircleOutlined style={{ fontSize: '30px', color: '#08c', position:'relative',top:'2vh',left:'3vw'}}/>

        </span> */}
              </Col>
            </Row>
            <Row gutter={[10, 10]} style={{ marginTop: "6vh" }}>
              {submissionPetitionsData?.data?.map((petition) => {
                return (
                  <>
                    <Col style={{ marginBottom: "5px" }} xs={20} sm={20} md={12} lg={8} xl={6} xxl={6}>
                      <Card
                        className="customCard"
                        hoverable
                        // style={{ width: "19vw" }}
                      >
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}>
                            <div>
                              {" "}
                              {selectedInterfaceLanguage && (
                                <TranslateWord
                                  key={"PETITION_ID"}
                                  text={"PETITION_ID"}
                                  lang={selectedInterfaceLanguage}
                                />
                              )}{" "}
                              : {petition.petition_id}
                            </div>
                            <div>
                              {petition?.petition_status?.status_description === "Draft" && (
                                <Badge color="#616161" placement="start" />
                              )}
                              {petition?.petition_status?.status_description === "Successfully Completed" && (
                                <Badge color="#00E676" placement="start" />
                              )}
                              {petition?.petition_status?.status_description === "Reject" && (
                                <Badge color="#CC0001" placement="start" />
                              )}
                              {petition?.petition_status?.status_description === "Posted for Signatures" && (
                                <Badge color="#4A148C" placement="start" />
                              )}

                              {petition?.petition_status?.status_description === "Sent to Authority Concerned" && (
                                <Badge color="#01579B" placement="start" />
                              )}
                              {petition?.petition_status?.status_description === "Under review" && (
                                <Badge color="#FAAC40" placement="start" />
                              )}
                              {/* {story?.story_status?.story_status_name === 'Published' &&  <Badge color="#00E676" text={story?.story_status?.story_status_name} placement='start' />} */}
                            </div>
                          </div>
                          <div style={{ height: "9vh" }}>
                            <Text strong>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html:
                                    petition.title
                                      .replace(/<[^>]*>/g, "")
                                      .replace(/&nbsp;|&emsp;/g, "")
                                      .trim()?.length > 55
                                      ? petition.title
                                          .replace(/<[^>]*>/g, "")
                                          .replace(/&nbsp;|&emsp;/g, "")
                                          .trim()
                                          ?.substring(0, 55) + "..."
                                      : petition.title
                                          .replace(/<[^>]*>/g, "")
                                          .replace(/&nbsp;|&emsp;/g, "")
                                          .trim(),
                                }}></span>
                            </Text>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}>
                            <div>{moment(petition.create_story_date_time).format("MMM DD")}</div>

                            {petition?.petition_status?.status_description === "Draft" && (
                              <>
                                <div>
                                  <img
                                    src={editIcon}
                                    // onClick={() => openStoryModalPopup(story)}
                                    width={18}
                                    height={18}
                                  />
                                </div>
                              </>
                            )}

                            {petition?.petition_status?.status_description === "Posted for Signatures" && (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}>
                                  <div
                                    style={{
                                      color: "blue",
                                      fontSize: "13px",
                                      marginRight: "5px",
                                    }}
                                    onClick={() => {
                                      openPetitionUpdateModel(petition);
                                    }}
                                    >
                                    <Tooltip
                                      title={
                                        selectedInterfaceLanguage && (
                                          <TranslateWord
                                            key={"UPDATES"}
                                            text={"UPDATES"}
                                            lang={selectedInterfaceLanguage}
                                          />
                                        )
                                      }>
                                      <img src={update} width={20} height={20} />
                                    </Tooltip>
                                  </div>
                                  <div
                                    style={{
                                      color: "blue",
                                      fontSize: "13px",
                                      marginRight: "5px",
                                    }}
                                  
                                    >   
                                       <Tooltip
                                      title={
                                        selectedInterfaceLanguage && (
                                          <TranslateWord
                                            key={"COMINGSOON"}
                                            text={"COMINGSOON"}
                                            lang={selectedInterfaceLanguage}
                                          />
                                        )
                                      }>                            
                                      <img src={sharingIcon} width={20} height={20} />   
                                      </Tooltip>                                   
                                  </div>
                                  <div
                                    style={{
                                      color: "blue",
                                      fontSize: "13px",
                                      marginRight: "5px",
                                    }}                                  
                                    >                                  
                                      <img src={commentsIcon} width={20} height={20} /><span style={{color:'#000000'}}>{petition?.totalComments}</span>
                                   
                                  </div>
                                  <div
                                    style={{
                                      color: "blue",
                                      fontSize: "13px",
                                      marginRight: "5px",
                                    }}                                  
                                    >                                  
                                      <img src={account_group} width={20} height={20} /><span style={{color:'#000000'}}>&nbsp;{`${petition?.signedSignatures}/${petition?.target_number_of_signatures}`}</span>
                                   
                                  </div>
                                </div>
                              </>
                            )}

                            {petition?.petition_status?.status_description === "Resolved" && (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}>
                                  <div
                                    style={{
                                      color: "blue",
                                      fontSize: "13px",
                                      marginRight: "5px",
                                    }}
                                    onClick={() => {
                                      hanglePetitionRejectReason(petition, "Reason for Return");
                                    }}>
                                    <Tooltip
                                      title={
                                        selectedInterfaceLanguage && (
                                          <TranslateWord
                                            key={"REASON_FOR_RETURNED"}
                                            text={"REASON_FOR_RETURNED"}
                                            lang={selectedInterfaceLanguage}
                                          />
                                        )
                                      }>
                                      <img src={ReasonStoryIcon} width={20} height={20} />
                                    </Tooltip>
                                  </div>
                                  <div
                                    style={{
                                      color: "blue",
                                      fontSize: "13px",
                                      marginRight: "5px",
                                    }}></div>
                                  <div style={{ marginRight: "5px" }}>
                                    <img
                                      src={editIcon}
                                      width={18}
                                      height={18}
                                      // onClick={() => openStoryModalPopup(story)}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                            {petition?.petition_status?.status_description === "Reject" && (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}>
                                  <div
                                    style={{
                                      color: "blue",
                                      fontSize: "13px",
                                      marginRight: "5px",
                                    }}
                                    onClick={() => {
                                      hanglePetitionRejectReason(petition, "Reason for Rejection");
                                    }}>
                                    <Tooltip
                                      title={
                                        selectedInterfaceLanguage && (
                                          <TranslateWord
                                            key={"REASON_FOR_REJECTED"}
                                            text={"REASON_FOR_REJECTED"}
                                            lang={selectedInterfaceLanguage}
                                          />
                                        )
                                      }>
                                      <img src={ReasonStoryIcon} width={20} height={20} />
                                    </Tooltip>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </>
                );
              })}
            </Row>
          </div>

          <div>
            <Pagination
              className="pull-right"
              current={currentPageForPetitions}
              pageSize={cardsPerPage}
              total={submissionPetitionsData?.totalElements}
              showSizeChanger={false}
              onChange={handlePageChangeForPetitions}
            />
          </div>
        </TabPane>
        <TabPane
          tab={
            selectedInterfaceLanguage && (
              <TranslateWord key={"GRIEVANCES"} text={"GRIEVANCES"} lang={selectedInterfaceLanguage} />
            )
          }
          key="GRIEVANCES">
          <div style={{ marginBottom: "3vh" }}>
            <Row style={{ margin: "0px 10px 10px 10px" }}>
              <Col
                style={{ marginTop: "20px" }}
                lg={{
                  span: 6,
                }}
                xs={{
                  span: 24,
                }}>
                <Row>
                  <Col style={iconStyle}>
                    <AiOutlineSearch
                      hidden={searchIconHide}
                      onClick={showInput}
                      style={{ cursor: "pointer" }}
                      size={20}
                    />
                    <div>
                      <Input
                        //  prefix={<SearchOutlined />}
                        // allowClear
                        placeholder="search text then press enter"
                        hidden={searchInputHide}
                        onChange={onSearch}
                        value={searchText}
                        onKeyUp={handleEnter}
                        style={{
                          width: 215,
                          marginLeft: "4px",
                        }}
                      />
                      {searchIconHide ? (
                        <AiOutlineCloseCircle
                          title="Close search box"
                          onClick={closeInputBox}
                          style={{ color: "red", cursor: "pointer" }}
                          size={20}
                        />
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                lg={{
                  span: 18,
                }}
                xs={{
                  span: 24,
                }}>
                <span style={{ marginRight: "5px" }}>
                  <Button
                    onClick={() => handleGrievanceDashboardData("Draft")}
                    style={{
                      position: "relative",
                      top: "20px",
                      cursor: "pointer",
                      color: grievanceType === "Draft" ? "white" : "#616161",
                      backgroundColor: grievanceType === "Draft" ? "#CC0001" : "white",
                      border: grievanceType === "Draft" ? "1px solid white" : "white",
                    }}
                    ghost>
                    {selectedInterfaceLanguage && (
                      <TranslateWord key={"DRAFT"} text={"DRAFT"} lang={selectedInterfaceLanguage} />
                    )}
                  </Button>
                </span>

                <span style={{ marginRight: "5px" }}>
                  <Button
                    onClick={() => handleGrievanceDashboardData("Waiting for Approval")}
                    style={{
                      position: "relative",
                      top: "20px",
                      cursor: "pointer",
                      color: grievanceType === "Waiting for Approval" ? "white" : "#616161",
                      backgroundColor: grievanceType === "Waiting for Approval" ? "#CC0001" : "white",
                      border: grievanceType === "Waiting for Approval" ? "1px solid white" : "white",
                    }}
                    ghost>
                    {selectedInterfaceLanguage && (
                      <TranslateWord key={"UNDER_REVIEW"} text={"UNDER_REVIEW"} lang={selectedInterfaceLanguage} />
                    )}
                  </Button>
                </span>
                <span style={{ marginRight: "5px" }}>
                  <Button
                    onClick={() => handleGrievanceDashboardData("Reject")}
                    style={{
                      position: "relative",
                      top: "20px",
                      cursor: "pointer",
                      color: grievanceType === "Reject" ? "white" : "#616161",
                      backgroundColor: grievanceType === "Reject" ? "#CC0001" : "white",
                      border: grievanceType === "Reject" ? "1px solid white" : "white",
                    }}
                    ghost>
                    {selectedInterfaceLanguage && (
                      <TranslateWord key={"REJECTED"} text={"REJECTED"} lang={selectedInterfaceLanguage} />
                    )}
                  </Button>
                </span>
                {/* <span style={{cursor:'pointer'}} onClick={openNewStoryModel}>       
             <PlusCircleOutlined style={{ fontSize: '30px', color: '#08c', position:'relative',top:'2vh',left:'3vw'}}/>

        </span> */}
              </Col>
            </Row>
            <Row gutter={[10, 10]} style={{ marginTop: "6vh" }}>
              {submissionGrievancesData?.data?.map((grievance) => {
                return (
                  <>
                    <Col style={{ marginBottom: "5px" }} xs={20} sm={20} md={12} lg={8} xl={6} xxl={6}>
                      <Card
                        className="customCard"
                        hoverable
                        // style={{ width: "19vw" }}
                      >
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}>
                            <div>
                              {" "}
                              {selectedInterfaceLanguage && (
                                <TranslateWord
                                  key={"GRIEVANCE_ID"}
                                  text={"GRIEVANCE_ID"}
                                  lang={selectedInterfaceLanguage}
                                />
                              )}{" "}
                              : {grievance.grievance_details_id}
                            </div>
                            <div>
                              {grievance?.grievance_status?.status_description === "Draft" && (
                                <Badge color="#616161" placement="start" />
                              )}

                              {grievance?.grievance_status?.status_description === "Reject" && (
                                <Badge color="#CC0001" placement="start" />
                              )}

                              {grievance?.grievance_status?.status_description === "Under review" && (
                                <Badge color="#FAAC40" placement="start" />
                              )}
                              {/* {story?.story_status?.story_status_name === 'Published' &&  <Badge color="#00E676" text={story?.story_status?.story_status_name} placement='start' />} */}
                            </div>
                          </div>
                          <div style={{ height: "9vh" }}>
                            <Text strong>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html:
                                    grievance.title
                                      .replace(/<[^>]*>/g, "")
                                      .replace(/&nbsp;|&emsp;/g, "")
                                      .trim()?.length > 55
                                      ? grievance.title
                                          .replace(/<[^>]*>/g, "")
                                          .replace(/&nbsp;|&emsp;/g, "")
                                          .trim()
                                          ?.substring(0, 55) + "..."
                                      : grievance.title
                                          .replace(/<[^>]*>/g, "")
                                          .replace(/&nbsp;|&emsp;/g, "")
                                          .trim(),
                                }}></span>
                            </Text>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}>
                            <div>{grievance?.date?.moment(grievance?.date).format("MMM DD")}</div>

                            {grievance?.grievance_status?.status_description === "Draft" && (
                              <>
                                <div>
                                  <img
                                    src={editIcon}
                                    // onClick={() => openStoryModalPopup(story)}
                                    width={18}
                                    height={18}
                                  />
                                </div>
                              </>
                            )}

                            {grievance?.grievance_status?.status_description === "Rejected" && (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}>
                                  <div
                                    style={{
                                      color: "blue",
                                      fontSize: "13px",
                                      marginRight: "5px",
                                    }}
                                    onClick={() => {
                                      hangleGrievanceRejectReason(grievance, "Reason for Rejection");
                                    }}>
                                    <Tooltip
                                      title={
                                        selectedInterfaceLanguage && (
                                          <TranslateWord
                                            key={"REASON_FOR_REJECTED"}
                                            text={"REASON_FOR_REJECTED"}
                                            lang={selectedInterfaceLanguage}
                                          />
                                        )
                                      }>
                                      <img src={ReasonStoryIcon} width={20} height={20} />
                                    </Tooltip>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </>
                );
              })}
            </Row>
          </div>
          <div>
            <Pagination
              className="pull-right"
              current={currentPageForGrievances}
              pageSize={cardsPerPage}
              total={submissionGrievancesData?.totalElements}
              showSizeChanger={false}
              onChange={handlePageChangeForGrievances}
            />
          </div>
        </TabPane>
      </Tabs>
      {rejectReasonFlag && (
        <>
          <ReasonModel
            openFlag={rejectReasonFlag}
            selectedStory={selectedStory}
            reasonTitle={reasonTitle}
            handleModelFlag={closeReasonModel}
          />
        </>
      )}
      {petitionRejectReasonFlag && (
        <>
          <PetitionReason
            openFlag={petitionRejectReasonFlag}
            petition={selectedPetition}
            reasonTitle={reasonTitle}
            handleModelFlag={closeReasonModel}
          />
        </>
      )}
      {grievanceRejectReasonFlag && (
        <>
          <PetitionReason
            openFlag={grievanceRejectReasonFlag}
            petition={selectedGrievance}
            reasonTitle={reasonTitle}
            handleModelFlag={closeReasonModel}
          />
        </>
      )}
      
      {petitionUpdateFlag && (
        <>
          <PetitionUpdates openFlag={petitionUpdateFlag} petitionData={selectedPetition}  handleModelFlag={closeReasonModel} />
        </>
      )}
      {addNewStoryFlag && (
        <>
          <AddNewStory openFlag={addNewStoryFlag} story={storyData} closeModelFlag={closeModal} />
        </>
      )}
    </>
  );
};

export default SubmissionsTab;
