import { Button, Col, Form, Input, Modal, Row, Space, message } from "antd";
import React, { useState } from "react";
import politikosLogo from "../../../imgaes/politikosLogo.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  useGetAllValidateOtpMutation,
  useGetAllOtpMutation,
} from "../../../Services/OtpAPI";
import { faListSquares } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { validateUserGmailOTP, validateUserOTP } from "../../../Store/LoginSlice";

function OTP({ openFlag, closeLoginModel,sendingFrom,optType }) {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(openFlag);
  const [saveCategoryAPI] = useGetAllValidateOtpMutation();
  const [ReSendOtpAPI] = useGetAllOtpMutation();
  const [form] = Form.useForm();
  const [otp, setOtp] = useState(["", "", "", ""]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginOTPDetails } = useSelector(
    (state) => state.LoginList
  );
  const submitOTP = async () => {
    const payload = {
      user_id: loginOTPDetails?.user_id,
      otp: otp.join(""), // Combine all OTP digits into a single string
    };
if(optType === 'gmail'){
  dispatch(validateUserGmailOTP(payload)).then((action) => {
    if (validateUserGmailOTP.fulfilled.match(action)) {
      if(action?.payload?.is_success){
        closeLoginModel(false);          
        setIsLoginModalOpen(false); 
        let user = {
          mobile_number:action?.payload?.data?.mobile_number,
          user_id:action?.payload?.data?.user_id,
        }
        localStorage.setItem('LoggedInUserDetails',JSON.stringify(user))
        message.success({
          content: "OTP verified successfully",
          duration: 5, 
          style: {
              marginTop: '20px', 
              marginBottom: '20px', 
          },
      });
    //  if(sendingFrom === 'signIn') navigate("/ForYou")
      
      }else{
        message.error({
          content: "Invalid OTP, Please check again",
          duration: 5, 
          style: {
              marginTop: '20px', 
              marginBottom: '20px', 
          },
      });
      }
    
    }
  }).catch((err) => {
    message.error({
      content: "Something went wrong",
      duration: 5, 
      style: {
          marginTop: '20px', 
          marginBottom: '20px', 
      },
  });
    
  }) 
}else{
  dispatch(validateUserOTP(payload)).then((action) => {
    if (validateUserOTP.fulfilled.match(action)) {
      if(action?.payload?.is_success){
        closeLoginModel(false);          
        setIsLoginModalOpen(false); 
        let user = {
          mobile_number:action?.payload?.data?.mobile_number,
          user_id:action?.payload?.data?.user_id,
        }
        localStorage.setItem('LoggedInUserDetails',JSON.stringify(user))
        message.success({
          content: "OTP verified successfully",
          duration: 5, 
          style: {
              marginTop: '20px', 
              marginBottom: '20px', 
          },
      });
     if(sendingFrom === 'signIn') navigate("/ForYou")
      
      }else{
        message.error({
          content: "Invalid OTP, Please check again",
          duration: 5, 
          style: {
              marginTop: '20px', 
              marginBottom: '20px', 
          },
      });
      }
    
    }
  }).catch((err) => {
    message.error({
      content: "Something went wrong",
      duration: 5, 
      style: {
          marginTop: '20px', 
          marginBottom: '20px', 
      },
  });
    
  }) 
}
  
  
   
  };

  const onChange = (index, e) => {
    const value = e.target.value;

    // Ensure that the value is numeric (0-9) and update the state accordingly
    if (/^[0-9]*$/.test(value)) {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);

      // Move cursor to the next box if the current box is not the last one
      if (index < otp.length - 1 && value !== "") {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    } else if (e.key === "Backspace" && index > 0) {
      // Move cursor to the previous box on Backspace
      const prevInput = document.getElementById(`otp-input-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };
  const handleResendOtp = async () => {
    const USERDATA =JSON.parse(localStorage.getItem('userDetails')) 
    const payload = {
      first_name: USERDATA?.first_name,
      mobile_number:USERDATA?.mobile_number
    };
    if (payload) {
      try {
        await ReSendOtpAPI(payload)
          .unwrap()
          .then((payload) => {
            if (
              payload.is_success === true &&
              payload.data.is_otp_generated === true
            ) {
              message.success({
                content: "OTP resent successfully",
                duration: 5, // Specify the duration in seconds
                style: {
                  marginTop: "20px", // Adjust the margin if needed
                  marginBottom: "20px", // Add margin at the bottom
                },
              });
            }
          });
      } catch (err) {
        console.log("Some thing went wrong");
      }
    }
  };

  const handleCancel = () => {
    closeLoginModel(false);
    setIsLoginModalOpen(false);
  };
  const handleOk = () => {
    console.log("okkkkk");
  };

  const isMobile = {
    width:"430px",
    height:"auto"
  }

  return (
    <>
      <Modal
        open={isLoginModalOpen}
        //  onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
      {/* <div style={{paddingRight:"10px"}}>  // className="politikos-logo-alignments-for-otp-screen" */}

        <Row style={{ marginBottom: "40px", paddingRight:"40px"}} >
          <Col span={16} offset={6}>
            <img src={politikosLogo} /> 
          </Col>
        </Row>
        <Row style={{ marginBottom: "15px", paddingRight:"40px" }}>
          <Col span={18} offset={7}>
          {optType === 'gmail' ? 
            <h3> Check your Email for OTP</h3> : <h3> Check your Mobile for OTP</h3>
          }
          </Col>
        </Row>
        <Row style={{paddingRight:""}}>
          <Col span={18} offset={5}>
            <Form
              name="login-form"
              form={form}
              initialValues={{ remember: true }} 
              onFinish={submitOTP}
            >
              <Form.Item className="otp-container">
                {otp.map((digit, index) => (
                  <Input
                    key={index}
                    type="tel"
                    id={`otp-input-${index}`}
                    onChange={(e) => onChange(index, e)}
                    className="otp_data"
                    maxLength={1} // Limit to one character
                    style={{
                      textAlign: "center", 
                      // marginLeft:"15px"
                    }}
                  />
                ))}
              </Form.Item>
              <Form.Item  style={{maxWidth:"100%"}}>
               
                <Button type="primary"
                    htmlType="submit"
                    danger
                    block
                    style={{width:"90%"}}
                    >
                  Submit
                </Button>
              </Form.Item>
              <Row style={{marginRight:"10px" }}>
                <Col span={18} offset={4}>
                  Didn’t receive OTP?{" "}
                  <span onClick={handleResendOtp}>
                    Resend
                  </span>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        {/* </div> */}
      </Modal>
    </>
  );
}

export default OTP;
